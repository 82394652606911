import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { AlertService, SettingsService } from '@app/_services';
import { Channel } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {

  channelList: Channel[] = [];
  columnsToDisplay: string[] = ['name','code'];
  dataSource: MatTableDataSource<Channel>;
  broadcastMessage = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private alertService: AlertService,
    private settingsService: SettingsService,
    private globals: Globals,
    public dialog: MatDialog) {
      this.settingsService.
        invokeRefreshChannelList.subscribe((name: string) => {
          this.getChannelList();
        });

      this.getChannelList();
    }

    getChannelList() {
      this.settingsService.getAllChannels(this.globals.locale)
          .pipe(first())
          .subscribe(
              data => {
                // console.log(data);
                if (data.status !== 'error'){
                  this.channelList = data.response.channels;
                  this.dataSource = new MatTableDataSource(this.channelList);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  // console.log(this.clientList);
                } else {
                  this.alertService.error(data.errorMessage);
                }
              },
              error => {
                  console.log(error);
                  this.alertService.error(error.statusText);
              });
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }


  ngOnInit() {
  }


  addChannel() {
    const dialogRef = this.dialog.open(ChannelDialogContent, {
      disableClose: true,
      width: '500px'
    });
  }

  editChannel(row) {
    const dialogRef = this.dialog.open(ChannelDialogContent, {
      disableClose: true,
      width: '500px',
      data: row
    });
  }

}


// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'channel-dialog',
  templateUrl: './channel-form.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelDialogContent implements OnInit {

  submitted = false;
  channelData: Channel = new Channel();
  code = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private alertService: AlertService,
      private settingsService: SettingsService,
      private globals: Globals,
      public dialogRef:MatDialogRef <ChannelsComponent>,
    ){ }

  ngOnInit() {
      this.channelData.locale = this.globals.locale;

      if (this.data) {
        console.log(this.data);
        this.submitted = true;
        this.name = new FormControl(this.data.name, [Validators.required]);
        this.code = new FormControl(this.data.code, [Validators.required]);
      }
  }

  getErrorMessage() {
  }

  addNewChannel() {
    this.submitted = true;
    if (this.name.invalid || this.code.invalid) {
        return;
    }
    this.channelData.name = this.name.value;
    this.channelData.code = this.code.value;
    // console.log(this.clientData);

    this.settingsService.addChannel(this.channelData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshChannelList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }


  updateChannel() {
    this.submitted = true;
    if (this.name.invalid || this.code.invalid || !this.data.id) {
        return;
    }
    this.channelData.name = this.name.value;
    this.channelData.code = this.code.value;
    this.channelData.id = this.data.id;
    // console.log(this.clientData);

    this.settingsService.updateChannel(this.channelData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshChannelList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }

  deleteChannel() {
    this.submitted = true;
    if (!this.data.id) {
        return;
    }
    this.channelData.id = this.data.id;
    // console.log(this.clientData);

    this.settingsService.deleteChannel(this.channelData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Deleted');
          this.refreshChannelList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }

  refreshChannelList(){
    this.settingsService.refreshChannelList();
  }

}
