import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AlertService, ProjectService, SchedulerService, UtilityService, UserService } from '@app/_services';
import { Project } from '@app/_models';
import { Globals } from '@app/globals';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-project-scheduler',
  templateUrl: './project-scheduler.component.html',
  styleUrls: ['./project-scheduler.component.scss']
})
export class ProjectSchedulerComponent implements OnInit {

  projectList: Project[] = [];
  outletList = [];
  promoterList = [];
  statusList = []
  defaultStatusList = [
    { "value": -1, "name": "All" },
    { "value": "PENDING", "name": "Pending Check-in" },
    { "value": "NOT_ASSIGNED", "name": "Pending Assignment" },
    { "value": "COMPLETED", "name": "Check-in" },
    { "value": "CANCELLED", "name": "Cancelled" },
    { "value": "REPLACEMENT", "name": "Replacement" },
    { "value": "IN_PROGRESS", "name": "In Progress" }
  ];
  userList = [
    { "value": "ROLE_PROMOTER", "name": "Promoter" },
    { "value": "ROLE_SUPERVISOR", "name": "Supervisor" }
  ];

  projectId;
  status = new FormControl(-1);
  outletId = new FormControl(-1);
  userId = new FormControl(-1);
  userType = new FormControl('ROLE_PROMOTER');
  isAssignedPM: boolean = true;
  accessLevel;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private schedulerService: SchedulerService,
    private globals: Globals,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.accessLevel = this.globals.currentUser.response.accessLevel
    this.getProjectList();
  }

  getProjectList() {
    this.projectService.getSimpleList().pipe(first()).subscribe(
      data => {
        // console.log(data);
        if (data.status != 'error') {
          // if(this.accessLevel === "ROLE_PROJECT_MANAGER"){
          //   this.projectList = data.response.filter(el => el.projectManagerId === this.globals.currentUser.response.userId);
          // } else {
          this.projectList = data.response;
          // }
        } else {
          console.log(data.errorMessage);
        }
      },
      error => {
        console.log(error);
        this.alertService.error('Project Not Found');
      });
  }

  getOutlet() {
    this.schedulerService.getOutlet(this.projectId).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          let res = [];
          res.push({ name: "All", id: -1 });
          data.response.map(el => {
            res.push(el);
          })
          this.outletList = res;
          // console.log(this.outletList);
        } else {
          console.log(data.errorMessage);
        }
      },
      error => {
        console.log(error);
      });
  }

  getPromoter() {
    this.schedulerService.getPromoter1(this.projectId, this.userType.value).pipe(first()).subscribe(
      data => {
        this.promoterList = [];
        if (data.status != 'error') {
          this.promoterList.push({ fullName: "All", userId: -1 });
          data.response.map(el => {
            this.promoterList.push(el);
          })
        } else {
          console.log(data.errorMessage);
        }
      },
      error => {
        console.log(error);
      });
  }

  selectProject() {
    let data = {};
    data["id"] = this.projectId;
    data["project"] = this.projectList.find(el => el.id === this.projectId);
    this.schedulerService.fetchId(data);
    if (this.accessLevel === 'ROLE_PROJECT_MANAGER') {
      this.isAssignedPM = this.globals.currentUser.response.userId === this.projectList
        .find(el => el.id === this.projectId).projectManagerId;
    }
    this.getOutlet();
    this.getPromoter();
    this.clearFilter();
  }

  filter() {
    this.reInitStatusList()
    const filter = {
      status: this.status.value,
      outletId: this.outletId.value,
      userId: this.userId.value,
      userType: this.userType.value,
      tab: this.summaryPage ? 0 : 1,
      isAssignedPM: this.isAssignedPM,
      requestedBy: JSON.parse(localStorage.getItem('currentUser')).response.userId,
    };
    this.schedulerService.fetchFilter(filter);
    this.getPromoter();
  }

  clearFilter() {
    this.status.setValue(-1);
    this.outletId.setValue(-1);
    this.userId.setValue(-1);

    this.filter();
  }

  reInitStatusList() {
    this.statusList = []
    let extraSupervisorStatusList = [
      { "value": "APPROVAL_CANCELLED", "name": "Cancel Pending Approval" },
      { "value": "APPROVAL_REPLACEMENT", "name": "Replacement Pending Approval" },
      { "value": "APPROVAL_PENDING", "name": "New Pending Approval" }
    ]
    // Supervisor have more status (approvals)
    if (this.userType.value == 'ROLE_SUPERVISOR') {
      this.statusList = [
        ...this.defaultStatusList,
        ...extraSupervisorStatusList,
      ]
    } else {
      this.statusList = [...this.defaultStatusList]
    }
    // To prevent when extra status is select and userType is changed, the status is selected to an unavailable status
    let haveStatusShouldNotExist = true
    this.statusList.filter(status => {
      if (this.status.value == status.value) haveStatusShouldNotExist = false
    })
    if (haveStatusShouldNotExist) this.status.setValue(-1)
  }

  openModal(action, outletList) {
    let data = {
      action: action,
      outletList: outletList.filter(el => el.name !== 'All'),
      projectId: this.projectId,
      userType: this.userType,
      project: this.projectList.find(el => el.id === this.projectId),
      selectProject: () => this.selectProject()
    };
    this.dialog.open(UpdateOutletDialogContent, {
      data: data,
      minWidth: '500px',
      maxWidth: '650px',
      height: '650px'
    });
  }

  summaryPage: boolean = true;
  onTabClick(event: MatTabChangeEvent) {
    if (event.index == 0) this.summaryPage = true;
    else this.summaryPage = false;
    this.schedulerService.changeTabScheduler(event.index)
  }

  canModifyOutlet(type: 'edit' | 'remove' | 'add') {
    if (this.accessLevel === 'ROLE_SUPER_ADMIN') { //super admin can do anything
      return true
    } else if (this.accessLevel === 'ROLE_COORDINATOR') {
      if (this.userType.value === 'ROLE_PROMOTER' && type === 'edit') return true
      else return false
    } else if (this.accessLevel === 'ROLE_PROJECT_MANAGER') {
      if (this.isAssignedPM) return true
    } else {
      return true
    }
    return false
  }
  canAddVisitation() {
    if (this.accessLevel === 'ROLE_SUPER_ADMIN') { //super admin can do anything
      return true
    } else if (this.userType.value !== 'ROLE_SUPERVISOR') { // supervisor scheduler cant add visitation here.
      if (this.accessLevel === 'ROLE_PROJECT_MANAGER') {
        if (this.isAssignedPM) return true
      } else if (this.accessLevel === 'ROLE_COORDINATOR') {
        return true
      }
    }
    return false
  }

}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
// import * as moment from 'moment';
import debounce from 'lodash/debounce'

@Component({
  selector: 'update-outlet-dialog',
  templateUrl: 'update-outlet-dialog.html',
  styleUrls: ['update-outlet-dialog.scss']
})
export class UpdateOutletDialogContent implements OnInit {

  outletList = [];
  outletId = new FormControl('', [Validators.required]);
  promoterList = [];
  supervisorList = [];
  supervisorName = '';
  promoterId = new FormControl('');
  supervisorId = new FormControl('');
  startTime = new FormControl('', [Validators.required]);
  endTime = new FormControl('', [Validators.required]);
  date = new FormControl('', [Validators.required]);
  outletCode = new FormControl('', [Validators.required]);
  activationType = new FormControl('', [Validators.required]);
  salary = new FormControl('', [Validators.required]);
  dailySalesTarget = new FormControl('', [Validators.required]);
  dailySamplingTarget = new FormControl('', [Validators.required]);
  startEndTime = new FormGroup({
    startTime: new FormControl(),
    endTime: new FormControl()
  })
  user: any = new Array();
  salaryIncrement: number = 0;

  minDate;
  maxDate;
  isLoading: Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
    private userService: UserService,
    private globals: Globals,
    private utilityService: UtilityService,
    public dialogRef: MatDialogRef<ProjectSchedulerComponent>,
  ) {
    dialogRef.disableClose = false;
    this.user = this.globals.currentUser.response;
  }

  ngOnInit() {
    this.outletList = this.data.outletList;
    if (this.data.action === 'ADD_OUTLET') {
      this.getSupervisorList();
    }
    this.setMinMaxDate()

    // console.log(this.user)
    // console.log(this.data)
  }

  setMinMaxDate() {
    this.minDate = this.utilityService.getStartOrNowDate(this.data.project.startDate, "DD/MM/YYYY HH.mm", true)
    this.maxDate = moment(this.data.project.endDate, "DD/MM/YYYY HH.mm").toDate();
  }

  handleOutletCodeChange() {
    if (this.data.action === 'ADD_OUTLET' && this.outletCode.value) {
      this.getSupervisor();
    }
  }

  changeTime() {
    let st = this.outletList.find(el => el.id === this.outletId.value).startTime;
    if (st.substr(0, st.indexOf(':')).length == 1) {
      st = "0" + st;
    }
    this.supervisorId.setValue("");
    this.startEndTime.setValue({
      startTime: st,
      endTime: this.outletList.find(el => el.id === this.outletId.value).endTime
    })
  }

  getSupervisor() {
    this.userService.getAllSupervisorByOutletCode({ code: this.outletCode.value }).pipe(first())
      .subscribe(
        data => {
          if (data.response) {
            this.supervisorName = data.response.fullName
            this.supervisorId.setValue(data.response.userId)
          }
        }
      )
  }

  getSupervisorList() {
    this.schedulerService.getSupervisorList().pipe(first())
      .subscribe(
        data => {
          if (data.response) {
            this.supervisorList = data.response;
          }
        }
      )
  }

  getUserListByType() {
    let date = this.utilityService.formatDateOldWay(this.date.value)
    let outletId = null
    let projectId = null

    if (this.data.userType.value === 'ROLE_SUPERVISOR') {
      outletId = this.outletId.value;
      projectId = this.data.projectId;
    }
    this.isLoading = true
    this.schedulerService.getUserListByType(date, this.data.userType.value, outletId, projectId)
      .pipe(first())
      .subscribe(
        data => {
          console.log('Got From API', data);
          if (data.status != 'error') {
            if (this.data.userType.value === 'ROLE_PROMOTER') {
              this.promoterList = data.response;
            } else {
              this.supervisorList = data.response;
            }
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }, () => { this.isLoading = false; }
      );
  }

  submitForm = debounce(function (action) {
    if (action === 'EDIT_TIME') {
      this.editTime()
    } else if (action === 'ADD_OUTLET') {
      this.addOutlet()
    } else if (action === 'ADD_VISITATION') {
      if (new Date(this.date.value) < new Date()
        && new Date(this.date.value).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) {
        return;
      }
      let visitationTime = `${this.startEndTime.value.startTime} - ${this.startEndTime.value.endTime}`;
      if (this.data.userType.value === 'ROLE_PROMOTER') {
        this.addVisitationPromoter(visitationTime)
      } else {
        this.addVisitationSupervisor(visitationTime)
      }
    } else {
      this.removeOutlet()
    }

  }, 700)

  editTime() {
    if (this.outletId.invalid || this.startTime.invalid || this.endTime.invalid ) {
      // If invalid field 
      this.outletId.markAsTouched();
      this.startTime.markAsTouched();
      this.endTime.markAsTouched();
      return 
    } else if (this.isInvalidTime(this.startTime.value) || this.isInvalidTime(this.endTime.value)) {
      return
    }

    this.isLoading = true;
    this.schedulerService.editTime(
      this.data.projectId,
      this.outletId.value,
      this.startTime.value,
      this.endTime.value
    ).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          this.alertService.success('Time is updated');
          this.data.selectProject();
        } else {
          this.alertService.error(data.errorMessage);
        }
        this.dialogRef.close(true);
      },
      error => { this.handleError(error) }
      , () => { this.isLoading = false; }
    )
  }

  isInvalidTime(value) {
    // Regular expression to match 'HH:mm' format
    const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    // Check if the cleaned value matches the time pattern
    return !timePattern.test(value);
  }

  addOutletValidation() {
    if (this.outletCode.invalid
      || this.activationType.invalid
      || this.startTime.invalid
      || this.endTime.invalid
      || this.dailySalesTarget.invalid
      || this.dailySamplingTarget.invalid
      || this.salary.invalid
      || this.supervisorId.invalid
    ) {

      // If invalid field 
      // outletCode already focusout
      this.activationType.markAsTouched();
      this.startTime.markAsTouched();
      this.endTime.markAsTouched();
      this.dailySalesTarget.markAsTouched();
      this.dailySamplingTarget.markAsTouched();
      this.salary.markAsTouched();
      this.supervisorId.markAsTouched();

      return false
    } else if (this.isInvalidTime(this.startTime.value) || this.isInvalidTime(this.endTime.value)) {      
      // invalid date time here
      return false
    } else {
      // validation ok here
      return true
    }
  }

  addOutlet() {

    if (!this.addOutletValidation()) return

    this.isLoading = true;
    this.schedulerService.addOutlet(
      this.data.projectId,
      this.outletCode.value.trim(),
      this.activationType.value,
      this.startTime.value,
      this.endTime.value,
      this.dailySalesTarget.value,
      this.dailySamplingTarget.value,
      this.salary.value,
      this.supervisorId.value
    ).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          this.alertService.success('Added Outlet');
          this.data.selectProject();
        } else {
          this.alertService.error(data.errorMessage);
        }
        this.dialogRef.close(true);
      },
      error => { this.handleError(error) }
      , () => { this.isLoading = false; }
    )
  }

  addVisitationPromoter(visitationTime) {
    console.log('here')
    this.isLoading = true;
    let month = moment(this.date.value).format("MMM")
    let day = new Date(this.date.value).getDate();
    let year = new Date(this.date.value).getFullYear().toString().substr(-2);
    let visitationDate = `${day}-${month}-${year}`;
    const isSalaryIncrement = this.salaryIncrement > 0

    this.schedulerService.addVisitation(
      this.data.projectId,
      this.outletId.value,
      visitationDate,
      visitationTime,
      this.promoterId.value,
      this.data.userType.value,
      (isSalaryIncrement ? this.salaryIncrement : null),
      (isSalaryIncrement ? this.user.userId : null)
    ).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          this.alertService.success('Added Visitation');
          this.data.selectProject();
        } else {
          this.alertService.error(data.errorMessage);
        }
        this.isLoading = false;
        this.dialogRef.close(true);
      },
      error => { this.handleError(error) }
      , () => { this.isLoading = false; }
    )
  }

  addVisitationSupervisor(visitationTime) {
    this.isLoading = true;
    let requestedBy = this.user.userId
    const payload = {
      requestedBy: requestedBy,
      projectId: +this.data.projectId,
      outletId: this.outletId.value,
      visitationDate: moment(this.date.value, 'D-MMM-YY').format('DD-MMM-YYYY'), //11-Sep-2021
      visitationTime: visitationTime,
      supervisorId: this.supervisorId.value,
    }
    console.log('Sending to API', payload)
    this.schedulerService.addSupervisorVisitation(payload)
      .pipe(first())
      .subscribe(data => {
        console.log('Got from API', data)
        if (data.status !== "error") {
          this.alertService.success('Added Visitation');
          this.data.selectProject();
        } else {
          this.alertService.error(data.errorMessage);
        }
        this.dialogRef.close(true);
      },
        error => { this.handleError(error) }
        , () => { this.isLoading = false; }
      )
  }

  removeOutlet() {
    this.isLoading = true;
    this.schedulerService.removeOutlet(
      this.data.projectId,
      this.outletId.value
    ).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          this.alertService.success('Removed Outlet');
          this.data.selectProject();
        } else {
          this.alertService.error(data.errorMessage);
        }
        this.dialogRef.close(true);
      },
      error => { this.handleError(error) }
      , () => { this.isLoading = false; }
    )
  }

  //MISC
  handleError(error) {
    this.dialogRef.close(true);
    console.log(error);
    this.alertService.error(error.statusText);
  }

  canModifyVisitation(type: 'edit' | 'remove' | 'add' | 'increment') {
    if (this.user.accessLevel === 'ROLE_SUPER_ADMIN') { //super admin can do anything
      return true
    } else if (this.user.accessLevel === 'ROLE_COORDINATOR') {
      if (type !== 'increment') return true
      else return false
    } else if (this.user.accessLevel === 'ROLE_PROJECT_MANAGER') {
      return true
    }
    return false
  }

}
