import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '@app/_services/alert.service';
import { SchedulerService } from '@app/_services/scheduler.service';
import { Subscriber } from 'rxjs';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'scheduler-summary',
  templateUrl: 'scheduler-summary.html',
  styleUrls: ['scheduler.scss']
})

export class SchedulerSummaryComponent implements OnInit, OnDestroy {

  projectId;
  visitationSummary = [];
  userType = 'ROLE_PROMOTER';
  status;
  outletId;

  statusList :any[] = new Array();

  allSubscriptions: Subscriber<any>[] = []

  constructor(
    private alertService: AlertService,
    private schedulerService: SchedulerService,
  ) { }

  ngOnInit() {
    this.allSubscriptions.push(
      this.schedulerService.change.subscribe(data => {
        this.projectId = data.id;
      }))

    this.allSubscriptions.push(
      this.schedulerService.changeTab.subscribe(tab => {
        if (tab === 0) {
          this.getSummary(this.projectId, this.userType);
        }
      }))

    this.allSubscriptions.push(
      this.schedulerService.filter.subscribe(data => {
        this.userType = data.userType != -1 ? data.userType : null;
        this.outletId = data.outletId != -1 ? data.outletId : null;
        if (data.tab === 0) {
          this.getSummary(this.projectId, this.userType);
        }
      }));

  }

  getSummary(id,type){
    this.schedulerService.getSummary(id,type).pipe(first()).subscribe(
      data => {
        if (data.status != 'error') {
          this.visitationSummary = data.response.visitations.sort(function(a, b){
            if (a.outletName < b.outletName) { return -1; }
            if (a.outletName > b.outletName) { return 1; }
            return 0;
          });
          this.parseData(this.visitationSummary);
        } else {
          console.log(data);
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      });
  }

  parseData(data){
    const visit = data.map(data => data.data);
    // const finalVisit = visit[0].map((col, i) => visit.map(row => row[i] ? row[i] : 0));

    let temp = [];
    visit.forEach(function(vv){
      temp.push( vv.map(data => data[Object.keys(data)[0]]) );
    });

    this.statusList = temp;
  }

  // for HTML
  getStatusClass(status) {
    if (status == 'COMPLETED') return 'bg-green';
    if (status == 'IN_PROGRESS') return 'bg-light-blue';
    if (status == 'PENDING') return 'bg-blue';
    if (status == 'NOT_ASSIGNED' || status == 'REPLACEMENT') return 'bg-yellow';
    if (status == 'CANCELLED') return 'bg-red';
  }

  formatDateForTable(value) {
    let result = moment(value, 'DD-MMM-YYYY').format('DD-MMM-YY')
    return result == 'Invalid date' ? value : result
  }

  parseStatus(status) {
    return this.schedulerService.parseStatus(status)
  }

  isHidden(sl) {
    return this.outletId != null && this.outletId != sl.outletId
  }

  ngOnDestroy() {
    this.allSubscriptions.forEach(sub => sub.unsubscribe())
  }

}
