export class Client {
    locale: string;
    clientId: number;
    clientName: string;
    picEmail: string;
    email: string;
    name: string;
    status: string;
    picNumber: string;
    picPassword: string;
}
