import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";

import { environment } from "@environments/environment";
import { User } from "@app/_models";

@Injectable({ providedIn: "root" })
export class UserService {
  invokeRefreshUserList = new EventEmitter();
  subsVar: Subscription;

  constructor(private http: HttpClient) {}

  getAll(locale: string) {
    return this.http.post<any>("/fd/api/getuserslist", {
      locale: locale,
      status: null
    });
  }

  getById(user: User) {
    return this.http.post<any>("/fd/api/getuserdetails", user);
  }

  add(user: User) {
    return this.http.post<any>("/fd/api/adduser", user);
  }

  update(user: User) {
    return this.http.post<any>("/fd/api/userupdate", user);
  }

  delete(user: User) {
    return this.http.post<any>("/fd/api/deleteuser", user);
  }

  resetPW(postData) {
    return this.http.post<any>("/fd/api/resetpassword", postData);
  }

  // Supervisor
  getAllSupervisor() {
    return this.http.get<any>("/fd/api/getsupervisorslist")
  }

  getAllSupervisorsByRequestedBy(data) {
    return this.http.post<any>("/fd/api/supervisor/all", data)
  }

  getAllPendingSupervisorsByRequestedBy(data) {
    return this.http.post<any>("/fd/api/supervisor/all/pending-approval", data)
  }

  getAllSupervisorByOutletCode(data) {
    return this.http.get<any>(`/fd/api/supervisor/by-outlet-code/${data.code}`)
  }

  updateSVAndCoordinator(data) {
    return this.http.post<any>("fd/api/coordinator/supervisors/update", data)
  }

  updateSalary(data: any) {
    return this.http.post<any>("/fd/api/supervisor/salary/update", data);
  }

  getSupervisorDetails(data: any) { //For Overview Page
    return this.http.post<any>('/fd/api/supervisor/details', data)
  }

  //ALLOWANCE
  addAllowance(data) {
    return this.http.post<any>('/fd/api/supervisor/allowance/add', data)
  }

  updateAllowance(data) {
    return this.http.post<any>('/fd/api/supervisor/allowance/update', data)
  }

  cancelApprovalAllowance(data) { //Action by coordinator to cancel approval request
    return this.http.post<any>('/fd/api/supervisor/allowance/cancel', data)
  }

  moderateAllowance(data) {
    return this.http.post<any>('/fd/api/supervisor/allowance/moderate', data)
  }

  // Misc
  refreshUserList() {
    this.invokeRefreshUserList.emit();
  }

  parseUserAccessLv(accessLv){
    if( accessLv == "ROLE_PROMOTER") return 'Promoter';
    else if( accessLv == "ROLE_SUPERVISOR") return 'Supervisor';
    else if( accessLv == "ROLE_PROJECT_MANAGER") return 'Project Manager';
    else if( accessLv == "ROLE_SUPER_ADMIN") return 'Super Admin';
    else if( accessLv == "ROLE_CLIENT") return 'Client';
    else if( accessLv == "ROLE_COORDINATOR") return 'Coordinator';
    else if( accessLv == "ROLE_TRAINER") return 'Trainer';
  }
}
