import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PopulateRegionService {

    constructor(private router: Router) {}

    // success(message: string, keepAfterNavigationChange = false) {
    //     this.keepAfterNavigationChange = keepAfterNavigationChange;
    //     this.subject.next({ type: 'success', text: message });
    // }

    getRegion(event){
      // console.log(event);
      if(event.value === 1 ||
         event.value === 3 ||
         event.value === 15)
      {
        return 1;
      }
      else if(event.value === 5 ||
        event.value === 10 ||
        event.value === 11 ||
        event.value === 12)
      {
        return 3;
      }
      else if(event.value === 4 ||
              event.value === 7 ||
              event.value === 8)
      {
        return 2;
      }
      else if(event.value === 6 ||
              event.value === 9 ||
              event.value === 16)
      {
        return 4;
      }
      else{
        return 5;
      }

    }

}
