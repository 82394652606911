import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, ProjectService, DashboardService } from '@app/_services';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@app/globals';
import { Project } from '@app/_models';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: "dashboard-top-sales",
  templateUrl: "./top-sales.component.html",
  styleUrls: ["./top-sales.component.scss"]
})
export class DashboardTopSalesComponent implements OnInit {

  projectId ;

  topSales : any[] = [];
  type = 'all';
  value : Number;
  typeLists : any[] = null;
  typeList : any[] = null;
  topSalesListTypes : [
    "High", "Low"
  ];
  topSalesList: "High";

  @ViewChild('topSalesChart') topSalesChart: ElementRef;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private dashboardService: DashboardService,
    private globals: Globals
  ) { }

  ngOnInit() {
    this.dashboardService.change.subscribe(id => {
      // this.projectId = 56;  //TESTING
      this.projectId = id;
      // console.log(this.projectId);
      this.type = 'all';
      this.topSalesList = 'High';
      this.selectProject();
    });
  }

  selectProject(value?){

    this.typeList = null;

    if(this.type != 'all'){

        // console.log(this.typeLists);
        let temp = this.type + 's';
        let choice = this.value;
        this.typeList = this.typeLists[temp];

        if(value){
          this.value = value;
        }else{
          this.value = this.typeList.length === 0 ? null : this.typeList[0].id;
        }
        // console.log(this.typeList,this.value);
        if(this.value){
          this.getSalesSKU(this.projectId,this.type,this.value);
        }else{
          this.getSalesSKU(this.projectId,this.type);
        }

    }else{
      this.getSalesSKU(this.projectId,this.type);
    }

  }

  getSalesSKU(id,type,value?){

    let data: any = {};
    data.projectId = id;
    data.type = type;
    if(value) data.value = value;
    // console.log(data);

    this.dashboardService.getSalesBySku(data).pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            // console.log(data);
            this.topSales = data.response ? data.response.salesBySku : null;
            this.typeLists = data.response;
            if(this.topSalesList === "High"){
              this.topSales = this.topSales.slice(0, 5).map(el => el);
            } else if(this.topSalesList === "Low"){
              this.topSales = this.topSales.slice(Math.max(this.topSales.length - 5, 0));
              // console.log(this.topSales)
            }

            this.initChartData(this.topSales);
          }else{
            console.log(data.errorMessage);
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
    });

  }

  initChartData(data){
    console.log("initchartdata");
    console.log(data);

    const product = data.map(data => data.name);
    const key = data.map(data => data.sales);
    const sales = data.map(data => data.sales);
    const colors = Object.assign([], this.topSalesList === "High" ? this.colorArrayHigh : this.colorArrayLow)
    sales.forEach((el, index) => {
      if (el == 0) colors[index] = '#ffffff' // let zero sales to have white color
    })

    this.createCharts(product, key, sales, colors);

  }

  public myChart: Chart
  public colorArrayHigh = [
    'rgba(255, 99, 132)',
    'rgba(255, 159, 64)',
    'rgba(255, 205, 86)',
    'rgba(75, 192, 192)',
    'rgba(54, 162, 235)'
  ];
  public colorArrayLow = [
    '#b8d5b2',
    '#84b29e',
    '#568f8b',
    '#1c485e',
    '#132740'
  ];
  createCharts(product, key, sales, colors) {
     const ctx = this.topSalesChart.nativeElement.getContext('2d');
     let context = this;
     if (this.myChart) this.myChart.destroy();

     this.myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        // labels: product.length >= 1 ? product : [''],
        labels: product,
        datasets: [
           {
            data: sales.length >= 1 ? sales : [],
            backgroundColor: colors,
            borderWidth: 2,
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        hover: {
          animationDuration: 0
        },
        animation: {
          duration: 1,
          // easing: "easeOutQuart",
          onComplete: function() {
            var ctx = this.chart.ctx;
            ctx.font = 'normal 12px Roboto,"Helvetica Neue",sans-serif';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                let left = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.left;
                // let offset = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.longestLabelWidth;
                // ctx.fillStyle = '#444';
                var y_pos = model.y - 5;
                var label = model.label;
                // Make sure data value does not get overflown and hidden
                // when the bar's value is too close to max value of scale
                // Note: The y value is reverse, it counts from top down
                if ((scale_max - model.y) / scale_max >= 0.93)
                  y_pos = model.y + 20;
                // ctx.fillText(dataset.data[i], model.x, y_pos);
                ctx.fillText(label, left + 10, model.y - 13);
              }
            });
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: function(t, d) {
              return String(d.labels[t[0].index]);
            },
            label: function(tooltipItem, data) {
              return tooltipItem['value'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
          xPadding: 15,
          yPadding: 15,
          intersect: false // Show tooltips even if the value is 0
        },

        title: {
          text: 'Top Sales Product',
          display: false
        },
        scales:
        {
          xAxes: [{
             ticks: {
                beginAtZero: true,
                maxTicksLimit: 10
              },
         }],
          yAxes: [{
            ticks: {
              display: false,
           },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
           barThickness: 10
         }]
        },
      },
    });
  };

  changeTopSalesListType(value){
    this.topSalesList = value;
    if(this.value){
      this.getSalesSKU(this.projectId,this.type,this.value);
    }else{
      this.getSalesSKU(this.projectId,this.type);
    }
  }
}
