import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";

import { environment } from "@environments/environment";
import { Announcement, Channel, Retailer, OffDay } from "@app/_models";
import { Globals } from "@app/globals";

@Injectable({ providedIn: "root" })
export class SettingsService {
  invokeRefreshAnnouncementList = new EventEmitter();
  invokeRefreshChannelList = new EventEmitter();
  invokeRefreshRetailersList = new EventEmitter();
  invokeRefreshOffDayList = new EventEmitter();
  subsVar: Subscription;

  constructor(private http: HttpClient, private globals: Globals) {}

  getAllAnnouncement(locale: string) {
    return this.http.post<any>("/fd/api/notifications", {
      locale: locale,
      status: null
    });
  }

  getSettingsData(locale: string, userId: number) {
    return this.http.post<any>("/fd/api/getsettingsdata", {
      locale: locale,
      status: null,
      userId: userId
    });
  }

  addAnnouncement(anno: Announcement) {
    return this.http.post<any>("/fd/api/sendnotification", anno);
  }
  updateAnnouncement(anno: Announcement) {
    return this.http.post<any>("/fd/api/updatenotification", anno);
  }
  deleteAnnouncement(id: number) {
    return this.http.post<any>("/fd/api/deletenotification", {
      locale: this.globals.locale,
      notificationIds: [id]
    });
  }

  getAllChannels(locale: string) {
    return this.http.post<any>("/fd/api/utilityapi", {
      locale: locale,
      status: null
    });
  }
  addChannel(channel: Channel) {
    return this.http.post<any>("/fd/api/addchannel", channel);
  }
  updateChannel(channel: Channel) {
    return this.http.post<any>("/fd/api/updatechannel", channel);
  }
  deleteChannel(channel: Channel) {
    return this.http.post<any>("/fd/api/deletechannel", channel);
  }

  getAllRetailers(locale: string) {
    return this.http.post<any>("/fd/api/utilityapi", {
      locale: locale,
      status: null
    });
  }
  addRetailer(retailer: Retailer) {
    return this.http.post<any>("/fd/api/addretailer", retailer);
  }
  updateRetailer(retailer: Retailer) {
    return this.http.post<any>("/fd/api/updateretailer", retailer);
  }
  deleteRetailer(retailer: Retailer) {
    return this.http.post<any>("/fd/api/deleteretailer", retailer);
  }

  getOffDayList() {
    return this.http.post<any>("/fd/api/getoffdays",{});
  }
  addOffday(offday: OffDay) {
    return this.http.post<any>("/fd/api/addoffdays", offday);
  }

  refreshAnnouncementList() {
    this.invokeRefreshAnnouncementList.emit();
  }

  refreshChannelList() {
    this.invokeRefreshChannelList.emit();
  }

  refreshRetailersList() {
    this.invokeRefreshRetailersList.emit();
  }

  refreshOffDayList() {
    this.invokeRefreshOffDayList.emit();
  }
}
