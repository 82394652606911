import { Component, OnInit, ViewChild, Inject, ElementRef } from "@angular/core";
import { AlertService, UserService, UtilityService, ProjectGalleryService, ProjectService } from "@app/_services";
import { FormControl, Validators } from '@angular/forms';
import { User } from "@app/_models";
import { Globals } from "@app/globals";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import * as XLSX from "xlsx";
import { first } from "rxjs/operators";
import { AccessibilityConfig, AdvancedLayout, GalleryService, GridLayout, Image, Description, DescriptionStrategy,
  LineLayout, ButtonsStrategy, ButtonEvent,ButtonType,ButtonsConfig, PlainGalleryConfig,CurrentImageConfig, PlainGalleryStrategy } from "@ks89/angular-modal-gallery";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-project-gallery",
  templateUrl: "./project-gallery.component.html",
  styleUrls: ["./project-gallery.component.scss"]
})
export class ProjectGalleryComponent implements OnInit {
  imageList = [];
  projectList = [];
  selectedProjectId = 0;
  foundImage = 0;
  imageIndex = 1;
  images1: Image[] = [];
  images2: Image[] = [];
  images3: Image[] = [];
  galleryList = [];
  regionName= ''; channelName= ''; outletName= ''; date = '';

  selectedRegion : any = null;
  selectedChannel : any = null;
  selectedOutlet : any = null;
  selectedDate : any = null;
  clientId;
  projectId ;
  userType = new FormControl('ROLE_PROMOTER');

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private projectGalleryService: ProjectGalleryService,
    private projectService: ProjectService,
    private globals: Globals,
    public dialog: MatDialog,
    private galleryService: GalleryService,
    private sanitizer: DomSanitizer
  ) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if(user.response.clientId) this.clientId = user.response.clientId;
  }

  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.GRID,
    layout: new GridLayout({ width: '19%', height: '160px' }, { length: 5, wrap: true })
  };

  buttonsConfigSimple: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      {
        className: 'delete-image',
        type: ButtonType.DELETE
      },
      {
        className: 'download-image',
        type: ButtonType.DOWNLOAD
      },
      {
        className: 'close-image',
        type: ButtonType.CLOSE
      }
    ]
  };

  buttonsConfigSimple1: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.DEFAULT
  };

  ngOnInit() {
    this.getPrjectList();
    // this.getProjectGallery(50);
  }

  loadProjectGallery(isDeleted?) {
    // console.log(this.projectId,this.userType.value);
    // this.selectedProjectId = projId;
    if(this.projectId){
      this.getProjectGallery(this.projectId,this.userType.value,isDeleted ? isDeleted : null);
    }
  }

  getPrjectList() {
    this.projectService.getSimpleList(this.clientId?this.clientId:null).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.projectList = data.response;
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          // console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  getProjectGallery(projectId,userType,isDeleted?) {
    if(!isDeleted) this.reInit();

    this.projectGalleryService
      .getProjectGallery(projectId,userType)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.galleryList = data.response;
            // console.log(this.galleryList);
            if(isDeleted) this.refreshImage();
            // this.generateImageFile(data.response);
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          // console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  refreshImage(){

    let selectedRegion = JSON.parse(JSON.stringify( this.selectedRegion ));
    let selectedChannel = JSON.parse(JSON.stringify( this.selectedChannel ));
    let selectedOutlet = JSON.parse(JSON.stringify( this.selectedOutlet ));
    let selectedDate = JSON.parse(JSON.stringify( this.selectedDate ));
    // console.log(selectedRegion);
    // console.log(this.galleryList);

    if(this.galleryList && this.galleryList.length != 0){
      this.selectedRegion = this.galleryList.filter(function(data){
        return data.regionId === selectedRegion.regionId;
      })[0];

      if(this.selectedRegion){
        this.selectedChannel = this.selectedRegion.channels.filter(function(data){
          return data.channelId === selectedChannel.channelId;
        })[0];

        if(this.selectedChannel){
          this.selectedOutlet = this.selectedChannel.outlets.filter(function(data){
            return data.outletId === selectedOutlet.outletId;
          })[0];

          if(this.selectedOutlet){
            this.selectedDate = this.selectedOutlet.dates.filter(function(data){
              return data.date === selectedDate.date;
            })[0];

            if(this.selectedDate){
              this.imageList = [];
              this.generateImageFile(this.selectedDate);

            }else {this.selectedDate = null;this.date = '';}

          }else {this.selectedOutlet = null;this.outletName = '';}

        }else {this.selectedChannel = null;this.channelName = ''}

      }

      else this.reInit();

    }else this.reInit();

    // console.log(this.selectedRegion,this.selectedChannel,this.selectedOutlet,this.selectedDate);
  }

  generateImageFile(image){
    // console.log(image);
      let data = image.images;
      for (let i = 0, len = data.length; i < len; i++) {
        this.imageList.push({
          'image' : new Image(i, {
            // modal
            img: data[i].imageUrl,
            extUrl: data[i].imageUrl,
            title: data[i].date,
            description: data[i].location + '<br><small>' + data[i].userName + '</small>' + '<br><small>' + data[i].date + '</small>',
            alt: data[i].id
          }),
          'type' : data[i].imageType
        });
      }
      // console.log(this.imageList);

      if (data.length > 0) {
        this.foundImage = 1;
      } else {
        this.foundImage = 0;
      }

      const images1 = this.imageList.filter(function (image) {
        return image.type === "SETUP_READY" ;
      });
      const images2 = this.imageList.filter(function (image) {
        return image.type === "TASK";
      });
      const images3 = this.imageList.filter(function (image) {
        return image.type === "OOS";
      });

      this.images1 = images1.map(images => images.image);
      this.images2 = images2.map(images => images.image);
      this.images3 = images3.map(images => images.image);
  }

  reInit(){
    // console.log('reInit');
    this.foundImage = 0;
    this.imageList = [];
    this.images1 = [];
    this.images2 = [];
    this.images3 = [];
    this.selectedRegion = null;
    this.selectedChannel = null;
    this.selectedOutlet = null;
    this.selectedDate = null;
    this.regionName= '';
    this.channelName= '';
    this.outletName= '';
    this.date = '';
  }

  onButtonBeforeHook(event: ButtonEvent,type) {
  //  console.log('onButtonBeforeHook ', event);

   if (!event || !event.button) {
     return;
   }
   if(event.button.type === ButtonType.DOWNLOAD) {
      console.log(event);
      var a = document.createElement('a');
      a.href = event.image.modal.extUrl;
      a.download = "output.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return false;
   } 

   if (event.button.type === ButtonType.DELETE) {
     // remove the current image and reassign all other to the array of images

     this.deleteImage(Number(event.image.modal.alt));
     if(type === '1')this.images1 = this.images1.filter((val: Image) => event.image && val.id !== event.image.id);
     if(type === '2')this.images2 = this.images2.filter((val: Image) => event.image && val.id !== event.image.id);
     if(type === '3')this.images3 = this.images3.filter((val: Image) => event.image && val.id !== event.image.id);
   }
   
 }

 deleteImage(id){
  //  console.log(id);
   this.projectGalleryService.deleteImage(id).pipe(first()).subscribe(
     data => {
      //  console.log(data);
       if (data.status !== "error") {
         this.loadProjectGallery(true);
         this.alertService.success("Deleted.");
       } else {
         this.alertService.error(data.errorMessage);
       }
     },
     error => {
      //  console.log(error);
       this.alertService.error(error.statusText);
     }
   );
 }

}
