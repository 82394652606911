export class Promoter {
    locale: string;
    status: string;

    idNumber: string;
    gender: string;
    dateOfBirth: string;
    dob: string;
    phoneNumber: string;
    photoUrl: any;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    currentAddress: object;
    permanentAddress: object;
    cancelledJobs: number;
    rating: number;
    accessLevel: string;
    userId: number;
    fullName: string;
    email: string;
    profilePhoto: string;
    race: string;

    emergencyName: string;
    emergencyPhoneNumber: string;
    emergencyRelation: string;

    approveChanges:boolean;

    caStreetAddress: string;
    caCity: string;
    caState: number;
    caPostalCode: string;
    caCountry: number;
    caRegion: number;
    caArea: string;

    paStreetAddress: string;
    paCity: string;
    paState: number;
    paPostalCode: string;
    paCountry: number;
    paRegion: number;
    paArea: string;

    adminRequest:boolean;

}
