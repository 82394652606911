import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  AlertService,
  UtilityService,
  UserService,
  ClientService
} from "@app/_services";
import { User, Client } from "@app/_models";
import { Globals } from "@app/globals";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";
import * as moment from "moment";
import { first } from "rxjs/operators";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  profile: User = new User();
  stateList: any = new Array();
  form: FormGroup;
  submitted = false;
  statusChecked = false;
  sub;
  id: Number;
  user: any = new Array();
  hasError: boolean;
  errorMessage: string;

  password = new FormControl("", [Validators.required]);
  // userName = new FormControl("", [Validators.required]);
  // userEmail = new FormControl("", [Validators.required, Validators.email]);
  // userPhone = new FormControl("", [Validators.required]);

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private utilityService: UtilityService,
    private globals: Globals,
    private userService: UserService,
    private clientService: ClientService
  ) {
    this.user = JSON.parse(localStorage.getItem("currentUser"));

    if (this.user) {
      // this.submitted = true;
      // if (this.user.response.accessLevel === "ROLE_CLIENT") {
      //   this.userName = new FormControl(this.user.response.name, [
      //     Validators.required
      //   ]);
      //   this.userName.setValue(this.user.response.name, { onlySelf: true });
      // } else {
      //   this.userName = new FormControl(this.user.response.fullName, [
      //     Validators.required
      //   ]);
      //   this.userName.setValue(this.user.response.fullName, { onlySelf: true });
      // }

      // this.userEmail = new FormControl(this.user.response.email, [
      //   Validators.required,
      //   Validators.email
      // ]);
      // this.userPhone = new FormControl(this.user.response.phoneNumber, [
      //   Validators.required
      // ]);

      // this.userPhone.setValue(this.user.response.phoneNumber, {
      //   onlySelf: true
      // });

      // this.userEmail.setValue(this.user.response.email, { onlySelf: true });
    }
  }

  ngOnInit() {
    this.getUtility(this.globals.locale);
  }

  getUtility(locale) {}

  updateProfile() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.password.invalid) {
      this.hasError = true;
      this.errorMessage = "Complete the form before submit.";
      return;
    }

    let userdata: User = new User();
    userdata.userId = this.user.response.userId;

    let formPwd = "FD@" + this.password.value + "@)!(";
    const md5 = new Md5();
    let pwd: any = md5.appendStr(formPwd).end();

    userdata.password = String(pwd).toUpperCase();
    console.log(userdata);

    this.userService
      .update(userdata)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            this.alertService.success("Saved");
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );

  }

}
