import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService, UtilityService } from './_services';
import { User, Utility } from './_models';
import { Globals } from '@app/globals'
import { first } from 'rxjs/operators';
import { Location } from "@angular/common";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    currentUser: any;
    environment = environment;

    constructor(
      location: Location,
      private router: Router,
      private authenticationService: AuthenticationService,
      private _utilityService: UtilityService,
      private globals: Globals
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        router.events.subscribe(val => {
          if (location.path() != "") {
            let path = location.path();
            if(!path.includes('promoter')){
              if(localStorage.getItem('promoterTableFilter')) localStorage.removeItem('promoterTableFilter');
              if(localStorage.getItem('promoterTablePage')) localStorage.removeItem('promoterTablePage');
            }
            if(!path.includes('outlet')){
              if(localStorage.getItem('outletTableFilter')) localStorage.removeItem('outletTableFilter');
              if(localStorage.getItem('outletTablePage')) localStorage.removeItem('outletTablePage');
            }
          }
        });
    }

    ngOnInit() {
      this._utilityService.get().subscribe((data) => {
        if(data.status != 'error'){
          // console.log(data.response);
          this.globals.utilityList = data.response ? data.response : null;
          this.router.events.subscribe((event) => {
            if(event instanceof NavigationEnd) {
              return this.authenticationService.validate().pipe(first()).subscribe(
                data => {
                  if(data.status !== 'ok'){
                    this.logout();
                  }
              })
            }
          });
        }else{
          console.log(data.errorMessage);
        }
      },
      error => {
          console.log(error);
      });

    }

    canAccessProfile() {
      const canAccessList = ['ROLE_SUPER_ADMIN', 'ROLE_PROJECT_MANAGER', 'ROLE_COORDINATOR']
      const accessLevel = this.currentUser.response.accessLevel
      let found = canAccessList.findIndex(el => el === accessLevel)
      if (found >= 0) return true
      return false
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
