import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, PromoterService } from '@app/_services';
import { Promoter } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit {

  pendingList: Promoter[] = [];
  columnsToDisplay: string[] = ['role','name','previousDetails','changeDetails','action'];
  dataSource: MatTableDataSource<Promoter>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private promoterService: PromoterService,
    private globals: Globals,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.promoterService.subsVar === undefined) {
      this.promoterService.subsVar = this.promoterService.invokeRefreshList.subscribe(
        (name: string) => {          
          this.getPendingPromoter();
        }
      );
    }

    this.getPendingPromoter();
  }

  getPendingPromoter(){    
    this.promoterService.getPending()
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              if(data.status != 'error'){
                if(data.response.length > 0){
                  this.pendingList = data.response;
                  this.globals.pending = data.response.length;
                  this.dataSource = new MatTableDataSource(this.pendingList);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  console.log(this.pendingList);
                }else{
                  console.log('redirect to promoter')
                  this.router.navigate(['promoter']);
                }
              }else{
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
  }

  reject(data){
    let promoter : Promoter = new Promoter();
    promoter.approveChanges = false;
    promoter.userId = data.userId;
    // console.log(promoter);

    this.promoterService
      .reject(promoter)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.alertService.success("Saved");
            this.getPendingPromoter();
            // this.refreshList();
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );

  }

  approve(data){
    let promoter : Promoter = new Promoter();
    promoter.approveChanges = true;
    promoter.userId = data.userId;
    promoter.email = data.email;
    promoter.fullName = data.fullName;
    promoter.idNumber = data.idNumber;
    promoter.gender = data.gender;
    promoter.dob = data.dateOfBirth;
    // console.log(promoter);

    this.promoterService
      .approve(promoter)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.alertService.success("Saved");
            this.getPendingPromoter();
            // this.refreshList();
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );

  }

  refreshList(){
    this.promoterService.refreshList();
  }

  getPreviousDetails(data){
    let changes :any[] = [];
    data.map(element => {
      changes.push(Object.values(element))
    })
    return changes;
  }

  getChangesDetails(data){
    let changes :any[] = [];
    data.map(element => {
      changes.push(Object.values(element))
    })
    return changes;
  }

  getRole(level){
    let role;
    if(level === 'ROLE_PROMOTER') return 'Promoter';
    if(level === 'ROLE_SUPERVISOR') return 'Supervisor';
  }

}
