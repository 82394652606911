import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { Globals } from "@app/globals";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AlertService, AuthenticationService } from "@app/_services";
import { Md5 } from "ts-md5/dist/md5";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [Globals]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isClient: boolean;

  forgetPwd = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private globals: Globals,
    private loadingBar: LoadingBarService
  ) {
    // redirect to home if already logged in
    console.log(this.authenticationService.currentUserValue);
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }

    this.isClient = route.snapshot.data.isClient;
  }

  ngOnInit() {
    console.log(this.globals.locale);

    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.forgetPwd && !this.f.email.errors){
      this.resetPwd();
      return;
    }
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    // console.log(this.f.email.value, this.f.password.value);

    let formPwd = "FD@" + this.f.password.value + "@)!(";
    const md5 = new Md5();
    let pwd: any = md5.appendStr(formPwd).end();

    // let formPwd = "FD@" + this.f.password.value + "@)!(";
    // console.log('Be4 encode : ',formPwd);
    // let encodePwd = encodeURIComponent(formPwd);
    // console.log('After encode : ',encodePwd);
    // const md5 = new Md5();
    // let pwd: any = md5.appendStr(encodePwd).end();
    // console.log('After md5 : ',pwd);

    this.loading = true;

    if (this.isClient) {
      this.clientLogin(pwd);
    } else {
      this.userLogin(pwd);
    }
  }

  errorMsg : boolean = false;
  errorMsgContent;
  errorMsg1 : boolean = false;
  errorMsgContent1;

  userLogin(pwd) {

    this.errorMsg = false;
    this.errorMsg1 = false;

    this.authenticationService
      .login(this.globals.locale, this.f.email.value, String(pwd).toUpperCase())
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            const isCoordinator = data.response.accessLevel === 'ROLE_COORDINATOR';
            if (isCoordinator) this.router.navigate(['/scheduler']); //Coordinator cant access /index
            else this.router.navigate([this.returnUrl]);

          } else {
            this.loading = false;
            if(data.errorCode == 'request.error.password.not.found'){
              this.errorMsg = true;
              this.errorMsgContent = data.errorMessage;
            }else if(data.errorCode == 'request.error.email.not.found'){
              this.errorMsg1 = true;
              this.errorMsgContent1 = data.errorMessage;
            }
            else this.alertService.error(data.errorMessage);

          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
          this.loading = false;
        }
      );
  }

  clientLogin(pwd) {
    this.authenticationService
      .clientLogin(
        this.globals.locale,
        this.f.email.value,
        String(pwd).toUpperCase()
      )
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            // alert(this.returnUrl);
            this.router.navigate([this.returnUrl]);
          } else {
            this.alertService.error(data.errorMessage);
            this.loading = false;
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
          this.loading = false;
        }
      );
  }

  resetPwd(){
    this.authenticationService
      .reset(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            this.alertService.success('Sent');
          } else {
            this.alertService.error(data.errorMessage);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
          this.loading = false;
        }
      );
  }
}
