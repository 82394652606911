import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AlertService, ProjectService } from "@app/_services";
import { Task } from "@app/_models";
import { Globals } from "@app/globals";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-checkout-reminder",
  templateUrl: "./checkout-reminder.component.html",
  styleUrls: ["./checkout-reminder.component.scss"]
})
export class CheckoutReminderComponent implements OnInit {
  sub;
  projectId: Number;
  taskList: Task[] = [];
  columnsToDisplay: string[] = ["name", "subTasks"];
  dataSource: MatTableDataSource<Task>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private globals: Globals,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = Number(params["id"]);
      this.getTaskList(this.projectId, "CHECKOUT");
    });
  }

  ngOnInit() {
    this.projectService.invokeRefreshCheckoutTaskList.subscribe(
      (name: string) => {
        this.getTaskList(this.projectId, "CHECKOUT");
      }
    );
  }

  getTaskList(projId, type) {
    this.projectService
      .getAllTasks(projId, type)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.taskList = data.response;
            this.dataSource = new MatTableDataSource(this.taskList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  editTask(task) {
    task.projectId = this.projectId;
    // if (task.updatable) {
      const dialogRef = this.dialog.open(AddCheckoutReminderTaskComponent, {
        disableClose: true,
        data: task,
        width: "500px"
      });
    // }
  }

  addTask() {
    const dialogRef = this.dialog.open(AddCheckoutReminderTaskComponent, {
      disableClose: true,
      minWidth: "500px",
      data: {
        projectId: this.projectId
      }
    });
  }
}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from "@angular/material";
import {
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  FormGroup
} from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "add-checkout-reminder-task",
  templateUrl: "./task-form.html",
  styleUrls: ["./checkout-reminder.component.scss"]
})
export class AddCheckoutReminderTaskComponent implements OnInit {
  taskForm: FormGroup;
  public subTaskList: FormArray;
  submitted = false;
  taskData: Task = new Task();
  hasError: boolean = false;
  errorMessage: string = "";
  choiceSelectable = true;
  choiceRemovable = true;
  choiceAddOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  choiceErrors: {};

  // returns all form groups under contacts
  get subTaskFormGroup() {
    return this.taskForm.get("subTasks") as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private projectService: ProjectService,
    private globals: Globals,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CheckoutReminderComponent>
  ) {
    //   this.taskForm = this._formBuilder.group({
    //     subTaskArray: this._formBuilder.array([])
    //  });
  }

  ngOnInit() {
    this.taskData.locale = this.globals.locale;
    this.taskForm = this._formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      subTasks: this._formBuilder.array([this.createSubTask()])
    });

    // set contactlist to this field
    this.subTaskList = this.taskForm.get("subTasks") as FormArray;

    if (this.data.id) {
      console.log(this.data);

      for (let i = 1; i < this.data.subTasks.length; i++) {
        this.subTaskList.push(this.createSubTask());
        // this.choiceErrors[i] = "";
      }

      this.taskForm.setValue({
        name: this.data.name,
        subTasks: this.data.subTasks
      });
      if(!this.data.updatable){
        this.taskForm.controls['name'].disable();
        this.taskForm.controls['subTasks'].disable();
      }

    } else {
    }
  }

  createSubTask(): FormGroup {
    return this._formBuilder.group({
      id: [null, Validators.compose([])],
      type: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([])],
      title: [null, Validators.compose([Validators.required])],
      choices: [[], Validators.compose([])]
    });
  }

  createChoice(): FormGroup {
    return this._formBuilder.group({
      name: [[], Validators.compose([Validators.required])]
    });
  }

  // add a contact form group
  addSubTask() {
    this.subTaskList.push(this.createSubTask());
  }

  // remove contact from group
  removeSubTask(index) {
    if (this.taskForm.value.subTasks.length <= 1) {
      this.alertService.error("Atleast one sub task required.");
    } else {
      this.subTaskList.removeAt(index);
    }
  }

  changedFieldType(index) {
    let validators = null;

    if (
      this.getSubTaskFormGroup(index).controls["type"].value === "MULTI_CHOICES"
    ) {
      validators = Validators.compose([Validators.required]);
    } else {
      validators = Validators.compose([]);
    }

    this.getSubTaskFormGroup(index).controls["type"].setValidators(validators);

    this.getSubTaskFormGroup(index).controls["type"].updateValueAndValidity();
  }

  // get the formgroup under contacts form array
  getSubTaskFormGroup(index): FormGroup {
    const formGroup = this.subTaskList.controls[index] as FormGroup;
    return formGroup;
  }

  addChoice(event: MatChipInputEvent, index): void {
    // console.log();
    this.choiceErrors = {};
    let c = this.subTaskList.controls[index]["controls"]["choices"].value;
    // alert(index);
    console.log(c);
    if (c === null) {
      this.subTaskList.controls[index]["controls"]["choices"].value = [];
      c = this.subTaskList.controls[index]["controls"]["choices"].value;
    }

    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      c.push({ id: 0, title: value.trim() });
    }

    if (input) {
      input.value = "";
      // alert(index);
      // this.choiceErrors[index] = "";
    }
  }

  removeChoice(choice, index): void {
    const c = this.subTaskList.controls[index]["controls"]["choices"].value;
    const i = c.indexOf(choice);
    if (i >= 0) {
      c.splice(i, 1);
    }
  }

  submit() {
    this.choiceErrors = {};
    let haveErrors = 0;
    if (!this.taskForm.value.name) {
    } else if (this.taskForm.value.subTasks.length <= 0) {
      this.alertService.error("Atleast one sub task required.");
    } else {
      // console.log(this.taskForm.value.subTasks);
      for (let k = 0; k < this.taskForm.value.subTasks.length; k++) {
        // alert(this.taskForm.value.subTasks[k].choices);
        if (
          this.taskForm.value.subTasks[k].type === "MULTI_CHOICES" &&
          this.taskForm.value.subTasks[k].choices.length <= 0
        ) {
          const msg = "This field is required.";
          // this.alertService.error(msg);
          this.choiceErrors[k] = msg;
          haveErrors++;
        } else {
          this.choiceErrors[k] = "";
        }
      }

      if (haveErrors === 0) {
        if (this.data.id) {
          this.editTask(this.taskForm.value);
        } else {
          this.addNewTask(this.taskForm.value);
        }
      } else {
        return false;
      }
    }
  }

  addNewTask(task) {
    this.projectService
      .addTask(task, this.data.projectId, "CHECKOUT")
      .pipe(first())
      .subscribe(
        data => {
          if (data.status !== "error") {
            this.dialogRef.close(true);
            this.alertService.success("Saved");
            this.refreshTaskList(this.data.projectId, "CHECKOUT");
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
        }
      );
  }

  editTask(task) {
    this.projectService
      .updateTask(task, this.data.id)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            this.dialogRef.close(true);
            this.alertService.success("Saved");
            this.refreshTaskList(this.data.projectId, "CHECKOUT");
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  deleteTask(task) {
    this.projectService
      .deleteTask(task)
      .pipe(first())
      .subscribe(
        data => {
          if (data.status !== "error") {
            this.dialogRef.close(true);
            this.alertService.success("Deleted");
            this.refreshTaskList(this.data.projectId, "CHECKOUT");
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  refreshTaskList(projId, type) {
    this.projectService.refreshTaskList(projId, type);
  }
}
