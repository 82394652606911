import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { environment } from '@environments/environment';
import { Promoter } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class PromoterService {

    invokeRefreshList = new EventEmitter();
    invokeRefreshBriefingList = new EventEmitter();
    subsVar: Subscription;

    constructor(private http: HttpClient,public _globals: Globals) { }

    getAll(page, size, query) {
      if(page >= 0 || size || query){
        return this.http.post<any>('/fd/api/getpromoterslist',{ "locale": this._globals.locale, page: page, size: size, q: query });
      }
      return this.http.post<any>('/fd/api/getpromoterslist',{ "locale": this._globals.locale });
    }

    getBriefing(pData) {
      return this.http.post<any>('/fd/api/promoter-briefing/grouped/list', pData);
    }

    deleteBriefing(pData) {
      return this.http.post<any>('/fd/api/promoter-briefing/grouped/reset', pData);
    }

    overwriteBriefing(pData) {
      return this.http.post<any>('/fd/api/promoter-briefing/grouped/overwrite', pData);
    }

    getById(id: Number) {
        return this.http.post<any>('/fd/api/getpromoterdetails',{ "userId": id });
    }

    update(promoter: Promoter) {
        promoter.locale = this._globals.locale;
        return this.http.post<any>('/fd/api/promoterupdate', promoter);
    }

    getPending(){
      return this.http.post<any>('/fd/api/getpromoterchanges', {});
    }

    approve(data: Promoter){
      return this.http.post<any>('/fd/api/moderatepromoterupdates', data);
    }

    reject(data: Promoter){
      return this.http.post<any>('/fd/api/moderatepromoterupdates', data);
    }

    refreshList() {
      this.invokeRefreshList.emit();
    }
    
    refreshBriefingList() {
      this.invokeRefreshBriefingList.emit();
    }

    exportPromoter(){
      return this.http.post<any>('/fd/api/getfullpromoterslist', {});
    }

    getSimplePromoter(){
      return this.http.post<any>('/fd/api/promoter/simple-list', {});
    }
}
