import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

interface Retailer {
    locale: string;
    retailerId : number;
    name: string;
    code: string;
};
@Injectable({ providedIn: 'root' })
export class RetailerService {

    constructor(private http: HttpClient) { }

    add(retailer: Retailer) {
        return this.http.post<any>('/fd/api/addretailer', retailer);
    }

    update(retailer: Retailer) {
        return this.http.post<any>('/fd/api/updateretailer', retailer);
    }

}
