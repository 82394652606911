import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "@environments/environment";
import { User } from "@app/_models";
import { Globals } from "@app/globals";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, public _globals: Globals) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(locale: string, email: string, password: string) {
    return this.http
      .post<any>("/fd/api/login", {
        locale: locale,
        email: email,
        password: password,
        type: "web"
      })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user.status != "error") {
            console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            this._globals.currentUser = user;
          }

          return user;
        })
      );
  }

  clientLogin(locale: string, email: string, password: string) {
    return this.http
      .post<any>("/fd/api/clientlogin", {
        locale: locale,
        email: email,
        password: password,
        type: "web"
      })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user.status !== "error") {
            console.log(user);
            // user.accessLevel = "ROLE_CLIENT";
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }

  validate(){
    return this.http.get<any>(`/fd/api/validateuser/${this._globals.currentUser.response.userId}`);
  }

  reset(email:string){
    return this.http.post<any>('/fd/api/resetpassword', { email: email });
  }
}
