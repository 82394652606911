import { Component, OnInit, AfterViewInit} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, ProjectService, ReportService } from '@app/_services';
import { FormControl, Validators } from '@angular/forms';
import { Project } from '@app/_models';
import { Globals } from '@app/globals';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

declare var libraryVar: any;

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"]
})
export class IndexComponent implements OnInit {
  isClient: boolean;
  isAdmin: boolean;
  isPromoter: boolean;
  isSupervisor: boolean;
  isPM: boolean;
  isCoordinator: boolean;

  constructor(private globals: Globals, private router: Router) {
    // if (globals.currentUser) {
    //   this.defineAccessRights(globals.currentUser.response.accessLevel);
    // } else {
      if (localStorage.getItem("currentUser")) {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        if (user) {
          this.defineAccessRights(user.response.accessLevel);
        } else {
          this.router.navigate(["/login"]);
        }
      } else {
        this.router.navigate(["/login"]);
      }
    }
  // }

  defineAccessRights(role) {
    console.log(role);
    if (role === "ROLE_CLIENT") {
      this.isClient = true;
    } else if (role === "ROLE_SUPER_ADMIN") {
      this.isAdmin = true;
    } else if (role === "ROLE_PROMOTER") {
      this.isPromoter = true;
    } else if (role === "ROLE_SUPERVISOR") {
      this.isSupervisor = true;
    } else if (role === "ROLE_PROJECT_MANAGER") {
      this.isPM = true;
    } else if (role === "ROLE_COORDINATOR") {
      this.isCoordinator = true;
    }
  }

  ngOnInit() {}
}
