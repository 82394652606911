import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import * as moment from 'moment';

interface Dashboard {
    locale: string;
    projectId : number;
    productCount : number;
    startDate: string;
    endDate: string;
    type: string;
    value: number;
};
@Injectable({ providedIn: 'root' })

export class DashboardService {

    @Output() change: EventEmitter<number> = new EventEmitter();
    private projectId : number;

    constructor(private http: HttpClient) { }

    getYTDData() {
        return this.http.post<any>('/fd/api/generatedashboard', {"type": "ytdData"});
    }

    getDashboardData(data: Dashboard) {
        return this.http.post<any>('/fd/api/generatedashboard', data);
    }

    getTopSales(data: Dashboard) {
        return this.http.post<any>('/fd/api/gettopsales', data);
    }

    getSalesBySku(data: Dashboard) {
        return this.http.post<any>('/fd/api/getsalesbysku', data);
    }

    //YYYY-MM-DD
    parsePickerDate(date){
      let year: any = moment(date, "YYYY-MM-DD HH:mm").get('year');
      let month: any = moment(date, "YYYY-MM-DD HH:mm").get('month');
      let day: any = moment(date, "YYYY-MM-DD HH:mm").format('DD');

      return new Date(year, month, day);
    }

    fetchId(id) {
        this.projectId = id;
        this.change.emit(this.projectId);
    }

    refresh() {
        this.change.emit(this.projectId);
    }
}
