import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {DialogComponent} from '@app/_components/dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {

  private dialogRef: MatDialogRef<DialogComponent>;

  constructor(public dialog: MatDialog) { }

  openDialog(data: any, additionalDialogConfigData?: any): MatDialogRef<DialogComponent> {

    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data,
      ...additionalDialogConfigData
    });

    this.dialogRef.afterClosed().subscribe(result => {
    });

    return this.dialogRef;
  }


  // WAY TO CALL SERVICE :::::*****************************
  // openDialog() {
  //
  //     const dialogData: DialogData = {
  //       title: 'Test Dialog',
  //       message: 'This is our first dialog!',
  //       showOKBtn: true,
  //       showCancelBtn: true
  //     };
  //
  //     const dialogRef = this.dialogService.openDialog(
  //       dialogData, {disableClose: true});
  //
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //         console.log('User clicked OK');
  //       } else {
  //         console.log('User clicked Cancel');
  //       }
  //     });
  //   }

}
