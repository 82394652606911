import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MatDatepicker, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from "../../_constants/dateTime.js"
import { Globals } from '@app/globals';
import { User } from '@app/_models';
import { UserService, AlertService, ProjectService, ReportService } from '@app/_services';
import * as moment from 'moment';
import { Moment } from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
})
export class PayrollSupervisorComponent implements OnInit {

  supervisorList = [];
  userId;

  datesList = [];
  selectedMonth = new FormControl(moment());
  maxDate = new Date(); //current year and month

  payrollReport = null;
  attendanceReport = null;

  constructor(
    private userService: UserService,
    private globals: Globals,
    private alertService: AlertService,
    private projectService: ProjectService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.getSupervisor()
    this.onSelectChange()
  }

  getSupervisor() {
    this.userService.getAll(this.globals.locale)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          let retrievedArray = data.response
          this.supervisorList = retrievedArray.filter(user => user.accessLevel === "ROLE_SUPERVISOR")
          this.supervisorList.unshift({userId: -1, fullName: "All"})
          this.userId = -1 //Default select All
        }
        , error => {
          console.log(error);
          this.alertService.error('Supervisor Not Found');
        });
  }

  onSelectChange(normalizedValue?: Moment, datepicker?: MatDatepicker<Moment>) {
    if (normalizedValue) {
      const ctrlValue = this.selectedMonth.value;
      if (!datepicker) { // with datepicker = selected month
        ctrlValue.year(normalizedValue? normalizedValue.year() : null);
        this.selectedMonth.setValue(ctrlValue);
      } else {
        datepicker.close() // Close datepicker to avoid user pick date
        ctrlValue.month(normalizedValue? normalizedValue.month() : null);
        this.selectedMonth.setValue(ctrlValue);
        // Only call when both month and year obtained
        this.getReports()
      }
    } else {
      this.getReports()
    }
  }

  getReports() {
    this.attendanceReport = null
    this.payrollReport = null

    const data = {
      "userId": this.userId === -1 ? null : this.userId,
      "month": "" + this.selectedMonth.value.format('M'),
      "year": "" + this.selectedMonth.value.format('YYYY')
    }
    this.getAttendanceReport(data)
    this.getPayrollReport(data)
  }

  getAttendanceReport(data) {
    console.log("Sending to API", data)
    this.reportService.getSupervisorAttendanceReport(data)
      .pipe(first())
      .subscribe( data => {
        console.log("Got from API", data)
        if (data.status === 'ok') {
          this.attendanceReport = data.response
        } else {
          this.alertService.error(data.errorMessage)
        }
      },
      error => {
        console.log(error)
        this.alertService.error(error);
      })
  }

  getPayrollReport(data) {
    console.log("Sending to API", data)
    this.reportService.getSupervisorPayrollReport(data)
      .pipe(first())
      .subscribe( data => {
        console.log("Got from API", data)
        if (data.status === 'ok') {
          this.payrollReport = data.response
        } else {
          this.alertService.error(data.errorMessage)
        }
      },
      error => {
        console.log(error)
        this.alertService.error(error)
      })
  }

  parseFileNameFromUrl(url) {
    // E.G. https://fd.chimaera.dev/fdimages/supervisor-attendance-Test Supervisor 14.xlsx
    let output = "" + url
    output = output.replace(/^.*\/(.*)$/, '$1') // take supervisor-attendance-Test Supervisor 14.xlsx
    return output
  }

  downloadAll() {
    window.open(this.attendanceReport.generatedExcelUrl);
    window.open(this.payrollReport.generatedExcelUrl);
  }

}
