import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard, RoleGuard } from "./_guards";

import { IndexComponent } from "./index/index.component";
import { PendingComponent } from './pending/pending.component';
import { LoginComponent } from "./login/login.component";
import { ClientComponent } from "./client/client.component";
import { TrainingComponent } from "./training/training.component";
import { UsersComponent } from "./users/users.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProjectSchedulerComponent } from "./project-scheduler/project-scheduler.component";
import { OverviewSupervisorComponent } from "./project-overview/supervisor/overview-supervisor.component";
import { PayrollSupervisorComponent } from "./payroll/supervisor/supervisor.component";
import { SettingsComponent } from "./settings/settings.component";
import { AnnouncementComponent } from "./settings/announcement/announcement.component";
import { ChannelsComponent } from "./settings/channels/channels.component";
import { RetailersComponent } from "./settings/retailers/retailers.component";
import { OffDayComponent } from "./settings/off-day/off-day.component";
import { ProjectGalleryComponent } from "./project-gallery/project-gallery.component";
import { ReportComponent } from './report/report.component' ;

const routes: Routes = [
  {
    path: "index",
    component: IndexComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_CLIENT", "ROLE_COORDINATOR"],
      breadcrumb: 'Dashboard'
    }
  },
  // {
  //   path: "oos",
  //   component: IndexComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_CLIENT"],
  //     breadcrumb: 'OOS Dashboard'
  //   }
  // },
  {
    path: "login",
    component: LoginComponent,
    data: {
      // isClient: false
    }
  },
  {
    path: "client-login",
    component: LoginComponent,
    data: {
      isClient: true
    }
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_CLIENT", "ROLE_COORDINATOR"],
      breadcrumb: 'Profile'
    }
  },
  {
    path: "users",
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN"],
      breadcrumb: 'User'
    }
  },
  {
    path: "client",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN"],
      breadcrumb: 'Client'
    }
  },
  {
    path: "outlet",
    loadChildren: "../app/outlet/outlet.module#OutletModule",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
    }
  },
  {
    path: "promoter",
    redirectTo: "promoter/profile", // Default redirect to promoter child
  },
  {
    path: "promoter",
    loadChildren: "../app/promoter/promoter.module#PromoterModule",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
    }
  },  
  {
    path: "training",
    component: TrainingComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
      breadcrumb: 'Product Training'
    }
  },
  {
    path: "project",
    loadChildren: "../app/project/project.module#ProjectModule",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"]
    }
  },
  {
    path: "gallery",
    component: ProjectGalleryComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_CLIENT", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
      breadcrumb: 'Gallery'
    }
  },
  {
    path:'pending',
    component: PendingComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Pending Approval',
      expectedRole: ["ROLE_SUPER_ADMIN"]
    }
  },
  {
    path: "settings",
    // component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"],
      breadcrumb: 'Setting'
    },
    children :[
      {
        path: "announcements",
        component: AnnouncementComponent,
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"],
          breadcrumb: 'Announcement'
        }
      },
      {
        path: "channels",
        component: ChannelsComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"],
          breadcrumb: 'Channel'
        }
      },
      {
        path: "retailers",
        component: RetailersComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"],
          breadcrumb: 'Retailer'
        }
      },
      {
        path: "off-day",
        component: OffDayComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"],
          breadcrumb: 'Off Day Setting'
        }
      },
    ]
  },
  {
    path: "scheduler",
    component: ProjectSchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
      breadcrumb: 'Project Scheduler'
    }
  },
  {
    path: "overview",
    redirectTo: "overview/supervisor", // Default redirect to supervisor child
  },
  {
    path: "overview",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
      breadcrumb: 'Project Overview'
    },
    children: [
      {
        path: "supervisor",
        component: OverviewSupervisorComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_COORDINATOR"],
          breadcrumb: 'Supervisor'
        }
      },
      {
        path: "pending-approval",
        component: OverviewSupervisorComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN"],
          breadcrumb: 'Pending Approval'
        }
      },
    ]
  },
  {
    path: "payroll",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN"],
      breadcrumb: 'Payroll'
    },
    children: [
      {
        path: "supervisor",
        component: PayrollSupervisorComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRole: ["ROLE_SUPER_ADMIN"],
          breadcrumb: 'Supervisor'
        }
      }
    ]
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER" ,"ROLE_CLIENT", "ROLE_COORDINATOR"],
      breadcrumb: 'Report'
    }
  },
  // otherwise redirect to home
  {
    path: "**",
    redirectTo: "index",
    canActivate: [AuthGuard],
    data: {
      expectedRole: ["ROLE_SUPER_ADMIN", "ROLE_PROJECT_MANAGER"]
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
