import { PendingComponent } from './pending/pending.component';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastModule } from "primeng/toast";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { OrderModule } from "ngx-order-pipe";
import { Globals } from "@app/globals";
import bootstrap from "bootstrap";
import "hammerjs";
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatNativeDateModule,
  MatButtonModule,
  MatTabsModule,
  MatCardModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatDividerModule
} from "@angular/material";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import "hammerjs";
import "mousetrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { NgSelectModule } from '@ng-select/ng-select';

// App Components
import { AppComponent } from "./app.component";
import { AlertComponent } from "./_components";
import { SchedulerSummaryComponent, SchedulerDetailsComponent, ScheduleDialogContent } from "./_components";
import { DashboardSalesComponent, DashboardOOSComponent, DashboardTopSalesComponent } from "./_components";
import { SidebarComponent } from "./_components";
import { DialogComponent } from "./_components";
import { ChannelFormComponent } from "./_components";
import { RetailerFormComponent } from "./_components";
import { BreadcrumbComponent } from "./_components";
import { IndexComponent } from "./index/index.component";
import { AdminDashboardComponent } from "./index/admin.component";
import { ClientDashboardComponent } from "./index/client.component";
import { LoginComponent } from "./login/login.component";
import {
  ClientComponent,
  ClientDialogContent
} from "./client/client.component";
import {
  TrainingComponent,
  TrainingDialogContent
} from "./training/training.component";
import {
  UsersComponent,
  UserDialogComponent
} from "./users/users.component";
import { ProfileComponent } from './profile/profile.component';
import { ProjectSchedulerComponent } from './project-scheduler/project-scheduler.component';

import { OverviewSupervisorComponent } from './project-overview/supervisor/overview-supervisor.component';
import { OverviewSupervisorDialogComponent } from './project-overview/supervisor/dialog.component';

import { PayrollSupervisorComponent } from './payroll/supervisor/supervisor.component';

import { UpdateOutletDialogContent } from './project-scheduler/project-scheduler.component';
import { SettingsComponent } from "./settings/settings.component";
import {
  AnnouncementComponent,
  AnnouncementDialogContent
} from "./settings/announcement/announcement.component";
import {
  ChannelsComponent,
  ChannelDialogContent
} from "./settings/channels/channels.component";
import {
  RetailersComponent,
  RetailerDialogContent
} from "./settings/retailers/retailers.component";
import {
  OffDayComponent,
  OffDayDialogContent
} from "./settings/off-day/off-day.component";
import { ProjectGalleryComponent } from "./project-gallery/project-gallery.component";
import { ReportComponent } from './report/report.component';

//App Services
import { UtilityService } from "./_services";
import { DialogService } from "./_services";
import { ChannelService } from "./_services";
import { RetailerService } from "./_services";
import { ChannelDialogService } from "./_services";
import { RetailerDialogService } from "./_services";
import { TrainingService } from "./_services";
import { ProjectService } from "./_services";

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    ClientDashboardComponent,
    AdminDashboardComponent,
    LoginComponent,
    AlertComponent,
    SchedulerSummaryComponent,
    SchedulerDetailsComponent,
    ScheduleDialogContent,
    DashboardSalesComponent,
    DashboardOOSComponent,
    DashboardTopSalesComponent,
    SidebarComponent,
    DialogComponent,
    ClientComponent,
    ClientDialogContent,
    ChannelFormComponent,
    RetailerFormComponent,
    BreadcrumbComponent,
    TrainingComponent,
    TrainingDialogContent,
    UsersComponent,
    UserDialogComponent,
    ProfileComponent,
    PendingComponent,
    ProjectSchedulerComponent,
    OverviewSupervisorComponent,
    OverviewSupervisorDialogComponent,
    UpdateOutletDialogContent,
    SettingsComponent,
    AnnouncementComponent,
    ChannelsComponent,
    RetailersComponent,
    OffDayComponent,
    AnnouncementDialogContent,
    ChannelDialogContent,
    RetailerDialogContent,
    OffDayDialogContent,
    ProjectGalleryComponent,
    ReportComponent,
    PayrollSupervisorComponent,
  ],
  entryComponents: [
    ClientComponent,
    ClientDialogContent,
    DialogComponent,
    ChannelFormComponent,
    RetailerFormComponent,
    BreadcrumbComponent,
    TrainingComponent,
    TrainingDialogContent,
    UserDialogComponent,
    SchedulerSummaryComponent,
    SchedulerDetailsComponent,
    ScheduleDialogContent,
    UpdateOutletDialogContent,
    DashboardSalesComponent,
    DashboardOOSComponent,
    DashboardTopSalesComponent,
    AnnouncementDialogContent,
    ChannelDialogContent,
    RetailerDialogContent,
    OffDayDialogContent,
    OverviewSupervisorDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    OrderModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatDividerModule,
    GalleryModule.forRoot(),
    NgSelectModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    Globals,
    UtilityService,
    DialogService,
    ChannelService,
    ChannelDialogService,
    RetailerService,
    RetailerDialogService,
    TrainingService,
    ProjectService
  ],
  exports: [ BreadcrumbComponent ],
  bootstrap: [AppComponent]
})
export class AppModule {}
