import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadCrumb} from './breadcrumb';
import { Globals } from '@app/globals';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(event => this.buildBreadCrumb(this.activatedRoute.root))
  );

  currentPage : string;
  // Build your breadcrumb starting with the root route of your current activated route

  constructor(private activatedRoute: ActivatedRoute,
              private globals: Globals,
              private router: Router) {
  }

  ngOnInit() {
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {

    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time

    var nextUrl;
    var newBreadcrumbs;

    if(path != ''){
      nextUrl = `${url}/${path}`;
    } else{
      nextUrl = `${url}`;
    }
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };

    if( breadcrumb && breadcrumb.label ){
      newBreadcrumbs = [...breadcrumbs, breadcrumb];
    }else{
      newBreadcrumbs = [...breadcrumbs];
    }
    // console.log(newBreadcrumbs);
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      if(newBreadcrumbs) return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    if(newBreadcrumbs){
      this.currentPage = newBreadcrumbs[newBreadcrumbs.length - 1].label;
      return newBreadcrumbs;
    }

  }
}
