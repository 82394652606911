export class Training {
    locale: string;
    userId: number;
    projectId: number;
    name: string;
    trainingMaterialData: string;
    projectTrainingId: number;
    trainingMaterialLink: [];
    trainingMaterials: any[];
}
