export class Project {
    locale: string;
    id: number;
    userId: number;
    name: string;
    startDate: string;
    endDate: string;
    clientId: number;
    clientName: string;
    projectManager: string;
    projectManagerId: number;
    budgetCode: string;
    projectCode: string;
    projectCost: number;
    status: string;
    gender: string;
    workingDays: string[];
    productCountNAOption: boolean;    
    createdBy: string[];


    //Execel
    projectId: number;
    excelType: string;
    excelData: string;
    uploadedDataType: string;
    reportUrl: string;
}
