export class Outlet {
    locale: string;
    status: string;

    name: string;
    uniqueId: string;
    channel: number;
    channelCode: string;
    retailerName: string;
    streetAddress: string;
    city: string;
    area: string;
    state: number;
    postalCode: string;
    region: number;
    country: number;
    supervisorId: number;
    supervisorName: string;

    equipment: string;
    traffic: string;
}
