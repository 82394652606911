import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  locale: string = 'en';
  country: number = 1;

  latitude: number = 4.2105;
  longitude: number = 101.9758;

  public currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
  utilityList : any = new Array();

  pending : number = 0;
}
