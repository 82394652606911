import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

import { AuthenticationService } from "@app/_services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const expectedRole = route.data.expectedRole;
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      // console.log(user);
      // if (user.response.accessLevel !== expectedRole) {
      //   this.router.navigate(["/login"]);
      //   return false;
      // }
      // authorised so return true

      if (typeof expectedRole !== "undefined") {
        for (let i = 0; i < expectedRole.length; i++) {
          if (user.response.accessLevel === expectedRole[i]) {
            return true;
          }
        }
        return false;
      }

      return true;
    }

    if (localStorage.getItem("isLoggedin")) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
