import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { Utility } from '@app/_models'
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class UtilityService {

  private utilityData: Subject<Utility> = new Subject<Utility>();
  private keepAfterNavigationChange = false;

  constructor(private http: HttpClient, public _globals: Globals) {}

  get() {
    return this.http.post<any>('/fd/api/utilityapi',{"locale": this._globals.locale});
  }

  utilityList(data: any) {
    console.log('herrrrrr service');
    this.utilityData.next(data);
  }

  getUtilityList(): Observable<Utility>{
    return this.utilityData.asObservable();
  }

  // MICS useful functions

  renameKey(object, oldKey, newKey) {
    if (oldKey !== newKey) {
      Object.defineProperty(object, newKey,
        Object.getOwnPropertyDescriptor(object, oldKey));
      delete object[oldKey];
    }
  }

  checkDecimalsFromFormControl(control: FormControl) { // Automatic limit the value to currency format (<any length>.xx)
    if (control.value) {
      let toString = control.value+""
      toString = toString.replace(/^([0-9]*\.[0-9][0-9])(.*)/, "$1") //Only allow <any length>.XX with numbers
      control.setValue(toString)
    }
  }

  // Date

  formatDate(date, fromFormat, toFormat) {
    return moment(date, fromFormat).format(toFormat)
  }

  parseDateToPOJODate(date, fromFormat) {
    let finalDate = null;

    if ( date || date != '') {
      let year: any = moment(date, fromFormat).get('year');
      let month: any = moment(date, fromFormat).get('month');
      let day: any = moment(date, fromFormat).format('DD');

      finalDate = new Date(year, month, day);
    }

    return finalDate;
  }

  formatDateOldWay(date, isPOJODateFormat?) {
    if (!isPOJODateFormat) {date = this.parseDateToPOJODate(date, 'DD-MMM-YYYY')}
    let month = moment(date).format('MMM')
    let day = new Date(date).getDate();
    let year = new Date(date).getFullYear().toString().substr(-2);
    let result = `${day}-${month}-${year}`;

    return result
  }

  getStartOrNowDate(date, format = "DD/MM/YYYY HH.mm", shouldReturnPOJODate = true) {
    let startDate = moment(date, format)
    if (startDate.isBefore(moment())) return shouldReturnPOJODate ? moment().toDate() : moment()
    else return shouldReturnPOJODate ? moment(startDate, format).toDate(): moment(startDate, format);
  }

  generateMonthList(startMonthAndYear, endMonthAndYear) { //e.g. '2025-12'
    var startDate = moment(startMonthAndYear, 'YYYY-MM');
    var endDate = moment(endMonthAndYear, 'YYYY-MM');
    var datesBetween = []

    var startingMoment = startDate;
    while (startingMoment <= endDate) {
        datesBetween.push({
          name : startingMoment.clone().format('MMM YYYY'),
          month : startingMoment.clone().format('M'),
          year : startingMoment.clone().format('YYYY'),
        });// clone to add new object
        startingMoment.add(1, 'month');
    }
    return datesBetween
  }

}
