import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChannelFormComponent } from '@app/_components/channel-form.component';

@Injectable({ providedIn: 'root' })
export class ChannelDialogService {

  private dialogRef: MatDialogRef<ChannelFormComponent>;

  constructor(public dialog: MatDialog) { }

  openDialog(data?: any): MatDialogRef<ChannelFormComponent> {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      if(data){
          this.dialogRef = this.dialog.open(ChannelFormComponent, {
            width: '400px',
            data: data,
            disableClose: true
          });
      }else{
        this.dialogRef = this.dialog.open(ChannelFormComponent, {
          width: '300px',
          disableClose: true
        });

      }

      this.dialogRef.afterClosed().subscribe(result => { });
      return this.dialogRef;
  }

}
