import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { environment } from '@environments/environment';
import { Outlet } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class OutletService {

    invokeRefreshOutletList = new EventEmitter();
    subsVar: Subscription;

    constructor(private http: HttpClient,public _globals: Globals) { }

    getAll() {
        return this.http.post<any>('/fd/api/getoutletslist',{
          "locale": this._globals.locale
        });
    }

    getById(id: Number) {
        return this.http.post<any>('/fd/api/getoutletdetails',
        {
          "locale": this._globals.locale,
          "outletId": id
        });
    }

    getOutletId(id: Number) {
        return this.http.post<any>('/fd/api/generateoutletid',{"retailerId": id });
    }

    add(outlet: Outlet) {
        return this.http.post<any>('/fd/api/addoutlet', outlet);
    }

    update(outlet: Outlet) {
        return this.http.post<any>('/fd/api/updateoutlet', outlet);
    }

    delete(outlet: Outlet) {
        return this.http.post<any>('/fd/api/deleteoutlet', outlet);
    }

    refreshOutletList() {
      this.invokeRefreshOutletList.emit();
    }
}
