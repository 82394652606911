import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { AlertService, SettingsService, UtilityService} from '@app/_services';
import { OffDay } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-off-day',
  templateUrl: './off-day.component.html',
  styleUrls: ['./off-day.component.scss']
})
export class OffDayComponent implements OnInit {

  offList: OffDay[] = [];
  stateList : any = new Array();

  columnsToDisplay: string[] = ['name','date','state'];
  dataSource: MatTableDataSource<OffDay>;
  broadcastMessage = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private settingsService: SettingsService,
    private utilityService: UtilityService,
    private globals: Globals,
    public dialog: MatDialog
  ) { this.getStateList();}


    ngOnInit() {
      this.settingsService.invokeRefreshOffDayList.subscribe(
        (name: string) => {
          this.getOffDayList();
        }
      );
      this.getOffDayList();
    }

    getStateList(){
      this.utilityService.get().pipe(first()).subscribe(
          data => {
            if (data.status !== 'error') this.stateList = data.response.states;
          },
          error => { console.log(error); }
      );
    }

    getOffDayList() {
      this.settingsService.getOffDayList()
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              // console.log(this.stateList);
              if (data.status !== 'error'){
                this.offList = data.response;
                this.dataSource = new MatTableDataSource(this.offList);
                this.dataSource.sort = this.sort;
                this.sortingDate();
                this.dataSource.paginator = this.paginator;
              } else {
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    sortingDate(){

      this.dataSource.sortingDataAccessor = (item, property): string | number => {
        let date = moment(item.date, "DD/MM/YYYY").format("X");

        switch (property) {
          case 'date': return date;
          default: return item[property];
        }
      };

    }


  addOffday() {
    const dialogRef = this.dialog.open(OffDayDialogContent, {
      disableClose: true,
      width: '500px'
    });
  }

  editOffday(data) {
    const dialogRef = this.dialog.open(OffDayDialogContent, {
      disableClose: true,
      width: '500px',
      data: data
    });
  }

  getState(data){
    // console.log(this.stateList);
    let state:any = '';
    let list:any = this.stateList;

    if(this.stateList.length > 0){
      data.forEach(function(count,index){
        count = count - 1;
        if(count != -1){
          state += list[count].name;
          if(index != data.length - 1){
            state += ' , ';
          }
        }else{
          state = 'All';
        }
      });
    }

    return state;
  }


}



// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'off-day-dialog',
  templateUrl: './off-day-form.html',
  styleUrls: ['./off-day.component.scss']
})
export class OffDayDialogContent implements OnInit {

  submitted = false;
  offData: OffDay = new OffDay();
  stateList : any = new Array();

  name = new FormControl('', [Validators.required]);
  startDate = new FormControl('', [Validators.required]);
  endDate = new FormControl('', [Validators.required]);
  stateIds = new FormControl('', [Validators.required]);

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private alertService: AlertService,
      private settingsService: SettingsService,
      private globals: Globals,
      public dialogRef:MatDialogRef <OffDayComponent>,
    ){ }

  ngOnInit() {
      this.stateList = this.globals.utilityList.states;
      this.offData.locale = this.globals.locale;
  }

  addOffday() {
    this.submitted = true;
    if (this.name.invalid) {
        return;
    }
    this.offData.name = this.name.value;
    this.offData.startDate = moment(this.startDate.value).format('YYYY-MM-DD');
    this.offData.endDate = moment(this.endDate.value).format('YYYY-MM-DD');
    this.offData.stateIds = this.stateIds.value;
    // console.log(this.offData);

    this.settingsService.addOffday(this.offData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshOffDayList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }

  refreshOffDayList(){
    this.settingsService.refreshOffDayList();
  }

  //No future date can be selected
  minDate = new Date(Date.now());
  getMinDate(event){
    this.minDate = event.value;
  }

}
