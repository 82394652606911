import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, ProjectService, DashboardService } from '@app/_services';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@app/globals';
import { Project } from '@app/_models';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: "admin-dashboard-component",
  templateUrl: "./admin-template.component.html",
  styleUrls: ["./index.component.scss"]
})
export class AdminDashboardComponent implements OnInit {

  ytdData : any = {};
  router: string;
  projectList: Project[] = [];
  projectId;
  projectPeriod = '-';
  salesSummary : any = {};
  sessions : any = {};
  minDate = new Date(Date.now());

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private dashboardService: DashboardService,
    private globals: Globals,
    private _router: Router
  ) {
    this.router = _router.url;
  }

  ngOnInit() {
    this.getYTDData();
    this.getProjectList();
  }

  getYTDData(){
    this.dashboardService.getYTDData().pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            this.ytdData = data.response;
            // console.log(this.ytdData);
          }else{
            console.log(data.errorMessage);
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
        });
  }

  getProjectList(){
    this.projectService.getSimpleList().pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.projectList = data.response;
            this.projectId = this.projectList[0].id;

            // TESTING
            // this.projectId = 55;
            this.selectProject();

          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
        });
  }

  getMinDate(event){
    //No future date can be selected
    this.minDate = event.value;
  }

  selectProject(){
    const project = this.projectList.filter(list => list.id === this.projectId);
    // console.log(project);
    // console.log(this.projectId);

    let sDate = moment(project[0].startDate,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
    let eDate = moment(project[0].endDate,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');

    this.projectPeriod = sDate + ' - ' + eDate;
    this.dashboardService.fetchId(this.projectId);

    this.getDashboardData(this.projectId,'salesSummary');
    this.getDashboardData(this.projectId,'sessions');
  }

  getDashboardData(id, type){

    let data: any = {};
    data.projectId = id;
    data.type = type;
    // console.log(data);

    this.dashboardService.getDashboardData(data).pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            this[type] = data.response;
            if(type === 'salesSummary'){
              let dataSetSalesSummarySales = data.response.sales.achievement;
              let dataSetSalesSummarySampling = data.response.sampling.samplingAchievement;
              let dataSetSalesSummaryROI = data.response.roi.roiAchieved;
              this.getChart(dataSetSalesSummarySales, '#fff3db', '#FFC757', 'Sales');
              this.getChart(dataSetSalesSummarySampling, '#ffcfcf', '#ec4c4c', 'Sampling');
              this.getChart(dataSetSalesSummaryROI, '#fff3db', '#FFC757', 'ROI');
            } else if(type === 'sessions'){
              let dataSetSalesSummarySales = data.response.sessions.sessionsToGo;
              this.getChart(dataSetSalesSummarySales, '#d4fce6', '#0f8945', 'Sessions');
            }
          }else{
            console.log(data.errorMessage);
            // this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
    });

  }

  getFormattedNumber(number){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getChart(percentage, colorStart, color, chartName){
    const canvas = <HTMLCanvasElement> document.getElementById(`chartJS${chartName}`);
    const ctx1 = canvas.getContext('2d');

    var gradientColors = [
      {
        start: colorStart,
        end: color
      },
      {
        start: '#fff',
        end: '#fff'
      }
      ];
      
      var gradients = [];
      
      gradientColors.forEach( function( item ){
          var gradient = ctx1.createLinearGradient(0, 0, 200 , 0);
          gradient.addColorStop(0, item.start);
          gradient.addColorStop(1, item.end);
          gradients.push(gradient);
      });

    var options1 = {
      type: 'doughnut',
      data: {
        datasets: [
         {
          data: [percentage, percentage < 100 ? 100 - percentage : 0],
          backgroundColor: gradients,
          borderColor: [
            '#fff',
          ],
          borderWidth: 5
          }
        ]
      },
      options: {
       rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        cutoutPercentage: 85
      }
    }
    // var ctx1 = document.getElementById('chartJSContainer').getContext('2d');
    new Chart(ctx1, options1);

    const canvasContainer = <HTMLCanvasElement> document.getElementById(`chartJS${chartName}Container`);
    const ctx2 = canvasContainer.getContext('2d');
    
    var options2 = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [percentage, percentage < 100 ? 100 - percentage : 0],
            backgroundColor: [
              "rgba(0,0,0,0)"
            ],
            borderColor: [
              "rgba(0,0,0,0)",
            ],
            borderWidth: 5
          }
        ]
      },
      options: {
        cutoutPercentage: 85,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    }
    
    // var ctx2 = document.getElementById('secondContainer').getContext('2d');
    new Chart(ctx2, options2);
  }

}
