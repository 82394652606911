import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { AlertService, ProjectService, ReportService } from '@app/_services';
import { Project } from '@app/_models';
import { Globals } from '@app/globals';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  projectList: Project[] = [];
  projectId ;
  clientId;
  role;
  userType = new FormControl(null, [Validators.required]);

  summary: any;
  sales: any;
  sampling: any;
  oos: any;
  attendance: any;
  tasks: any;
  report: any;

  userList = [
    {"value": "ROLE_PROMOTER", "name": "Promoter"},
    {"value": "ROLE_SUPERVISOR", "name": "Supervisor"}
  ];

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private globals: Globals
  ) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if(user.response.clientId) this.clientId = user.response.clientId;
    this.role = user.response.accessLevel

    if (this.role === 'ROLE_CLIENT') {
      this.userList = this.userList.filter((el) => {
        return el.value === "ROLE_PROMOTER"
      })
    }
  }

  ngOnInit() {
    this.getList();
  }

  getList(){
    this.projectService.getSimpleList(this.clientId?this.clientId:null).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.projectList = data.response;
          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            // this.alertService.error(error.statusText);
        });
  }

  selectProject(){
    // this.reportService.fetchId(this.projectId);
    // Call All Reports application
    this.getReport('summary');
    this.getReport('sales');
    this.getReport('sampling');
    this.getReport('oos');

    // NOTE: Since only one option, take the value instead. If in the future there is more option, please change these two
    // this.userType.setValue(null)
    this.userType.setValue(this.userList[0].value)

    this.getReport('attendance', 'ROLE_PROMOTER'); // Change this if more options are available

    this.attendance = null;
    this.getReport('tasks', this.userType.value)

    if(this.clientId) this.getReport1();
  }

  getReport(type,userType?:string){
    if(userType) console.log(userType);
    this.reportService.getReport(this.projectId,type,userType).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this[type] = data.response;
            // console.log(type,this[type]);
          }else{
            this[type] = null;
            this.alertService.warn(data.errorMessage);
          }
        },
        error => {
            this[type] = null;
            console.log(error);
            this.alertService.error(error.statusText);
        });
  }

  getReport1(){
    this.reportService.getPostEvalReport(this.projectId).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.report = data.response;
          }else{
            this.report = null;
            // console.log(data.errorMessage);
            // this.alertService.warn(data.errorMessage);
          }
        },
        error => {
            this.report = null;
            console.log(error);
            // this.alertService.error(error.statusText);
        });
  }

  changeListener(inputValue, type){
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    if(file){
      // console.log(file);
      myReader.onloadend = (e) => {
          // console.log(myReader.result);
          this.updateReport(myReader.result,type);
      }
      myReader.readAsDataURL(file);

    }
  }

  updateReport(excel,type){

    let file = excel;
    if(file.indexOf(",") >= 0){
        let temp : string[] = file.split(",");
        file = temp[1];
    }
    // console.log(file,type);
    let data:any = {};
    data.projectId = this.projectId;
    data.excelType = type;
    data.excelData = file;
    // console.log(data);

    this.reportService.upload(data).pipe(first()).subscribe(
      data => {
        // console.log(data);
        if(data.status != 'error'){
          this.selectProject();
          this.alertService.success('Saved');
        }else{
          this.alertService.error(data.errorMessage
            || data.response? data.response.error : null);
        }
      },
      error => {
          console.log(error);
          this.alertService.error(error.statusText);
      }
    );

  }

}
