import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AlertService, SettingsService } from "@app/_services";
import { Announcement } from "@app/_models";
import { Globals } from "@app/globals";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
import { first } from "rxjs/operators";

@Component({
  selector: "app-announcement",
  templateUrl: "./announcement.component.html",
  styleUrls: ["./announcement.component.scss"]
})
export class AnnouncementComponent implements OnInit {
  announcementList: Announcement[] = [];
  columnsToDisplay: string[] = ["header", "date", "isRead", "action"];
  dataSource: MatTableDataSource<Announcement>;
  broadcastMessage = false;
  currentUser = {};
  currentUserId = 0;
  userProjects = [];
  userList = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private settingsService: SettingsService,
    private globals: Globals,
    public dialog: MatDialog
  ) {
    this.currentUser = this.globals.currentUser.response;
    this.currentUserId = this.globals.currentUser.response.userId;
    
    this.settingsService.invokeRefreshAnnouncementList.subscribe(
      (name: string) => {
        this.getAnnouncementList();
      }
    );
    this.getAnnouncementList();
  }

  ngOnInit() {}
  getAnnouncementList() {
    this.settingsService
      .getAllAnnouncement(this.globals.locale)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.announcementList = data.response.announcements;
            this.dataSource = new MatTableDataSource(this.announcementList);
            this.dataSource.sort = this.sort;
            this.sortingDate();
            this.dataSource.paginator = this.paginator;

            this.getSettingsData();
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  sortingDate(){

    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      let date = moment(item.date, "DD/MM/YYYY HH:mm").format("X");

      switch (property) {
        case 'date': return date;
        default: return item[property];
      }
    };

  }

  getSettingsData() {
    this.settingsService
      .getSettingsData(this.globals.locale, this.currentUserId)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.userProjects = data.response.projects;
            this.userList = data.response.users;
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addAnnouncement() {
    const dialogRef = this.dialog.open(AnnouncementDialogContent, {
      disableClose: true,
      width: "500px",
      data: { projects: this.userProjects, users: this.userList }
    });
  }

  editAnnouncement(data){
    const dialogRef = this.dialog.open(AnnouncementDialogContent, {
      disableClose: true,
      width: "500px",
      data: { annoData:data, projects: this.userProjects, users: this.userList }
    });
  }

  deleteAnnouncement(row) {
    const result = confirm(
      "Are you sure you want to delete this announcement ?"
    );

    if (result) {
      this.settingsService
        .deleteAnnouncement(row.id)
        .pipe(first())
        .subscribe(
          data => {
            // console.log(data);
            if (data.status !== "error") {
              this.settingsService.refreshAnnouncementList();
            } else {
              this.alertService.error(data.errorMessage);
            }
          },
          error => {
            console.log(error);
            this.alertService.error(error.statusText);
          }
        );
    }
  }
}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "announcement-dialog",
  templateUrl: "./announcement-form.html",
  styleUrls: ["./announcement.component.scss"]
})
export class AnnouncementDialogContent implements OnInit {
  submitted = false;
  annoData: Announcement = new Announcement();
  details = new FormControl("", [Validators.required]);
  title = new FormControl("", [Validators.required]);
  content = new FormControl("", [Validators.required]);
  projectType = new FormControl("", [Validators.required]);
  project = new FormControl("", [Validators.required]);
  userType = new FormControl("", []);
  broadcastMessage = false;
  statusChecked = false;
  currentUser = {};
  currentUserId = 0;

  userList = [
    {"value": "ALL", "name": "All"},
    {"value": "ROLE_PROMOTER", "name": "Promoter"},
    {"value": "ROLE_SUPERVISOR", "name": "Supervisor"}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private settingsService: SettingsService,
    private globals: Globals,
    public dialogRef: MatDialogRef<AnnouncementComponent>
  ) {
    this.currentUser = this.globals.currentUser.response;
    this.currentUserId = this.globals.currentUser.response.userId;
  }

  ngOnInit() {
    this.annoData.locale = this.globals.locale;
    // console.log(this.currentUser);
    if (this.data && this.data.annoData) {
      console.log(this.data);
      this.submitted = true;
      this.userType = new FormControl(this.data.annoData.userType, [Validators.required]);
      this.details = new FormControl(this.data.annoData.description, [Validators.required]);
      this.title = new FormControl(this.data.annoData.header, [Validators.required]);
      this.content = new FormControl(this.data.annoData.content, [Validators.required]);
      this.projectType = new FormControl(this.data.annoData.projectType, [Validators.required]);
      this.statusChecked = this.data.annoData.status;
    }
    if(this.data && this.data.annoData && this.data.annoData.projectType === 5){
      this.project.setValue(this.data.projects.find(el => el.id === this.data.annoData.projectId).id);
    }
  }

  addNewAnnouncement() {
    this.submitted = true;
    if (this.details.invalid) {
      return;
    }
    this.annoData.description = this.details.value;
    this.annoData.header = this.title.value;
    this.annoData.userType = this.userType.value;
    // this.annoData.broadcast = this.broadcastMessage;
    this.annoData.from = this.currentUserId;
    this.annoData.projectType = this.projectType.value;
    this.annoData.project = this.project.value;
    // this.annoData.status = this.status.value;
    this.annoData.content = this.content.value;
    this.annoData.status = this.statusChecked ? 'ACTIVE' : 'INACTIVE';
    this.annoData.type = "ANNOUNCEMENT";
    console.log(this.annoData);

    this.settingsService
      .addAnnouncement(this.annoData)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            this.dialogRef.close(true);
            this.alertService.success("Saved");
            this.refreshAnnouncementList();
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
        }
      );
  }

  refreshAnnouncementList() {
    this.settingsService.refreshAnnouncementList();
  }

  editAnnouncement(){

    this.annoData.notificationId = this.data.annoData.id;
    this.annoData.description = this.details.value;
    this.annoData.header = this.title.value;
    this.annoData.userType = this.userType.value;
    this.annoData.from = this.currentUserId;
    this.annoData.projectType = this.projectType.value;
    this.annoData.project = this.project.value;
    this.annoData.content = this.content.value;
    this.annoData.status = this.statusChecked ? 'ACTIVE' : 'INACTIVE';
    this.annoData.type = "ANNOUNCEMENT";
    console.log(this.annoData);


    this.settingsService
      .updateAnnouncement(this.annoData)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.status !== "error") {
            this.dialogRef.close(true);
            this.alertService.success("Saved");
            this.refreshAnnouncementList();
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
        }
      );
  }
}
