import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter,
  MatDatepicker,
  MatDialog,
  MatDialogConfig,
  MatTabChangeEvent,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE } from "@angular/material";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from "@angular/router";
import { Globals } from "@app/globals";
import { User } from "@app/_models";
import {
  AlertService,
  SchedulerService,
  UserService,
  UtilityService,
} from "@app/_services";
import * as moment from "moment";
import { Moment } from "moment";
import { Subscriber } from "rxjs";
import { first } from "rxjs/operators";
import { OverviewSupervisorDialogComponent } from "./dialog.component";
import { CUSTOM_DATE_FORMATS } from "../../_constants/dateTime.js"

@Component({
  selector: 'app-overview-supervisor',
  templateUrl: './overview-supervisor.component.html',
  styleUrls: ['./overview-supervisor.component.scss'],
  providers: [
    // for Mat calendar
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
})
export class OverviewSupervisorComponent implements OnInit, OnDestroy {

  isPendingApprovalView: boolean = false
  canEditWithoutApproval: boolean = false
  canEditWithApproval: boolean = false

  requestedBy: number = null;
  supervisorList: User[] = [];
  userId: number = null;
  selectedSV = null;
  visitationData: any[] = [];
  totalOutlets: number | string = "...";
  totalAllowance: number = 0;
  totalActualAllowance: number = 0;
  totalSalary: number = 0;

  outletId = new FormControl(-1);
  status = new FormControl(-1);
  outletList: any[] = [];
  statusList = [
    {"value": -1, "name": "All"},
    {"value": "PENDING", "name": "Pending Check-in"},
    {"value": "COMPLETED", "name": "Check-in"},
    {"value": "CANCELLED", "name": "Cancelled"},
    {"value": "IN_PROGRESS", "name": "In Progress"},
    {"value": "APPROVAL_CANCELLED", "name": "Cancel Pending Approval"},
    {"value": "APPROVAL_REPLACEMENT", "name": "Replacement Pending Approval"},
    {"value": "APPROVAL_PENDING", "name": "New Pending Approval"},
    // {"value": "REJECTED", "name": "Rejected"},
  ];
  minDate = new Date(new Date().getFullYear() - 3, 1, 0); //3 years before
  maxDate = new Date(new Date().getFullYear() + 4, 12, 0); //4 years after
  monthFilter = new FormControl(moment());

  allVisitationList = []
  allowanceSalaryList = []
  approvalAllList = []

  // ================== APPROVAL
  pendingSupervisorList: User[] = []

  // ================== MICS
  allSubscriptions: Subscriber<any>[] = []

  constructor(
    public utilityService: UtilityService,
    private router: Router,
    private userService: UserService,
    private globals: Globals,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setAccessLevel()
    this.isPendingApprovalView = this.router.url === '/overview/pending-approval'
    this.getSupervisorList()
    this.allSubscriptions.push(
      this.schedulerService.refresh.subscribe(() => this.getSupervisor())
    )
  }

  setAccessLevel() {
    const accessLevel = this.globals.currentUser.response.accessLevel
    this.canEditWithoutApproval = accessLevel === "ROLE_SUPER_ADMIN"
    this.canEditWithApproval = accessLevel === "ROLE_COORDINATOR"
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.requestedBy = user.response.userId
  }

  getSupervisorList() {
    let apiToCall = this.userService.getAllSupervisorsByRequestedBy({requestedBy: this.requestedBy})
    if (this.isPendingApprovalView) apiToCall = this.userService.getAllPendingSupervisorsByRequestedBy({requestedBy: this.requestedBy})
    apiToCall.pipe(first())
      .subscribe( data => {
        this.supervisorList = data.response.filter(user => user.status === 'ACTIVE')
      },
      (error) => {
        console.log(error);
        this.alertService.error("Supervisors Not Found");
      });
  }

  //Event by user

  handleSelectMonth(normalizedValue: Moment, datepicker?: MatDatepicker<Moment>) {
    const ctrlValue = this.monthFilter.value;
    if (!datepicker) { // with datepicker = selected month
      ctrlValue.year(normalizedValue.year());
      this.monthFilter.setValue(ctrlValue);
    } else {
      datepicker.close() // Close datepicker to avoid user pick date
      ctrlValue.month(normalizedValue.month());
      this.monthFilter.setValue(ctrlValue);
      this.getSupervisor() // Only call when both month and year obtained
    }
  }

  getSupervisor() {
    this.clearFilter()
    const payload = {
      userId: this.userId,
      month: moment(this.monthFilter.value).format('M'),
      year: moment(this.monthFilter.value).format('YYYY'),
      requestedBy: this.requestedBy,
    }
    console.log("getSupervisor(): Sending to API", payload)
    this.userService
      .getSupervisorDetails(payload)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.status === 'ok') {
            console.log("Got from API", data)
            this.selectedSV = Object.assign({},data.response);
            // this.getProjects(); //Initiate trigger
            this.totalOutlets = this.selectedSV.totalOutletCount ? this.selectedSV.totalOutletCount : 0
            if (this.selectedSV.projects) {
              if (this.selectedSV.visitations.length > 0) {
                this.setReplacementOutlet();

                this.initiateList();
                this.extractVisitations();
                this.extractAllowance();
                this.limitChangableAllowance();
                this.extractSalary();
                this.getApprovalAllList();
              }
              this.getTotalAllowanceAndSalary();

              this.generateOutletList();
            }
            this.getScheduler();
          } else {
            console.log(data);
          }
        },
        (error) => {
          console.log(error);
          this.alertService.error("Data Not Found");
        }
      );
  }

  // If the outlet row only have replacement visit, it is a replacement outlet
  setReplacementOutlet() {
    this.selectedSV.visitations = this.selectedSV.visitations.map(outlet => {
      let haveReplacement = false
      let haveOthersVisit = false
      outlet.data.forEach(day => {
        const dayData = day[Object.keys(day)[0]]
        dayData.forEach(visit => {
          if (visit) {
            let status = visit.visitationStatus
            if (status == 'REPLACEMENT' || status == 'APPROVAL_REPLACEMENT') haveReplacement = true
            else haveOthersVisit = true
          }
        })
      })
      outlet.isReplacementOutlet = false
      if (haveReplacement && !haveOthersVisit) outlet.isReplacementOutlet = true
      return outlet
    })
  }

  initiateList() {
    this.allowanceSalaryList = []
    this.approvalAllList = []
    this.allVisitationList = []

    let tempList = []
    let tempList2 = []
    let tempList3 = []
    // Initiate with the date and other
    this.selectedSV.visitations[0].data.forEach(visit => {
      tempList.push({ //allowanceSalaryList
        visitationDate: Object.keys(visit)[0],
      })
      tempList2.push({ //approvalAllList
        visitationDate: Object.keys(visit)[0],
        visitationIds: [],
        allowanceIds: [],
      })
      tempList3.push({ //allVisitationList
        visitationDate: Object.keys(visit)[0],
        visits: [],
      })
    })
    this.allowanceSalaryList = tempList.slice()
    this.approvalAllList = tempList2.slice()
    this.allVisitationList = tempList3.slice()
  }

  extractVisitations() {
    this.selectedSV.visitations.forEach(outlet => {
      outlet.data.forEach(visit => {
        let visits = visit[Object.keys(visit)[0]]
        if (visits.length > 0) {
          visits.forEach( visit => {
            let index = this.allVisitationList.findIndex(el => el.visitationDate === visit.visitationDate)
            let haveDuplicate = this.allVisitationList.findIndex(el => el.visits.visitationId === visit.visitationId) !== -1
            if (!haveDuplicate) this.allVisitationList[index].visits.push(visit)
          })
        }
      })
    })
  }
  extractAllowance() {
    this.selectedSV.allowances.forEach(allowance => {
      let date = Object.keys(allowance)[0]
      let index = this.allowanceSalaryList.findIndex(el => el.visitationDate === date)
      let data = allowance[date].find(el => el.status === 'PENDING')
      if (data) { //add allowance before change too
        this.allowanceSalaryList[index] = data
        data = allowance[date].find(el => el.status === 'APPROVED')
        this.allowanceSalaryList[index].before = data
      } else {
        data = allowance[date][0]
        this.allowanceSalaryList[index] = data
      }
      this.utilityService.renameKey(this.allowanceSalaryList[index], 'visitationDateFormatted', 'visitationDate')
    })
  }
  extractSalary() {
    this.selectedSV.salaries.forEach(salary => {
      let date = Object.keys(salary)[0]
      let index = this.allowanceSalaryList.findIndex(el => el.visitationDate === date)
      this.allowanceSalaryList[index].salary = salary[date]
    })
  }
  limitChangableAllowance() {
    const now = moment()
    this.allVisitationList.forEach(day => {
      if (day.visitationDate) {
        let index = this.allowanceSalaryList.findIndex(el => el.visitationDate === day.visitationDate)

        let isAllowanceCurrentMonthOnward = moment(day.visitationDate, "DD-MMM-YYYY").isSameOrAfter(now, 'month')
        this.allowanceSalaryList[index].isAllowanceCurrentMonthOnward = isAllowanceCurrentMonthOnward

        let isAllowanceTodayOnward = moment(day.visitationDate, "DD-MMM-YYYY").isSameOrAfter(now, 'day')
        this.allowanceSalaryList[index].isAllowanceTodayOnward = isAllowanceTodayOnward

        if (day.visits.length > 0) this.allowanceSalaryList[index].canAddAllowance = true
      }
    })
  }
  getApprovalAllList() {
    // Get approvals of visitation
    this.allVisitationList.forEach(day => {
      if (day.visits.length > 0) {
        day.visits.forEach(newEl => {
          let index = this.approvalAllList.findIndex(el => el.visitationDate === day.visitationDate)
          if (newEl.visitationStatus === 'APPROVAL_CANCELLED'
            || newEl.visitationStatus === 'APPROVAL_PENDING') {
            let duplicate = this.approvalAllList[index].visitationIds
              .findIndex(oldElVisitationId =>  oldElVisitationId === newEl.visitationId)
            if (duplicate === -1) this.approvalAllList[index].visitationIds.push(newEl.visitationId)
          }
        })
      }
    })
    //Get approvals of allowance
    this.allowanceSalaryList.forEach(day => {
      if (day.status ? day.status === 'PENDING' : false) {
        let index = this.approvalAllList.findIndex(el => el.visitationDate === day.visitationDate)
        this.approvalAllList[index].allowanceIds.push(day.id)
      }
    })
  }

  getTotalAllowanceAndSalary() {
    // console.log(this.selectedSV)
    this.totalAllowance = this.selectedSV.totalMonthlyAllowance;
    this.totalActualAllowance = this.selectedSV.actualMonthlyAllowance;
    this.totalSalary = this.selectedSV.totalMonthlySalary;
  }

  generateOutletList() { // generate from visitation obj
    this.outletList = []
    this.selectedSV.visitations.forEach(visit => {
      let duplicate = this.outletList.filter(oldOutlet => oldOutlet.outletId === visit.outletId)
      if (duplicate.length <= 0) this.outletList.push(visit)
    })
    this.outletList.push({outletId: -1, outletName: "All"})
  }

  openDialog(type: 'outletDetails' | 'addVisitation' | 'modifyAllowance', payload = this.selectedSV) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      payload : {
        supervisorId : this.selectedSV.userId,
        requestedBy : this.requestedBy,
        allowanceSalaryList: this.allowanceSalaryList,
        ...payload,
      },
      type : type,
    }
    if (type === 'outletDetails') dialogConfig.panelClass = 'hide-scrollbar'

    this.dialog.open(OverviewSupervisorDialogComponent, dialogConfig);
  }

  // ================== Scheduler

  onTabClick(event: MatTabChangeEvent) {
    if (event.index === 2) this.getScheduler()
  }

  getScheduler() {
    const payload = {
      data: this.selectedSV.visitations,
      requestedBy: this.requestedBy,
      status: this.status.value,
      outletId: this.outletId.value,
      allowanceSalaryList: this.allowanceSalaryList,
      supervisorId: this.selectedSV.userId,
      approvalAllList: this.approvalAllList,
      projectList: this.selectedSV.projects,
    }
    this.schedulerService.changeHideExpiredScheduler(true)
    this.schedulerService.passData(payload)
  }

  filter() {
    const payload = {
      status: this.status.value,
      filterOutletId: this.outletId.value
    }
    this.schedulerService.fetchFilter(payload)
  }

  clearFilter(){
    this.status.setValue(-1);
    this.outletId.setValue(-1);

    this.filter();
  }


  // ================== MISC

  ngOnDestroy() {
    this.allSubscriptions.forEach(sub => sub.unsubscribe())
  }

  formatStatus(status: string) {
    switch(status) {
      case "ON_GOING": return 'On going'
      case "UP_COMING": return 'Upcoming'
      case "ENDED": return 'Ended'
    }
  }
}
