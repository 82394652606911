import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AlertService, SchedulerService, UserService, UtilityService } from "@app/_services";
import * as moment from "moment";
import { first } from "rxjs/operators";

@Component({
    selector: 'app-overview-supervisor-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./overview-supervisor.component.scss']
})
// ONLY cater for Display Outlet Details, Add Visitation and Modify Allowance in Overview Supervisor
export class OverviewSupervisorDialogComponent implements OnInit {

  canEditWithoutApproval: boolean
  canEditWithApproval: boolean

  submitted = false;
  hasError = false;
  errorMessage: string = '';

  type: string = "";
  selectedSV: any = {};

  // Outlet Details
  outlets: any[] = null;
  projectName: string = "";

  // Shared with visitation and allowance
  date: any = new FormControl({value: '', disabled: true}, [Validators.required]);

  // Visitation
  minDate: Date;
  project
  outlet
  projectList = [];
  projectListFiltered = [];
  projectListSearch = new FormControl()
  outletList = [];
  outletListFiltered = [];
  outletListSearch = new FormControl()
  hasOutlet: boolean = false;

  // Allowance
  allowance
  haveExistedAllowance
  isPendingApproval
  canChangeDate: boolean = true

  isLoading:boolean = false


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
    private userService: UserService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<OverviewSupervisorDialogComponent>,
  ) {
    dialogRef.disableClose = false;
  }

  ngOnInit() {
    console.log('Data for Dialog: ', this.data)
    this.type = this.data.type
    this.minDate = new Date()
    this.type === 'outletDetails' ? this.initiateOutletDetails() : null
    if (this.type === 'addVisitation') {
      this.project = new FormControl('', [Validators.required]);
      this.outlet = new FormControl('', [Validators.required]);
      this.initiateVisitation()
    }
    if (this.type === 'modifyAllowance') {
      this.allowance = new FormControl(0.00, [Validators.required]);
      this.initiateAllowance()
    }
  }

  // ======== OUTLET DETAILS PART
  initiateOutletDetails() {
    this.outlets = this.data.payload.outlets
    this.projectName = this.data.payload.projectName
    if (this.outlets ? this.outlets.length > 0 : false) this.hasOutlet = true
    if (this.hasOutlet) {
      this.outlets = this.outlets.filter(outlet => outlet.outletId !== -1) //Remove 'All' option
    }
  }

  // VISITATION + ALLOWANCE - Event by user
  dateChangeEvent() {
    if (this.type === 'addVisitation') this.initiateVisitation()
    if (this.type === 'modifyAllowance') this.initiateAllowance()
  }


  // ======== VISITATION part
  initiateVisitation() {
    let payload = this.data.payload
    if (payload.date && payload.projectId && payload.outletId) {
      let dateSelected = moment(payload.date, 'DD-MMM-YYYY').format()
      this.date.setValue(dateSelected)

      this.getProjects()
      let projectFound = this.projectList.find(el => el.projectId == payload.projectId)
      this.project.setValue(projectFound)

      this.getOutlets()
      let outletFound = this.outletList.find(el => el.outletId == payload.outletId)
      this.outlet.setValue(outletFound)
    } else {
      this.getProjects()
    }
  }
  getProjects() {
    this.selectedSV = this.data.payload
    this.project.setValue(null)
    this.project.enable()
    this.projectList = this.selectedSV.projects.filter(project => {
      let startDate = moment(project.projectStartDate, 'DD-MM-YYYY')
      let endDate = moment(project.projectEndDate, 'DD-MM-YYYY')
      let check1 = startDate.isSameOrBefore(moment(this.date.value), 'days')
      let check2 = endDate.isSameOrAfter(moment(this.date.value), 'days')
      return check1 && check2
    })
    this.projectListFiltered = this.projectList
    if (this.projectList.length <= 0){
      this.project.disable()
    }
  }

  getOutlets() {
    this.outlet.setValue(null)
    this.outlet.enable()
    if (this.project.value.outlets) {
      this.outletList = this.project.value.outlets
      this.outletListFiltered = this.outletList
      if (this.outletList.length > 0) {
        return
      }
    }
    this.outlet.disable()
  }

  addSupervisorVisitation() {
    this.submitted = true;
    this.isLoading = true;
    this.hasError = false;
    if (this.isAnyInvalid()) {
      this.hasError = true;
      this.errorMessage = "Complete the form before submit.";
      return;
    }
    const payload = {
      requestedBy : this.selectedSV.requestedBy,
      projectId : +this.project.value.projectId,
      outletId : this.outlet.value.outletId,
      visitationDate : moment(this.date.value).format('DD-MMM-YYYY'),
      visitationTime : this.outlet.value.startTime + " - " + this.outlet.value.endTime,
      supervisorId : this.selectedSV.supervisorId,
    }
    console.log('Sending to API', payload)
    this.schedulerService.addSupervisorVisitation(payload)
      .pipe(first())
      .subscribe( data => {
        console.log('Got from API', data)
        if (data.status !== "error") {
          this.handleSuccess()
        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => { this.handleError(error) }
      ,() => {this.isLoading = false;})
  }

  // ====== ALLOWANCE part

  initiateAllowance() {
    if (this.data.payload) { // ADD
      this.selectedSV = this.data.payload
    } else { // UPDATE
      this.selectedSV.supervisorId = this.data.supervisorId
      this.selectedSV.requestedBy = this.data.requestedBy
      this.canEditWithApproval = this.data.canEditWithApproval
      this.canEditWithoutApproval = this.data.canEditWithoutApproval
    }

    let date = this.data.date
    if (date) { // UPDATE
      const chosenDate = moment(date.visitationDate, 'DD-MMM-YYYY').format()
      this.date.setValue(chosenDate)
    }
    this.haveExistedAllowance = false
    this.allowance.setValue(0.00)
    // Get existing allowance if any
    if (date ? date.allowance : false) {
      if (date.status === 'PENDING' && this.canEditWithApproval) {
        this.isPendingApproval = true
      }
      this.allowance.setValue(date.allowance)
      this.haveExistedAllowance = true
      this.canChangeDate = false
    }
  }

  addUpdateCancelAllowance(option?) {
    let apiToCall
    if (this.isAnyInvalid()) {
      this.errorMessage = "Complete the form before submit.";
      return;
    }

    this.isLoading = true;
    let payload = {
      "svId": +this.selectedSV.supervisorId,
      "requestedBy": +this.selectedSV.requestedBy,
      "visitationDate": moment(this.date.value).format('DD-MMM-YYYY'),
      "allowance": +this.allowance.value
    }

    if (option === 'cancel') apiToCall = this.userService.cancelApprovalAllowance(payload)
    else if (this.haveExistedAllowance) apiToCall = this.userService.updateAllowance(payload)
    else apiToCall = this.userService.addAllowance(payload)

    console.log('Sending to API', payload)
    apiToCall.pipe(first())
      .subscribe(data => {
        console.log('Got from API', data)
        if (data.status !== "error") {
          if (data.response.error) {
            this.handleError({statusText : data.response.error})
          } else {
            this.handleSuccess()
          }
        } else {
          this.handleError({statusText : data.errorMessage})
        }
      },
        error => { this.handleError(error) }
        ,() => {this.isLoading = false;}
      )
  }


  // All submit form events by user
  saveEvent() {
    if (this.type === 'addVisitation') this.addSupervisorVisitation()
    if (this.type === 'modifyAllowance') this.addUpdateCancelAllowance()
  }


  // MISC

  // for matDatepickerFilter
  filterCanAddAllowance = (date) => {
    if (this.type === 'modifyAllowance') {
      let formattedDate = moment(date).format('DD-MMM-YYYY')
      let foundDate = this.data.payload.allowanceSalaryList.find(el => el.visitationDate == formattedDate)
      return foundDate ? foundDate.canAddAllowance : false
    }
    return true
  }

  // for search function
  filterList(type: 'projectList' | 'outletList', option?: 'unfilter') {
    let search = this[type + 'Search'].value
    if (!search || search === "" || option === 'unfilter') {
      this[type + 'Filtered'] = this[type] //unfilter
      return
    }
    search = search.toLowerCase()
    let result = this[type].filter(el => {
      if (type == 'outletList') return el.outletName.toLowerCase().indexOf(search) > -1
      if (type == 'projectList') return el.projectName.toLowerCase().indexOf(search) > -1
    })
    this[type + 'Filtered'] = result
  }

  isAnyInvalid() {
    let haveInvalid = false
    // Visitation
    if (this.date) this.date.invalid ? haveInvalid = true : null
    if (this.project) this.project.invalid ? haveInvalid = true : null
    if (this.outlet) this.outlet.invalid ? haveInvalid = true : null
    // Allowance
    if (this.allowance) this.allowance.invalid ? haveInvalid = true : null

    return haveInvalid
  }

  handleSuccess() {
    this.dialogRef.close(true);
    this.alertService.success("Saved");
    this.schedulerService.refreshOverviewSupervisor()
  }

  handleError(error) {
    console.error('Error from API', error);
    this.dialogRef.close(true);
    this.alertService.error(error.statusText);
  }
}
