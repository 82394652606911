import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '@app/globals';
import { AlertService } from '@app/_services/alert.service';
import { ProjectService } from '@app/_services/project.service';
import { DialogService } from '@app/_services/dialog.service';
import { Project } from '@app/_models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs/operators';

@Component({
    selector: 'project-details',
    templateUrl: 'project-details.component.html',
    styleUrls: ['../project/project.component.scss']
})

export class ProjectDetailsComponent implements OnInit {

    sub;
    projectId : number;
    errorMessage : string;
    projectStatus : string;
    @Input() disableUpload: boolean;

    projectCreationColumns: string[];
    projectCreationDataSource : MatTableDataSource<any>;

    // supervisorsInProjectColumns: string[];
    // supervisorsInProjectDataSource : any = [];
    // supervisorsInProjectParseStatus:boolean = true;

    promotersInProjectColumns: string[];
    promotersInProjectDataSource : any = [];
    promotersInProjectParseStatus:boolean = true


    productsInProjectColumns: string[];
    productsInProjectDataSource : any = [];

    allowancesInProjectColumns: string[];
    allowancesInProjectDataSource : any = [];

    internalUseInProjectColumns: string[];
    internalUseInProjectDataSource : any = [];

    productForSampleInProjectColumns: string[];
    productForSampleInProjectDataSource : any = [];

    materialForSampleInProjectColumns: string[];
    materialForSampleInProjectDataSource : any = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('projectCreation') projectCreation: ElementRef;
    @ViewChild('promotersInProject') promotersInProject: ElementRef;
    @ViewChild('productsInProject') productsInProject: ElementRef;
    @ViewChild('allowancesInProject') allowancesInProject: ElementRef;
    @ViewChild('internalUseInProject') internalUseInProject: ElementRef;
    @ViewChild('productForSampleInProject') productForSampleInProject: ElementRef;
    @ViewChild('materialForSampleInProject') materialForSampleInProject: ElementRef;

    typeList = [ //In order
      'projectCreation',
      'promotersInProject',
      'productsInProject',
      'allowancesInProject',
      'internalUseInProject',
      'productForSampleInProject',
      'materialForSampleInProject'
    ]

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private globals: Globals,
      public dialog: MatDialog,
      private alertService: AlertService,
      private projectService: ProjectService,
      private dialogService: DialogService
    ) {
      this.sub = this.route.params.subscribe(params => {
        this.projectId = Number(params['id']);
      });
    }

    ngOnInit() {
      this.getUploadedData('projectCreation')
      //   .then(() => {
      //     this.getUploadedData('supervisorsInProject')
      //   } ).then(() => {
      //   this.getUploadedData('promotersInProject')
      // });
      // this.getProject(this.projectId);

      // this.getUploadedData('supervisorsInProject');
      // this.getUploadedData('promotersInProject');
      // this.getUploadedData('productsInProject');
      // this.getUploadedData('allowancesInProject');
      // this.getUploadedData('internalUseInProject');
      // this.getUploadedData('productForSampleInProject');
      // this.getUploadedData('materialForSampleInProject');
    }

    getProject(id){
      this.projectService.getById(id).pipe(first()).subscribe(
          data => {
            if(data.status != 'error'){
              this.projectStatus = data.response.status;
            }
          }
      )
    }

    tabChanged(tab){
      let type = this.typeList[tab.index]
      this.getUploadedData(type);
    }

    downloadExcel(type){

      let data:Project = new Project();
      data.projectId = this.projectId;
      data.excelType = type;

      console.log(data);

      this.projectService.getExcel(data).pipe(first()).subscribe(
        data => {
          console.log(data);
          if(data.status != 'error'){
            // this.alertService.info('Downloading...');
            let dialog :any = {};
            dialog.msg = 'Downloading...';
            this.openDialog(dialog);

            window.open(data.response.generatedExcelUrl, '_blank');
          }else{
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
        });

    }

    parseExcel(inputValue, type) {
      var file:File = inputValue.files[0];
      var myReader:FileReader = new FileReader();

      if (file) {
        // console.log(file);
        myReader.onloadend = (e) => {
          // console.log(myReader.result);
          this.uploadExcel(myReader.result,type);
        }
        myReader.readAsDataURL(file);

      }
    }

    uploadExcel(excel,type){

      let file = excel;
      if(file.indexOf(",") >= 0){
          let temp : string[] = file.split(",");
          file = temp[1];
      }

      let data: Project = new Project();
      data.locale = this.globals.locale;
      data.projectId = this.projectId;
      data.excelType = type;
      data.excelData = file;
      console.log(data);

      this.projectService.upload(data).pipe(first()).subscribe(
        data => {
          console.log('Got from API', data);
          this.resetInputFieldAndRefreshData(type);
          if(data.status != 'error'){
            if(data.response.uploadStatus){
              // this.alertService.success('Uploaded');
              let dialog :any = {};
              dialog.msg = 'Uploaded';
              this.openDialog(dialog);
              // this.getUploadedData(type);
            }else{
              if(!data.response.parseStatus){
                let temp = type + 'ParseStatus';
                this[temp] = data.response.parseStatus;
                // this.alertService.error('Failed. Incorrect data provided.');
                let dialog :any = {};
                dialog.title = 'Failed - Incorrect data provided';
                dialog.footer = true;
                // dialog.msg = 'Failed. Incorrect data provided.';

                this.errorMessage = data.response.wrongEmails ? data.response.wrongEmails.toString() : data.response.error;
                dialog.msg = '<div class="p-2 error-box m-2">' +
                                '<p class="m-0"><b>Incorrect data : </b><br>'+ this.errorMessage +'</p>' +
                              '</div>'

                this.openDialog(dialog);
              }else{
                // this.alertService.success('Saved');
                let dialog :any = {};
                dialog.msg = 'Saved.';
                this.openDialog(dialog);
              }
            }
          }else{
            this.alertService.error(data.errorMessage
                || data.response? data.response.error : null);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );

    }

    getUploadedData(type): Promise<any>{

      let data:Project = new Project();
      data.projectId = this.projectId;
      data.uploadedDataType = type;

      this.projectService.getUploadedData(data).pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            // console.log(type,data.response);
            this.initTable(type,data.response);
          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            console.log(error.statusText);
        });

        return Promise.resolve((() => {
            // code here
            return '';
        })());
    }

    initTable(dataType,data){
      let columns = dataType + 'Columns';
      this[columns] = data.headers;

      let source = dataType + 'DataSource';
      // this[source] = data.data;
      this[source] = new MatTableDataSource(data.data);

      // console.log(source,this[source]);
      // this[source].sort = this.sort;
      // this[source].paginator = this.paginator;

      // setTimeout(() => this[source].paginator = this.paginator);

    }

    parseString(data){
      // let newD = data.split(',').join('\n');
      // console.log(newD);
      return data;
    }

    resetInputFieldAndRefreshData(type){
      this[type].nativeElement.value = ""
      this.getUploadedData(type)
    }

    openDialog(data) {

        const dialogData = {
          title: data.title ? data.title : '',
          message: data.msg ? data.msg : '',
          footer: data.footer ? data.footer : false,
          showOKBtn: true,
          showCancelBtn: true
        };

        const dialogRef = this.dialogService.openDialog(
          dialogData, {disableClose: false}
        );


        //Auto Close Dialog
        if(!dialogData.footer){
          setTimeout(function () {
            dialogRef.close();
          }, 1500);
        }

    }
}
