import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { AlertService, TrainingService, ProjectService} from '@app/_services';
import { Training } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  list: Training[] = [];
  columnsToDisplay: string[] = ['projectName','projectStartDate','status'];
  dataSource: MatTableDataSource<Training>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private trainingService: TrainingService,
    private globals: Globals,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.trainingService.subsVar !== undefined) {
      this.trainingService.subsVar.unsubscribe();
      this.trainingService.subsVar = undefined;
    }

    this.trainingService.subsVar = this.trainingService
      .invokeRefreshList.subscribe((name:string) => {
        this.getTrainingList()
      });

    this.getTrainingList();
    // this.openDialog();
  }

  getTrainingList(){
    this.trainingService.getAll()
        .pipe(first())
        .subscribe(
            data => {
              // console.log(data);
              if(data.status != 'error'){
                this.list = data.response.trainingMaterial;
                this.dataSource = new MatTableDataSource(this.list);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                console.log(this.list);
              }else{
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDate(date){
    return moment(date,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
  }

  openDialog(data){
    const dialogRef = this.dialog.open(TrainingDialogContent, {
      disableClose: true,
      data: data,
      width: '500px'
    });
  }

}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'training-dialog',
  templateUrl: './training-dialog.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingDialogContent implements OnInit {

  submitted = false;
  submitData: Training = new Training();
  projectId = new FormControl('', [Validators.required]);
  projectList = new Array();
  uploadContent :any[] = new Array();
  itemUpload :any[] = new Array();
  canEdit = false;
  linkInputField = new FormControl('',[Validators.required,Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/) ]);

  @ViewChild('inputFile') inputFile: ElementRef;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private alertService: AlertService,
      private trainingService: TrainingService,
      private projectService: ProjectService,
      private globals: Globals,
      public dialogRef:MatDialogRef <TrainingComponent>,
    ){ }

  ngOnInit() {

    if(this.data){
      console.log(this.data);
      this.itemUpload = this.data.links;
    }else{
      this.getProjectList();
    }
    if(this.data){
      this.canEdit = this.globals.currentUser.response.accessLevel === 'ROLE_PROJECT_MANAGER' ? this.data.projectManagerId === this.globals.currentUser.response.userId : true;
    }
  }

  getProjectList(){
    this.projectService.getAll()
        .pipe(first())
        .subscribe(
            data => {
              // console.log(data);
              if(data.status != 'error'){
                if(this.globals.currentUser.response.accessLevel === "ROLE_PROJECT_MANAGER"){
                  this.projectList = data.response.filter(el => el.projectManagerId === this.globals.currentUser.response.userId);
                } else {
                  this.projectList = data.response;
                }
              }else{
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
  }

  addItem(){
    if(this.linkInputField.invalid || this.linkInputField.value == null){
      return;
    }
    this.itemUpload.push({'link' : this.linkInputField.value, "type":'link'});
    this.uploadContent.push({"trainingMaterialLink":this.linkInputField.value});

    this.linkInputField = new FormControl();
  }


  // Add file link(PDF/JPEG/PNG)
  changeListener(inputValue){
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    // console.log(file.size);
    if(file){
      if(file.size > 5242880){
        return this.alertService.warn('Maximum upload file size : 5 MB.');
      }
      let type = file.name.split('.').pop();
      if(type != 'docx' && type != 'xlsx'){
        let name = file.name.split('.').slice(0, -1).join('.');
        this.itemUpload.push({'link': name, "type": type});
        myReader.onloadend = (e) => {
            // console.log(myReader.result);
            this.getFileUrl(myReader.result, name);
        }
        myReader.readAsDataURL(file);
      }else{
        this.alertService.warn('File Not Supported');
      }

    }
  }

  getFileUrl(file,name){

    let img = file;
    if(img.indexOf(",") >= 0){
        let temp : string[] = img.split(",");
        img = temp[1];
    }
    // console.log(img);
    this.uploadContent.push({"trainingMaterialData":img,"name":name});
  }

  refreshList(){
    this.trainingService.refreshList();
  }

  uploadMaterial(){

    console.log(this.uploadContent);
    this.submitted = true;
    if(this.data && !this.uploadContent) return;
    if(!this.data && (this.projectId.invalid || this.uploadContent.length == 0)) {
      return;
    }

    if(this.data) this.submitData.projectId = this.data.projectId;
    else if(!this.data) this.submitData.projectId = this.projectId.value;

    this.submitData.trainingMaterials = this.uploadContent;
    console.log(this.submitData);
    console.log(this.uploadContent);

    this.trainingService.upload(this.submitData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if(data.status != 'error'){

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshList();

        }else{
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );

  }


  deleteMaterial(id){

    let data:Training = new Training();
    data.projectTrainingId = id;

    this.trainingService.delete(data)
        .pipe(first())
        .subscribe(
            data => {
              if(data.status != 'error'){
                this.itemUpload = this.itemUpload.filter(item=>item.id != id );
                this.refreshList();
                console.log('Item deleted.');
              }else{
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
  }

  triggerUploadEvt(){
    this.inputFile.nativeElement.click();
  }


}
