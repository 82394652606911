export class Schedule {
    locale: string;
    id: number;
    projectId: number;
    visitationId: number;
    outletId: number;
    userId:number;
    reason: string;
    changeDate: string;
    createReplacement: boolean;

    date: string;
    outletCode: string;
    oldEmail: string;
    newEmail: string;

    salaryIncrement: number;
    salaryIncrementUpdatedById: any
}
