import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, ProjectService, DashboardService } from '@app/_services';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@app/globals';
import { Project } from '@app/_models';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: "dashboard-oos",
  templateUrl: "./oos.component.html",
  styleUrls: ["./oos.component.scss"]
})
export class DashboardOOSComponent implements OnInit {

  projectList: Project[] = [];
  projectId ;
  oosByTypeDateSorted : any[] = [];
  oosByType : any[] = [];
  type = 'oosChart';

  @ViewChild('regionOosChart') regionOosChart: ElementRef;
  @ViewChild('channelOosChart') channelOosChart: ElementRef;
  @ViewChild('retrailerOosChart') retrailerOosChart: ElementRef;
  @ViewChild('oosChart') oosChart: ElementRef;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private dashboardService: DashboardService,
    private globals: Globals
  ) { }

  ngOnInit() {
    this.dashboardService.change.subscribe(id => {
      this.projectId = id;
      // console.log(this.projectId);
      this.getProjectList();
    });
  }

  getProjectList(){
    this.projectService.getSimpleList().pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.projectList = data.response;
            this.selectProject();
          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
        });
  }

  selectProject(){

    const project = this.projectList.filter(list => list.id === this.projectId);
    // console.log(project);

    let sDate = moment(project[0].startDate,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
    let eDate = moment(project[0].endDate,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');

    this.getDashboardData(this.projectId,sDate,eDate,'oosByTypeDateSorted');
    this.getDashboardData(this.projectId,sDate,eDate,'oosByType');
  }

  getDashboardData(id,sDate,eDate,type){

    let data: any = {};
    data.projectId = id;
    data.startDate = sDate;
    data.endDate = eDate;
    data.type = type;
    // console.log(data);

    this.dashboardService.getDashboardData(data).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this[type] = data.response;
            if(type == 'oosByTypeDateSorted'){
              if(this.type == 'regionOosChart') this.initOosChartsData(this[type].oosByDate,'regions','regionOosChart','OOS Movement Performance - Region');
              else if(this.type == 'channelOosChart') this.initOosChartsData(this[type].oosByDate,'channels','channelOosChart','OOS Movement Performance - Channel');
              else if(this.type == 'retrailerOosChart') this.initOosChartsData(this[type].oosByDate,'retailers','retrailerOosChart','OOS Movement Performance - Retailer');
            }else{
              if(this.type == 'oosChart') this.initOosChartData(this[type].oosByDate,'oosChart','OOS Movement');
            }
          }else{
            console.log(data.errorMessage);
            // this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
    });

  }

  initOosChartsData(data,type,chartName,chartTitle){
    // console.log(data);
    const filteredData = data.map(data => data[type]);
    const key = data.map(data => data.key);

    const datasets = this.parseData(filteredData);

    // console.log(key);
    this.createOosCharts(datasets,key,chartName,chartTitle);
  }

  parseData(data){
    // console.log(data);
    let temp = [];
    let keyset = [];
    let dataset = [];
    let finalDataset = [];

    data.forEach(function(region){
      const oos = region.map(data => data.oos);
      const label = region.map(data => data.key);
      temp.push(oos);
      if(label.length > 0) keyset = label;
    });
    // console.log(temp);
    dataset = temp.map((col, i) => temp.map(row => row[i] ? row[i] : 0));
    // dataset = temp[0].map((col, i) => temp.map(row => row[i] ? row[i] : 0));
    // console.log(dataset);
    keyset.forEach(function(key,index){
      let randomColor = Math.floor(Math.random()*16777215).toString(16);
      finalDataset.push({
        'label':key ,
        'data': dataset[index],
        'lineTension': 0.2,
        'fill': false,
        'borderColor': '#'+ randomColor,
        'backgroundColor': '#'+ randomColor,
      });
    });

    // console.log(finalDataset);
    return finalDataset;
  }

  public retrailerOosChartC: Chart;
  public regionOosChartC: Chart;
  public channelOosChartC: Chart;
  createOosCharts(datasets,key,chartName,chartTitle) {

     const ctx = this[chartName].nativeElement.getContext('2d');
     let chart = chartName + 'C';
     if (this[chart]) this[chart].destroy();

     this[chart] = new Chart(ctx, {
      type: 'line',
      data: {
        labels: key,
        datasets: datasets,
      },
      options: {
        // responsive: true,
        title: {
          text: chartTitle,
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
                beginAtZero: true,
                stepSize: 1
            }
          }]
        }
      },
    });
  }

  initOosChartData(data,chartName,chartTitle){
    // console.log(data);

      const oos = data.map(data => data.oos);
      const key = data.map(data => data.key);
      // console.log(sales,key,target);
      this.createSalesChart(key,oos,chartName,chartTitle);
  }

  public myChart: Chart
  createSalesChart(key,oos,chartName,chartTitle){
      const ctx = this[chartName].nativeElement.getContext('2d');
      if (this.myChart) this.myChart.destroy();
      this.myChart = new Chart(ctx, {
       type: 'bar',
       data: {
         labels: key,
         datasets: [ {
             label: 'Total OOS',
             data: oos,
             type: 'line',
             borderColor: '#f28e2b',
             borderWidth: 3,
             fill: false,
         }
         ],
       },
       options: {
         // responsive: true,
         title: {
           text: chartTitle,
           display: true
         },
         scales: {
           yAxes: [{
             ticks: {
                 beginAtZero: true,
                 stepSize: 1
             }
           }]
         }
       },
     });
  }

}
