import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@app/globals";

import { environment } from "@environments/environment";
import { Image } from "@app/_models";

@Injectable({ providedIn: "root" })
export class ProjectGalleryService {
  invokeRefreshGalleryList = new EventEmitter();
  subsVar: Subscription;

  constructor(private http: HttpClient, public _globals: Globals) {}

  getAllProjects() {
    return this.http.post<any>("/fd/api/getsimplifiedprojectslist", {
      locale: this._globals.locale
    });
  }

  getProjectGallery(projectId: number, userType:string) {
    return this.http.post<any>("/fd/api/getimagegallery", {
      locale: this._globals.locale,
      status: null,
      projectId: projectId,
      userType: userType
    });
  }

  refreshGalleryList() {
    this.invokeRefreshGalleryList.emit();
  }

  deleteImage(id){
    return this.http.post<any>("/fd/api/deleteimageingallery", {
      imageId: id,
    });
  }
}
