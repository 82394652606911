import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { Schedule } from '@app/_models';
import { environment } from '@environments/environment';

interface Report {
  projectId: number;
  excelType: string;
  excelData: string;
};

@Injectable({ providedIn: 'root' })
export class ReportService {

    @Output() change: EventEmitter<number> = new EventEmitter();
    private projectId : number;

    constructor(private http: HttpClient,public _globals: Globals) { }

    getPostEvalReport(id) {
        return this.http.post<any>('/fd/api/getpostevalreport',{ "locale": this._globals.locale, "projectId": id });
    }

    getReport(id,type,userType?:string) {
        let data:any = {};
        data.reportType = type;
        data.projectId = id;
        if(userType) data.userType = userType;
        data.requestBy = this._globals.currentUser.response.userId

        return this.http.post<any>('/fd/api/generatereport',data);
    }

    upload(report: any) {
      report.requestBy = this._globals.currentUser.response.userId
      return this.http.post<any>("/fd/api/excelupload", report);
    }

    //Supervisor
    getSupervisorAttendanceReport(data) {
      return this.http.post<any>("/fd/api/report/supervisor/attendance", data)
    }

    getSupervisorPayrollReport(data) {
      return this.http.post<any>("/fd/api/report/supervisor/payroll", data)
    }

    fetchId(id) {
        this.projectId = id;
        this.change.emit(this.projectId);
    }

    refreshReport() {
        this.change.emit(this.projectId);
    }

}
