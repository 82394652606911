import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AlertService, ProjectService, DashboardService } from '@app/_services';
import { Globals } from '@app/globals';
import { Project } from '@app/_models';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

declare let Chart: any;

@Component({
  selector: "dashboard-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"]
})
export class DashboardSalesComponent implements OnInit {

  projectList: Project[] = [];
  projectId ;
  salesSummary : any = {};
  sessions : any = {};
  salesByType : any[] = [];
  type = 'salesChart';
  chartName = 'Sales Performance';
  isLoading: Boolean = false;

  @ViewChild('regionSalesChart') regionSalesChart: ElementRef;
  @ViewChild('channelSalesChart') channelSalesChart: ElementRef;
  @ViewChild('chainSalesChart') chainSalesChart: ElementRef;
  @ViewChild('salesChart') salesChart: ElementRef;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private dashboardService: DashboardService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.dashboardService.change.subscribe(id => {
      this.projectId = id;
      // console.log(this.projectId);
      this.getProjectList();
    });

    Chart.elements.Rectangle.prototype.draw = function() {

      var ctx = this._chart.ctx;
      var vm = this._view;
      var left, right, top, bottom, signX, signY, borderSkipped, radius;
      var borderWidth = vm.borderWidth;

      // If radius is less than 0 or is large enough to cause drawing errors a max
      //      radius is imposed. If cornerRadius is not defined set it to 0.
      var cornerRadius = this._chart.config.options.cornerRadius;
      if(cornerRadius < 0){ cornerRadius = 0; }
      if(typeof cornerRadius == 'undefined'){ cornerRadius = 0; }

      if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top? 1: -1;
          borderSkipped = vm.borderSkipped || 'bottom';
      } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left? 1: -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
          borderWidth = borderWidth > barSize? barSize: borderWidth;
          var halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          var borderLeft = left + (borderSkipped !== 'left'? halfStroke * signX: 0);
          var borderRight = right + (borderSkipped !== 'right'? -halfStroke * signX: 0);
          var borderTop = top + (borderSkipped !== 'top'? halfStroke * signY: 0);
          var borderBottom = bottom + (borderSkipped !== 'bottom'? -halfStroke * signY: 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
              top = borderTop;
              bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
              left = borderLeft;
              right = borderRight;
          }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |
      var corners = [
          [left, bottom],
          [left, top],
          [right, top],
          [right, bottom]
      ];

      // Find first (starting) corner with fallback to 'bottom'
      var borders = ['bottom', 'left', 'top', 'right'];
      var startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
          startCorner = 0;
      }

      function cornerAt(index) {
          return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      var corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (var i = 1; i < 4; i++) {
          corner = cornerAt(i);
          let nextCornerId = i+1;
          if(nextCornerId == 4){
              nextCornerId = 0
          }

          let nextCorner = cornerAt(nextCornerId);

          let width = corners[2][0] - corners[1][0];
          let height = corners[0][1] - corners[1][1];
          let x = corners[1][0];
          let y = corners[1][1];

          var radius = cornerRadius;
          // Fix radius being too large
          if(radius > Math.abs(height)/2){
              radius = Math.floor(Math.abs(height)/2);
          }
          if(radius > Math.abs(width)/2){
              radius = Math.floor(Math.abs(width)/2);
          }

          if(height < 0){
              // Negative values in a standard bar chart
              let x_tl = x;           let x_tr = x+width;
              let y_tl = y+height;    let y_tr = y+height;

              let x_bl = x;           let x_br = x+width;
              let y_bl = y;           let y_br = y;

              // Draw
              ctx.moveTo(x_bl+radius, y_bl);
              ctx.lineTo(x_br-radius, y_br);
              ctx.quadraticCurveTo(x_br, y_br, x_br, y_br-radius);
              ctx.lineTo(x_tr, y_tr+radius);
              ctx.quadraticCurveTo(x_tr, y_tr, x_tr-radius, y_tr);
              ctx.lineTo(x_tl+radius, y_tl);
              ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl+radius);
              ctx.lineTo(x_bl, y_bl-radius);
              ctx.quadraticCurveTo(x_bl, y_bl, x_bl+radius, y_bl);

          }else if(width < 0){
              // Negative values in a horizontal bar chart
              let x_tl = x+width;     let x_tr = x;
              let y_tl= y;            let y_tr = y;

              let x_bl = x+width;     let x_br = x;
              let y_bl = y+height;    let y_br = y+height;

              // Draw
              ctx.moveTo(x_bl+radius, y_bl);
              ctx.lineTo(x_br-radius, y_br);
              ctx.quadraticCurveTo(x_br, y_br, x_br, y_br-radius);
              ctx.lineTo(x_tr, y_tr+radius);
              ctx.quadraticCurveTo(x_tr, y_tr, x_tr-radius, y_tr);
              ctx.lineTo(x_tl+radius, y_tl);
              ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl+radius);
              ctx.lineTo(x_bl, y_bl-radius);
              ctx.quadraticCurveTo(x_bl, y_bl, x_bl+radius, y_bl);

          }else{
              //Positive Value
              ctx.moveTo(x, y);
              ctx.lineTo(x + width - radius, y);
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
              ctx.lineTo(x + width, y + height);
              ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
              ctx.lineTo(x, y + height);
              ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
              ctx.lineTo(x, y + radius);
              ctx.quadraticCurveTo(x, y, x + radius, y);
          }
      }

      ctx.fill();
      if (borderWidth) {
          ctx.stroke();
      }
    };
  }

  getProjectList(){
    this.projectService.getSimpleList().pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.projectList = data.response;
            this.selectProject();
          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
        });
  }

  selectProject(){
    this.isLoading = true;
    if(this.regionSalesChart) this.regionSalesChart.nativeElement.style.display = 'none';
    if(this.salesChart) this.salesChart.nativeElement.style.display = 'none';
    if(this.channelSalesChart) this.channelSalesChart.nativeElement.style.display = 'none';
    if(this.chainSalesChart) this.chainSalesChart.nativeElement.style.display = 'none';
    // const project = this.projectList.filter(list => list.id === this.projectId);
    // console.log(project);
    // console.log(this.projectId.value,sDate,eDate);
    // this.getDashboardData(this.projectId,sDate,eDate,'salesSummary');
    // this.getDashboardData(this.projectId,sDate,eDate,'sessions');
    this.getDashboardData(this.projectId, 'salesWithOOSSortedWeekly');
  }

  getDashboardData(id, type){

    let data: any = {};
    data.projectId = id;
    data.type = type;
    // console.log(data);
    this.dashboardService.getDashboardData(data).pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            this[type] = data.response;
            this.isLoading = false;
            this[this.type].nativeElement.style.display = 'block';
            if(type == 'salesWithOOSSortedWeekly'){
              if(this.type == 'salesChart') {
                this.initSalesChartData(this[type].dataByDate,'salesChart');
              }
              else if(this.type == 'chainSalesChart') {
                this.initSalesChartData(this[type].dataByRetailer,'chainSalesChart');
              }
              else if(this.type == 'regionSalesChart'){
                this.initSalesChartData(this[type].dataByRegion,'regionSalesChart');
              }
              else if(this.type == 'channelSalesChart') {
                this.initSalesChartData(this[type].dataByChannel,'channelSalesChart');
              }
            }
          }else{
            this.isLoading = false;
            console.log(data.errorMessage);
            // this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.alertService.error(error.statusText);
    });

  }

  public chainSalesChartC: Chart;
  public regionSalesChartC: Chart;
  public channelSalesChartC: Chart;
  public SalesChartC: Chart;
  
  initSalesChartData(data,chartName){
    if(chartName != 'salesChart'){
      const sales = data.map(data => data.sales);
      const key = data.map(data => data.key);
      const target = data.map(data => data.target);
      const oosPercentage = data.map(data => data.oosPercentage)
      // console.log(sales,key,target);
      this.createSalesCharts(key,sales,target,oosPercentage,chartName);
    }else{
      const sales = data.map(data => data.sales);
      const key = data.map(data => data.week);
      const target = data.map(data => data.target);
      const sessions = data.map(data => data.sessions);
      const oosPercentage = data.map(data => data.oosPercentage)
      // console.log(sales,key,target,sessions);
      this.createSalesCharts(key,sales,target,oosPercentage,chartName);
    }
  }

  createSalesCharts(key,sales,target,oosCount,chartName) {

     const ctx = this[chartName].nativeElement.getContext('2d');
    //  var gradientStroke = ctx.createLinearGradient(0, 0, 0, 250);
    //  gradientStroke.addColorStop(0, "#00EB22");
    //  gradientStroke.addColorStop(1, "#02900C");

     let chart = chartName + 'C';
     if (this[chart]) this[chart].destroy();
     this[chart] = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [
            {
              label: 'Total OOS',
              yAxisID: 'Total OOS',
              data: oosCount,
              type: 'line',
              borderColor: '#ec4c4c',
              borderWidth: 3,
              fill: false,
            },
            {
              label: 'Total Sales',
              yAxisID: 'Total Sales',
              data: sales,
              backgroundColor: '#6ab565',
              borderWidth: 0,
            }, {
              label: 'Total Sales Target',
              yAxisID: 'Total Sales',
              data: target,
              backgroundColor: '#e3e3e4',
              borderWidth: 0,
            },
          ],
          labels: key
        },
        options: {
          cornerRadius: 20,
          scales: {
            yAxes: [{
              id: 'Total Sales',
              type: 'linear',
              position: 'left',
              ticks: {
                min: 0,
              }
            }, {
              id: 'Total OOS',
              type: 'linear',
              position: 'right',
              ticks: {
                min: 0,
                max: 100,
                callback: function(label, index, labels) {
                  return label + '%';
                }
              },
              gridLines: {
                display:false
              }
            }],
            xAxes: [{
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            }]
          },
          responsive: true,
          title: {
            display: false
          },
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              padding: 30,
              boxWidth: 20,
              usePointStyle: true,
              generateLabels: function(chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.datasets.map(function(label, i) {
                    var meta = chart.getDatasetMeta(i);
                    var ds = data.datasets[i];
                    let isEmpty = true
                    ds.data.forEach(el => {
                      if (el && el > 0) isEmpty = false
                    })
                    if(label.type === 'line'){
                      return {
                        hidden: isEmpty || meta.hidden,
                        text: label.label,
                        strokeStyle: label.borderColor,
                        lineWidth: 5,
                        pointStyle: 'line',
                        datasetIndex: i,
                      };
                    } else {
                      return {
                        hidden: isEmpty || meta.hidden,
                        text: label.label,
                        datasetIndex: i,
                        strokeStyle: label.backgroundColor,
                        lineWidth: 15,
                        pointStyle: 'line',
                      };
                    }
                  });
                } else {
                  return [];
                }
              },
            }
          },
          tooltips: {
            xPadding: 15,
            yPadding: 15,
            callbacks: {
              label: function(tooltipItem, data) {
                return tooltipItem['value'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            }
          },
        },
      });
  }

}
