import { Component, OnInit } from '@angular/core';
import { Globals } from '@app/globals'

@Component({
    selector: 'sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['../../assets/css/sidebar.css'],
    providers: [ Globals ]
})

export class SidebarComponent implements OnInit{

    public isClient: boolean;
    public isAdmin: boolean;
    public isPromoter: boolean;
    public isSupervisor: boolean;
    public isPM: boolean;
    public isCoordinator: boolean;

    constructor(private globals: Globals) {
      if (localStorage.getItem("currentUser")) {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        if (user) {
          this.defineAccessRights(user.response.accessLevel);
        }
      }
    }

    ngOnInit() { }

    defineAccessRights(role) {
      localStorage.setItem("accessLevel", role);
      if (role === "ROLE_CLIENT") {
        this.isClient = true;
      } else if (role === "ROLE_SUPER_ADMIN") {
        this.isAdmin = true;
      } else if (role === "ROLE_PROMOTER") {
        this.isPromoter = true;
      } else if (role === "ROLE_SUPERVISOR") {
        this.isSupervisor = true;
      } else if (role === "ROLE_PROJECT_MANAGER") {
        this.isPM = true;
      } else if (role === "ROLE_COORDINATOR") {
        this.isCoordinator = true;
      }
    }
}
