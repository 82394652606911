import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService, ProjectService, DashboardService, ReportService } from '@app/_services';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@app/globals';
import { Project } from '@app/_models';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: "client-dashboard-component",
  templateUrl: "./client-template.component.html",
  styleUrls: ["./index.component.scss"]
})
export class ClientDashboardComponent implements OnInit {
  isClient: boolean;
  isAdmin: boolean;
  isPromoter: boolean;
  isSupervisor: boolean;
  isPM: boolean;

  router: string;
  projectList: Project[] = [];
  projectId;
  clientId;
  projectPeriod = '-';
  salesSummary : any = {};
  sessions : any = {};
  minDate = new Date(Date.now());
  report: any;

  constructor(
    private reportService: ReportService,
    private alertService: AlertService,
    private projectService: ProjectService,
    private dashboardService: DashboardService,
    private globals: Globals,
    private _router: Router
  ) {
    this.router = _router.url;
    const user = JSON.parse(localStorage.getItem("currentUser"));
    this.clientId = user.response.clientId;
  }

  ngOnInit() {
    this.getList();
  }

  getList(){
    // console.log(this.clientId);
    this.projectService.getSimpleList(this.clientId).pipe(first()).subscribe(
        data => {
          if(data.status != 'error'){
            this.projectList = data.response;
            console.log(this.projectList);
            // TESTING
            this.projectId = this.projectList.length > 0 ? this.projectList[0].id : null;
            this.selectProject();
          }else{
            console.log(data.errorMessage);
          }
        },
        error => {
            console.log(error);
        });
  }

  getFormattedNumber(number){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getReport(){
    this.reportService.getPostEvalReport(this.projectId).pipe(first()).subscribe(
        data => {
          // console.log(data);
          if(data.status != 'error'){
            this.report = data.response;
          }else{
            this.report = null;
            console.log(data.errorMessage);
            // this.alertService.warn(data.errorMessage);
          }
        },
        error => {
            this.report = null;
            console.log(error);
            // this.alertService.error(error.statusText);
        });
  }


    getMinDate(event){
      //No future date can be selected
      this.minDate = event.value;
    }

    selectProject(){
      if(this.projectId){
          const project = this.projectList.filter(list => list.id === this.projectId);
          // console.log(project);
          // console.log(this.projectId);

          let sDate = moment(project[0].startDate,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
          let eDate = moment(project[0].endDate,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');

          this.projectPeriod = sDate + ' - ' + eDate;
          this.dashboardService.fetchId(this.projectId);
          this.getReport();

          // let sDate1 = moment(project[0].startDate,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
          // let eDate1 = moment(project[0].endDate,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
          this.getDashboardData(this.projectId,'salesSummary');
          this.getDashboardData(this.projectId, 'sessions');
      }

    }

    getDashboardData(id, type){

      let data: any = {};
      data.projectId = id;
      data.type = type;
      // console.log(data);

      this.dashboardService.getDashboardData(data).pipe(first()).subscribe(
          data => {
            if(data.status != 'error'){
              this[type] = data.response;
              if(type === 'salesSummary'){
                let dataSetSalesSummarySales = data.response.sales.achievement;
                let dataSetSalesSummarySampling = data.response.sampling.samplingAchievement;
                let dataSetSalesSummaryROI = data.response.roi.roiAchieved;
                this.getChart(dataSetSalesSummarySales, '#fff3db', '#FFC757', 'Sales');
                this.getChart(dataSetSalesSummarySampling, '#ffcfcf', '#ec4c4c', 'Sampling');
                this.getChart(dataSetSalesSummaryROI, '#fff3db', '#FFC757', 'ROI');
              } else if(type === 'sessions'){
                let dataSetSalesSummarySales = data.response.sessions.sessionsToGo;
                this.getChart(dataSetSalesSummarySales, '#d4fce6', '#0f8945', 'Sessions');
              }
            }else{
              console.log(data.errorMessage);
              // this.alertService.error(data.errorMessage);
            }
          },
          error => {
              console.log(error);
              this.alertService.error(error.statusText);
      });

    }

    getChart(percentage, colorStart, color, chartName){
      const canvas = <HTMLCanvasElement> document.getElementById(`chartJS${chartName}`);
      const ctx1 = canvas.getContext('2d');
  
      var gradientColors = [
        {
          start: colorStart,
          end: color
        },
        {
          start: '#fff',
          end: '#fff'
        }
        ];
        
        var gradients = [];
        
        gradientColors.forEach( function( item ){
            var gradient = ctx1.createLinearGradient(0, 0, 200 , 0);
            gradient.addColorStop(0, item.start);
            gradient.addColorStop(1, item.end);
            gradients.push(gradient);
        });
  
      var options1 = {
        type: 'doughnut',
        data: {
          datasets: [
           {
            data: [percentage, percentage < 100 ? 100 - percentage : 0],
            backgroundColor: gradients,
            borderColor: [
              '#fff',
            ],
            borderWidth: 5
            }
          ]
        },
        options: {
         rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          cutoutPercentage: 85
        }
      }
      // var ctx1 = document.getElementById('chartJSContainer').getContext('2d');
      new Chart(ctx1, options1);
  
      const canvasContainer = <HTMLCanvasElement> document.getElementById(`chartJS${chartName}Container`);
      const ctx2 = canvasContainer.getContext('2d');
      
      var options2 = {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [percentage, percentage < 100 ? 100 - percentage : 0],
              backgroundColor: [
                "rgba(0,0,0,0)"
              ],
              borderColor: [
                "rgba(0,0,0,0)",
              ],
              borderWidth: 5
            }
          ]
        },
        options: {
          cutoutPercentage: 85,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      }
      
      // var ctx2 = document.getElementById('secondContainer').getContext('2d');
      new Chart(ctx2, options2);
    }

}
