import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { AlertService, ClientService } from '@app/_services';
import { Client } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from "xlsx";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  clientList: Client[] = [];
  columnsToDisplay: string[] = ['clientId','clientName','picEmail','status'];
  dataSource: MatTableDataSource<Client>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("CLIENTTABLE") table: ElementRef;

  constructor(
    private alertService: AlertService,
    private clientService: ClientService,
    private globals: Globals,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.clientService.subsVar==undefined) {
      this.clientService.subsVar = this.clientService.
      invokeRefreshClientList.subscribe((name:string) => {
        this.getClientList();
      });
    }
    this.getClientList();
  }

  getClientList(){
    this.clientService.getAll(this.globals.locale)
        .pipe(first())
        .subscribe(
            data => {
              // console.log(data);
              if(data.status != 'error'){
                this.clientList = data.response;
                this.dataSource = new MatTableDataSource(this.clientList);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                console.log(this.clientList);
              }else{
                this.alertService.error(data.errorMessage);
              }
            },
            error => {
                console.log(error);
                this.alertService.error(error.statusText);
            });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editClient(client){
    // console.log(client);
    const dialogRef = this.dialog.open(ClientDialogContent, {
      disableClose: true,
      data: client,
      width: '500px'
    });

  }

  viewClient(){
    const dialogRef = this.dialog.open(ClientDialogContent, {
      disableClose: true,
      width: '300px'
    });
  }

  exportAsExcel() {
    let clientExportData:any[] = this.clientList;
    clientExportData = clientExportData.filter(function (props) {
        delete props.accessLevel;
        return true;
    });
    // console.log(clientExportData);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      clientExportData
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "clients.xlsx");
  }

}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'client-dialog',
  templateUrl: './client-dialog.html',
  styleUrls: ['./client.component.scss']
})
export class ClientDialogContent implements OnInit {

  submitted = false;
  statusChecked = false;
  clientData: Client = new Client();
  clientName = new FormControl('', [Validators.required]);
  picEmail = new FormControl('', [Validators.required, Validators.email]);

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private alertService: AlertService,
      private clientService: ClientService,
      private globals: Globals,
      public dialogRef:MatDialogRef <ClientComponent>,
    ){ }

  ngOnInit() {
      this.clientData.locale = this.globals.locale;

      if(this.data){
        console.log(this.data);
        this.submitted = true;
        this.clientName = new FormControl(this.data.name, [Validators.required]);
        this.picEmail = new FormControl(this.data.email, [Validators.required, Validators.email]);
        this.statusChecked = this.data.status == 'ACTIVE' ? true : false;
      }
  }

  getErrorMessage() {
    return this.picEmail.hasError('required') ? 'You must enter a value' :
        this.picEmail.hasError('email') ? 'Not a valid email' :
            '';
  }

  addNewClient(){

    this.submitted = true;
    if (this.clientName.invalid || this.picEmail.invalid) {
        return;
    }
    this.clientData.clientName = this.clientName.value;
    this.clientData.picEmail = this.picEmail.value;

    // console.log(this.clientData);

    this.clientService.add(this.clientData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if(data.status != 'error'){

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshClientList();

        }else{
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );

  }

  editClient(){
    if (this.clientName.invalid || this.picEmail.invalid) {
        return;
    }
    this.clientData.clientId = this.data.clientId;
    this.clientData.clientName = this.clientName.value;
    this.clientData.picEmail = this.picEmail.value;
    this.clientData.status = this.statusChecked ? 'ACTIVE' : 'INACTIVE';

    console.log(this.clientData);

    this.clientService.update(this.clientData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if(data.status != 'error'){
          this.alertService.success('Saved');
          this.refreshClientList();
        }else{
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.alertService.error(error.statusText);
      }
    );

  }

  refreshClientList(){
    this.clientService.refreshClientList();
  }

}
