import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { environment } from '@environments/environment';
import { Training } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class TrainingService {

    invokeRefreshList = new EventEmitter();
    subsVar: Subscription;

    constructor(private http: HttpClient,public _globals: Globals) { }

    getAll() {
        return this.http.post<any>('/fd/api/getallprojecttrainings',{ "locale": this._globals.locale });
    }

    upload(training: Training) {
        training.locale = this._globals.locale;
        return this.http.post<any>('/fd/api/addprojecttraining', training);
    }

    delete(training: Training) {
        training.locale = this._globals.locale;
        return this.http.post<any>('/fd/api/deleteprojecttraining', training);
    }

    refreshList() {
      this.invokeRefreshList.emit();
    }
}
