import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@app/globals';
import { AlertService } from '@app/_services/alert.service';
import { ChannelService } from '@app/_services/channel.service';

import { first } from 'rxjs/operators';
@Component({
    selector: 'channel-form',
    templateUrl: 'channel-form.component.html'
})

export class ChannelFormComponent implements OnInit {

    submitted = false;
    statusChecked = false;
    name = new FormControl('', [Validators.required]);
    code = new FormControl('', [Validators.required]);

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<ChannelFormComponent>,
      private globals: Globals,
      private alertService: AlertService,
      private channelService: ChannelService
    ) { }

    ngOnInit() {
      console.log(this.data);
    }

    saveEvent() {
      console.log('here');
      this.submitted = true;
      if (this.name.invalid || this.code.invalid) {
          return;
      }

      let data: any = {};
      data.locale = this.globals.locale;
      data.name = this.name.value;
      data.code = this.code.value.toUpperCase();


      console.log(data);

      this.channelService.add(data).pipe(first()).subscribe(
        data => {
          console.log(data);
          if(data.status != 'error'){

            this.dialogRef.close(true);
            this.alertService.success('Saved');

          }else{
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
            console.log(error);
            this.dialogRef.close(true);
            this.alertService.error(error.statusText);
        }
      );

    }
}
