import { Injectable, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@app/globals";
import { Project } from "@app/_models";
import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class ProjectService {
  invokeRefreshTaskList = new EventEmitter();
  invokeRefreshCheckoutTaskList = new EventEmitter();
  invokeRefreshSupervisorTaskList = new EventEmitter();
  invokeRefreshProjectReviewList = new EventEmitter();
  invokeRefreshPromoterReviewList = new EventEmitter();
  subsVar: Subscription;
  constructor(private http: HttpClient, public _globals: Globals) {}

  getAll() {
    return this.http.post<any>("/fd/api/getprojectslist", {
      locale: this._globals.locale
    });
  }

  getSimpleList(id?){
    let data = {};
    if(id) data = { 'clientId' : id};
    return this.http.post<any>("/fd/api/getsimplifiedprojectslist", data);
  }

  getById(id) {
    return this.http.post<any>("/fd/api/getprojectdetails", {
      locale: this._globals.locale,
      projectId: id
    });
  }

  add(project: Project) {
    return this.http.post<any>("/fd/api/createproject", project);
  }

  update(project: Project) {
    return this.http.post<any>("/fd/api/updateproject", project);
  }

  upload(project: any) {
    project.requestBy = this._globals.currentUser.response.userId
    return this.http.post<any>("/fd/api/excelupload", project);
  }

  //temporary use, avoid using from user service, others developer is doing on it
  getUser() {
    return this.http.post<any>("/fd/api/getuserslist", {
      locale: this._globals.locale
    });
  }

  getAllTasks(projectId, type) {
    return this.http.post<any>("/fd/api/gettaskslist", {
      locale: this._globals.locale,
      projectId: projectId,
      type: type
    });
  }

  addTask(data, projectId, type) {
    return this.http.post<any>("/fd/api/addtask", {
      locale: this._globals.locale,
      projectId: projectId,
      taskType: type,
      tasks: [data]
    });
  }

  updateTask(data, taskId) {
    return this.http.post<any>("/fd/api/updatetask", {
      locale: this._globals.locale,
      taskId: taskId,
      taskName: data.name,
      subTasks: data.subTasks
    });
  }

  getExcel(project: Project){
    return this.http.post<any>('/fd/api/getexcel', project);
  }

  getUploadedData(project: Project){
    return this.http.post<any>('/fd/api/getuploadeddata', project);
  }

  deleteTask(data) {
    return this.http.post<any>("/fd/api/deletetask", {
      locale: this._globals.locale,
      taskId: data.id
    });
  }

  refreshTaskList(projectId, type) {
    if (type === "CHECKOUT") {
      this.invokeRefreshCheckoutTaskList.emit();
    } else if (type === "SUPERVISOR") {
      this.invokeRefreshSupervisorTaskList.emit();
    } else if (type === "PROJECT_REVIEW") {
      this.invokeRefreshProjectReviewList.emit();
    } else if (type === "PROMOTER_REVIEW") {
      this.invokeRefreshPromoterReviewList.emit();
    } else {
      this.invokeRefreshTaskList.emit();
    }
  }

  uploadReport(project: Project){
    return this.http.post<any>("/fd/api/updatepostevalreport", project);
  }
  getReport(project: Project){
    return this.http.post<any>("/fd/api/getpostevalreport", project);
  }

}
