import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '@app/globals';
import { AlertService } from '@app/_services/alert.service';
import { SchedulerService } from '@app/_services/scheduler.service';
import { Promoter, Schedule } from '@app/_models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Subscriber } from 'rxjs';
import { OverviewSupervisorDialogComponent } from '@app/project-overview/supervisor/dialog.component';


@Component({
  selector: 'scheduler-details',
  templateUrl: 'scheduler-details.html',
  styleUrls: ['scheduler.scss']
})

export class SchedulerDetailsComponent implements OnInit, OnDestroy {

  isPendingApprovalView: boolean = false
  viewOnly: boolean = false
  canEditWithApproval: boolean = false
  canEditWithoutApproval: boolean = false
  isAssignedPM: boolean = false
  selectedSupervisorId: number

  projectId;
  project;
  projectList;
  visitDetails = [];
  Object = Object;
  requestedBy;

  status;
  outletId;
  userId;
  userType = "ROLE_PROMOTER";
  statusList :any[] = new Array();
  allowanceSalaryList: any[] = [];
  approvalAllList

  hideExpiredScheduler: boolean;
  filterOutletId = -1; //show all by default

  @Input() type : 'overview-supervisor' | 'scheduler-details'

  allSubscriptions: Subscriber<any>[] = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.assignAccessibility()
    if (this.type === 'scheduler-details') this.schedulerDetailsInit() // Old Scheduler
    if (this.type === 'overview-supervisor') this.overviewSupervisorInit() // New Scheduler (SV)
  }

  assignAccessibility() {
    const accessLevel = this.globals.currentUser.response.accessLevel
    this.canEditWithoutApproval = accessLevel === "ROLE_SUPER_ADMIN"
    this.canEditWithApproval =  accessLevel === "ROLE_COORDINATOR"
    if (this.type === 'scheduler-details'
        && this.canEditWithApproval
          && this.userType === 'ROLE_SUPERVISOR') this.viewOnly = true
  }

  schedulerDetailsInit() {
    this.allSubscriptions.push(
      this.schedulerService.change.subscribe(data => {
        this.projectId = data.id;
        this.project = data.project;
        this.getVisit(this.projectId);
      }))
    this.allSubscriptions.push(
      this.schedulerService.changeTab.subscribe(tab => {
        if (tab === 1) this.getVisit(this.projectId);
      }))
    this.allSubscriptions.push(
      this.schedulerService.changeByOutlet.subscribe(data => {
        this.schedulerService.getVisitByOutlet(data.projectId, data.outletId)
          .pipe(first())
          .subscribe(
            data => {
              console.log('Got from API', data);
              if (data.response) {
                let index = this.visitDetails.findIndex(el => el.outletId === data.response.visitations[0].outletId);
                let outlet = {...data.response.visitations[0]};
                outlet['canShow'] = false;
                this.visitDetails[index] = outlet;
              }
            }
          );
      }))
    this.allSubscriptions.push(
      this.schedulerService.changeByVisitationId.subscribe(data => {
        this.schedulerService.getVisitationById(data.projectId, data.outletId, data.visitationDate, data.visitationTime)
          .pipe(first())
          .subscribe(
            res => {
              console.log('Got from API', res);
              if (res.response) {
                let outletIndex = this.visitDetails.findIndex(el => el.outletId === res.response.visitations[0].outletId);
                let cellIndex = this.visitDetails[outletIndex].data.findIndex(el => Object.keys(el)[0] === data.visitationDate);
                this.visitDetails[outletIndex].data[cellIndex] = res.response.visitations[0].data[0];
              }
            }
          );
      }))
    this.allSubscriptions.push(
      this.schedulerService.filter.subscribe(data => {
        this.userType = data.userType != -1 ? data.userType : null;
        this.outletId = data.outletId;
        this.status = data.status != -1 ? data.status : null;
        this.userId = data.userId != -1 ? data.userId : null;
        this.isAssignedPM = data.isAssignedPM
        this.requestedBy = data.requestedBy
        if (data.tab === 1) this.getVisit(this.projectId);
        if (this.type === 'scheduler-details'
          && this.canEditWithApproval
            && this.userType === 'ROLE_SUPERVISOR') this.viewOnly = true
        else this.viewOnly = false
      }))
  }

  overviewSupervisorInit() {
    //Pending Approval only visible to ROLE_SUPER_ADMIN
    this.isPendingApprovalView = this.router.url === '/overview/pending-approval'

    this.allSubscriptions.push(
      this.schedulerService.hideExpiredScheduler.subscribe(hide => {
        this.hideExpiredScheduler = hide
      }))
    this.allSubscriptions.push(
      this.schedulerService.dataPassing.subscribe(visitations => {
        this.visitDetails = visitations.data
        this.allowanceSalaryList = visitations.allowanceSalaryList
        this.requestedBy = visitations.requestedBy
        this.selectedSupervisorId = visitations.supervisorId
        this.approvalAllList = visitations.approvalAllList
        this.projectList = visitations.projectList
      }))
    this.allSubscriptions.push(
      this.schedulerService.filter.subscribe(filter => {
        this.status = filter.status;
        this.filterOutletId = filter.filterOutletId;
      }))
  }


  getVisit(id) {
    this.schedulerService.getVisit(id)
      .pipe(first())
      .subscribe(
        data => {
          console.log('Got from API', data);
          if (data.status != 'error') {
            this.visitDetails = data.response.visitations.sort(this.sortOutletName)
            this.visitDetails = this.visitDetails.map((outlet) => this.limitShowingEachOutlet(outlet))
            this.parseData(this.visitDetails);
            if (this.hideExpiredScheduler) this.removeExpiredScheduler()
          } else {
            console.error('Error from API', data);
          }
        },
        error => {
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
      });
  }

  addVisitation(data){
    const result = confirm("Are you sure you want to add visitation ?");

    if (result) {
      let visit: Schedule = new Schedule();
      visit.visitationId = data.visitationId;

      this.schedulerService.addVisit(visit)
        .pipe(first())
        .subscribe(
          data => {
            console.log('Got from API', data);
            if (data.status != 'error') {
              this.alertService.success('Saved');
              this.getVisit(this.projectId);
            } else {
              console.error('Error from API', data);
              this.alertService.error(data.errorMessage);
            }
          },
          error => {
            console.error('Error from API', error);
            this.alertService.error(error.statusText);
          }
        );
    }
  }

  reInit(){
    this.status = null;
    this.outletId = null;
    this.userId = null;
  }

  parseData(data){
    const visit = data.map(data => data.data);
    let temp = [];
    visit.forEach(function(vv){
      temp.push( vv.map(data => data[Object.keys(data)[0]] ));
    });
    this.statusList = temp;
  }

  openApproveAll(date, isApproving: boolean) {
    const dialogRef = this.dialog.open(ScheduleDialogContent, {
      data: {
        date: date,
        isApproving: isApproving,
      },
      minWidth: '500px',
    });
  }

  openAddVisitationDialog(date, projectId, outletId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      payload : {
        requestedBy : this.requestedBy,
        supervisorId: this.selectedSupervisorId,
        projects: this.projectList,
        date,
        projectId,
        outletId
      },
      type : 'addVisitation',
    }
    this.dialog.open(OverviewSupervisorDialogComponent, dialogConfig)
  }

  scheduleEvent(data, outletName, outletId, projectName?){
    if (this.canOpenDialog(data)) {
      const payload = this.getPayloadForScheduleDialog(data, outletName, outletId, projectName)
      const dialogRef = this.dialog.open(ScheduleDialogContent, {
        data: payload,
        minWidth: '500px',
      });
    }
  }

  getPayloadForScheduleDialog(data, outletName, outletId, projectName = null) {
    let payload = {
      ...data,
      viewOnly: this.viewOnly,
      type: this.type,
      outletName: outletName,
      outletId: outletId,
    }
    if (this.type === 'scheduler-details') {
      payload = {
        ...payload,
        projectId: this.projectId,
        visitations: this.visitDetails,
        filter: {
          userType: this.userType,
          outletId: this.outletId != -1 ? this.outletId : null,
          status: this.status != -1 ? this.status : null,
          userId: this.userId != -1 ? this.userId : null,
        },
        accessLevel: this.userType,
        project: this.project,
        isAssignedPM: this.isAssignedPM,
        requestedBy: this.requestedBy,
      }
    }
    if (this.type === 'overview-supervisor') {
      payload = {
        ...payload,
        projectName: projectName,
        requestedBy: this.requestedBy,
        isApproveRequest: this.canEditWithoutApproval,
        isPendingApprovalView: this.isPendingApprovalView,
      }
      payload.project = {}
      payload.project.startDate = this.projectList.find(el => el.projectId == payload.projectId).projectStartDate
      payload.project.endDate = this.projectList.find(el => el.projectId == payload.projectId).projectEndDate
    }
    return payload
  }

  updateAllowance(day) {
    if (!this.canEditWithApproval && !this.canEditWithoutApproval) return
    if (this.canModifyAllowance(day)) {
      let dialogToOpen
      let isPending = day.status === 'PENDING'
      const payload = {
        type: 'modifyAllowance',
        date: day,
        isApproveRequest: true,
        requestedBy: this.requestedBy,
        supervisorId: this.selectedSupervisorId,
        canEditWithApproval: this.canEditWithApproval,
        canEditWithoutApproval: this.canEditWithoutApproval,
        isPendingApprovalView: this.isPendingApprovalView,

      }
      let isApprovalRequest = this.isPendingApprovalView && isPending && this.canEditWithoutApproval
      if (isApprovalRequest) dialogToOpen = ScheduleDialogContent
      else dialogToOpen = OverviewSupervisorDialogComponent // Modifications

      console.log(payload, 'sending to', dialogToOpen)
      const dialogRef = this.dialog.open(dialogToOpen, {
        data: payload,
        minWidth: '500px',
      });
    } else {
      return
    }
  }

  canModifyAllowance(day) {
    if (day.canAddAllowance) {
      if (day.isAllowanceCurrentMonthOnward) {
        if (this.canEditWithoutApproval) return true
      }
      if (day.isAllowanceTodayOnward) return true
    }
    return false
  }

  // For displaying
  getVisitationStatusClass(pp) {
    const output = []
    if (pp.visitationStatus === 'COMPLETED') output.push('bg-green')
    if (pp.visitationStatus === 'IN_PROGRESS') output.push('bg-light-blue')

    if (pp.visitationStatus === 'PENDING'
    || pp.visitationStatus === 'APPROVAL_PENDING') output.push('bg-blue')

    if (pp.visitationStatus === 'CANCELLED'
    || pp.visitationStatus === 'APPROVAL_CANCELLED') output.push('bg-red')

    if (pp.visitationStatus === 'NON_WORKING_DAY') output.push('bg-grey', 'hide')

    if (pp.visitationStatus === 'NOT_ASSIGNED'
    || pp.visitationStatus === 'REPLACEMENT'
    || pp.visitationStatus === 'APPROVAL_REPLACEMENT') output.push('bg-yellow')

    return output
  }

  getTooltip(pp) {
    let output = ""
    if (pp.leadPromoter) output += "*"
    output += this.schedulerService.parseStatus(pp.promoterName)
    return output
  }

  isHidden(data, type: 'sl' | 'pp' | 'outlet' | 'outletVisit') {
    // Scheduler Details
    if (type === 'sl') {
      if (!data.canShow) return true
    }

    if (type === 'pp') {
      if (this.userId ?   data.userId != this.userId            : false) return true
      if (this.status === 'NOT_ASSIGNED'
        && data.visitationStatus === 'APPROVAL_REPLACEMENT') return false
      if (this.status ?   data.visitationStatus != this.status  : false) return true
      if (this.userType ? data.userType != this.userType        : false) return true
    }
    //Overview Supervisor
    if (type === 'outlet') {
      // if the outlet have no more visit left after status filter, hide it
      if (this.isOutletHaveNoVisitLeft(data)) return true

      if (this.filterOutletId === -1) return false //Show all
      if (this.filterOutletId ? data.outletId !== this.filterOutletId : false) return true
    }
    if (type === 'outletVisit') {
      if (this.status === -1) return false //Show all
      if (this.status ? data.visitationStatus != this.status  : false) return true
    }
    return false
  }

  isOutletHaveNoVisitLeft(data) { // used by overview-supervisor
    let haveNoVisitLeft = true
    if (this.status && this.status !== -1) { // only check if status filter is set
      data.data.forEach(day => {
        let dayData = day[Object.keys(day)[0]]
        if (dayData.length > 0) {
          dayData.forEach(visit => {
            // there is at least one visitation matched the status filter? allow display outlet row
            visit.visitationStatus == this.status ? haveNoVisitLeft = false : null
          })
        }
      })
    } else { // if status filter is not set, display outlet row
      return false
    }
    return haveNoVisitLeft
  }

  formatDateForTable(value) {
    let result = moment(value, 'DD-MMM-YYYY').format('DD-MMM-YY')
    return result == 'Invalid date' ? value : result
  }

  getContent(pp) {
    let output = ""
    if (this.type === 'scheduler-details' && this.userType === 'ROLE_PROMOTER') {
      if (pp.visitationStatus !== 'NON_WORKING_DAY') {
        if (pp.visitationStatus === 'NOT_ASSIGNED'
        || pp.visitationStatus === 'APPROVAL_REPLACEMENT') output = 'Not Assigned'
        else if (pp.visitationStatus == 'REPLACEMENT') output = 'Replacement'
        else output = pp.promoterName
      }
    } else {
      if (pp.visitationStatus === 'PENDING') output = 'Pending Check-in'
      if (pp.visitationStatus === 'NOT_ASSIGNED') output = 'Pending Assignment'
      if (pp.visitationStatus === 'COMPLETED') output = 'Check-in'
      if (pp.visitationStatus === 'CANCELLED') output = 'Cancelled'
      if (pp.visitationStatus === 'REPLACEMENT') output = 'Replacement'
      if (pp.visitationStatus === 'IN_PROGRESS') output = 'In Progress'

      if (pp.visitationStatus === 'APPROVAL_CANCELLED') output = 'Cancel Awaiting\nApproval'
      if (pp.visitationStatus === 'APPROVAL_PENDING') output = 'New Awaiting\nApproval'
      if (pp.visitationStatus === 'APPROVAL_REPLACEMENT') output = 'Replacement Awaiting\nApproval'

      if (pp.remarks) output += `\n(${pp.remarks})` // Append remarks by the end of the content
    }
    return output
  }

  getAllowanceContent(day) { //return innerHTML
    let output = `RM ${day.allowance ? day.allowance : 0}`
    if (day.status === 'PENDING') output += '<br>(Awaiting Approval)'
    if (day.before) output += `<br><small>(From: RM ${day.before.allowance})</small>`
    return output
  }

  canOpenDialog(pp) {
    if (this.type === 'overview-supervisor') {
      if (pp.visitationStatus === 'COMPLETED') return false // nothing to display in completed
    }
    if (pp.visitationStatus === 'NON_WORKING_DAY') return false
    return true
  }

  // MICS

  sortOutletName(a, b) {
    if (a.outletName < b.outletName) { return -1; }
    if (a.outletName > b.outletName) { return 1; }
    return 0;
  }

  limitShowingEachOutlet(outlet) { // used by scheduler-details (old)
    const haveFilter = this.status || this.userId || (this.outletId ? this.outletId !== -1 : false)
    if (haveFilter) {
      if (this.outletId !== -1 ? outlet.outletId != this.outletId : false) return outlet
      outlet.data.map(data => {
        if (data[Object.keys(data)[0]].length > 0) {
          let found = data[Object.keys(data)[0]].find( visit => {
            let haveCorrectStatus = this.status ? visit.visitationStatus === this.status : true
            let haveCorrectAssignee = this.userId ? this.userId === visit.userId : true
            let haveCorrectUserType = visit.userType === this.userType
            if (haveCorrectStatus && haveCorrectAssignee && haveCorrectUserType) return true
            return false
          })
          if (found) {
            outlet.canShow = true;
            return outlet;
          }
        }
      });
      return outlet;
    } else {
      outlet.canShow = true;
      return outlet;
    }
  }

  removeExpiredScheduler() {
    this.visitDetails[0].data = this.visitDetails[0].data.filter(visit => {
      const now = moment(new Date())
      const visitDate = moment(Object.keys(visit)[0], 'DD-MMM-YY')
      const monthDiff = visitDate.diff(now, 'months')
      if (monthDiff > 0) return true
      if (monthDiff === 0) return visitDate.format('MMM') === now.format('MMM') // Same month
      return false
    })
  }

  ngOnDestroy() {
    this.allSubscriptions.forEach(sub => sub.unsubscribe())
  }

}

// ******************** START : DIALOG COMPONENT ********************
import { DialogService } from '@app/_services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import debounce from 'lodash/debounce'
import { UserService, UtilityService } from '@app/_services';

@Component({
  selector: 'schedule-dialog',
  templateUrl: 'schedule-dialog.html',
  styleUrls: ['scheduler.scss']
})
export class ScheduleDialogContent implements OnInit {

  isPendingApprovalView: boolean = false
  canEditWithApproval: boolean = false
  canEditWithoutApproval: boolean = false
  viewOnly: boolean = false

  type : 'overview-supervisor' | 'scheduler-details' | 'modifyAllowance' = 'scheduler-details'
  submitted : boolean = false;
  isChangingDate : boolean = false;
  isChangingSalary : boolean = false;
  isChoosingAssignee: boolean = false;
  assigneeList: any[] = [];
  userId = new FormControl('', [Validators.required]);
  reason = new FormControl('', [Validators.required]);
  visitDate = new FormControl('', [Validators.required]);
  currentUser: any = {fullName: "", email: ""};

  canForceCheckOut = false;
  forceCheckOutDate = null;
  canForceCheckIn = false;

  salaryIncrement : number = 0;
  salaryIncrementUpdatedById : number = 0;

  minDate
  maxDate

  isLoading:boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
    private globals: Globals,
    private userService: UserService,
    private utilityService: UtilityService,
    private dialogService: DialogService,
    public dialogRef:MatDialogRef <SchedulerDetailsComponent>,
  ){
    dialogRef.disableClose = false;
  }

  ngOnInit() {
    console.log('Data to Dialog', this.data)
    this.currentUser = this.globals.currentUser.response;
    this.isPendingApprovalView = this.data.isPendingApprovalView
    this.canEditWithoutApproval = this.globals.currentUser.response.accessLevel === "ROLE_SUPER_ADMIN"
    this.canEditWithApproval = this.globals.currentUser.response.accessLevel === "ROLE_COORDINATOR"
    this.viewOnly = this.data.viewOnly
    if (this.data) {
      this.type = this.data.type
      this.submitted = true;
      if (this.data.project) {
        this.minDate = this.utilityService.getStartOrNowDate(this.data.project.startDate, "DD/MM/YYYY HH.mm", true)
        this.maxDate = moment(this.data.project.endDate, "DD/MM/YYYY HH.mm").toDate();
      }
      if (this.data.isApproving == null) { // approvalAll dont need assignList
        if (this.type === 'scheduler-details') {
          this.getAssignList(this.data.visitationDate,this.data.userType);
        } else {
          this.getAssignList(this.data.visitationDate, 'ROLE_SUPERVISOR');
        }
      }

      if (this.data.visitationStatus == 'PENDING' || this.data.visitationStatus == 'IN_PROGRESS') {
        const isVisitTodayOrBefore = moment(this.data.visitationDate, 'DD-MMM-YY').isSameOrBefore(moment(), 'day')
        // After user have checkout, then only check for canForceCheckIn
        if (isVisitTodayOrBefore) this.checkCanForceCheckOut()
      }
    }
  }
  checkCanForceCheckOut() {
    if (this.currentUser.userId != 42) return // PLEASE REMOVE ME
    // Condition: Only available for today or before's pending/in progress check-in visitation
    // AND if there is a past date of the ongoing visitation. (check with API)
    const postData = {
      userId: this.data.userId,
      past: true
    }
    console.log(postData)
    this.schedulerService.checkUserOngoingVisit(postData)
    .pipe(first())
    .subscribe(
    data => {
      console.log(data);
      if (data.status != 'error') {
        if (data.response.hasOngoingVisitation) {
          this.canForceCheckOut = true
          this.forceCheckOutDate = data.response.date
        } else {
          // After user have checked out, then only check for canForceCheckIn
          this.checkCanForceCheckIn()
        }
      }
    },
    error => {
      console.log(error);
      this.alertService.error(error.statusText);
    });
  }
  checkCanForceCheckIn() {
    if (this.currentUser.userId != 42) return // PLEASE REMOVE ME
    // Condition: Only available for today's pending check-in visitation
    // if the user does not have any in_progress visitation. (Check with API)
    const postData = {
      userId: this.data.userId,
      past: false
    }
    console.log(postData)
    this.schedulerService.checkUserOngoingVisit(postData)
    .pipe(first())
    .subscribe(
    data => {
      console.log(data);
      if (data.status != 'error') {
        if (data.response.hasOngoingVisitation == false) {
          this.canForceCheckIn = true
        }
      }
    },
    error => {
      console.log(error);
      this.alertService.error(error.statusText);
    });
  }

  // All actions
  getAssignList(date, userType){
    let apiToCall = this.schedulerService.getPromoter(date, userType)
    if (userType === 'ROLE_SUPERVISOR') {
      apiToCall = this.userService.getAllSupervisorsByRequestedBy({requestedBy: this.data.requestedBy})
      // if (this.data.outletId && this.data.projectId && this.data.visitationDate) {
      //   apiToCall = this.schedulerService.getUserListByType(
      //     this.utilityService.formatDateOldWay(this.data.visitationDate),
      //     userType,
      //     this.data.outletId,
      //     this.data.projectId
      //   )
    }
    console.log(this.data.requestedBy, apiToCall)
    apiToCall.pipe(first())
      .subscribe(
        data => {
          console.log('Got from API', data);
          if (data.status != 'error') {
            this.assigneeList = data.response;
          } else {
            console.error('Error from API', data);
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
        });
  }

  cancelVisit = debounce(function(createReplacement: boolean){
    if (this.type === 'scheduler-details' && this.data.userType === 'ROLE_PROMOTER') {
      this.submitted = true;
      if (this.reason.invalid) return

      let data: Schedule = new Schedule();
      data.visitationId = this.data.visitationId;
      data.userId = this.data.userId;
      data.reason = this.reason.value;
      data.createReplacement = createReplacement;
      data.outletId = this.data.outletId;
      data.projectId = this.data.projectId;

      let projectId = data.projectId;
      let outletId = data.outletId;
      let visitationDate = this.data.visitationDate;
      let visitationTime = this.data.visitationTime;
      data["cancelledByName"] = this.currentUser.fullName;
      data["cancelledByEmail"] = this.currentUser.email;

      console.log('Sending to API', data)
      this.schedulerService.cancelVisit(data)
        .pipe(first())
        .subscribe(
          data => {
          console.log('Got from API', data);
            if (data.status != 'error') {
              this.alertService.success('Saved');
            } else {
              console.error('Error from API', data);
              this.alertService.error(data.errorMessage);
            }
            this.dialogRef.close(true);
          },
          error => {
            this.dialogRef.close(true);
            console.error('Error from API', error);
            this.alertService.error(error.statusText);
          },
          () => {this.handleFinally()}
        );
    } else {
      if (this.reason.invalid) return
      if (createReplacement ? this.reason.invalid : false) return
      const payload = {
        requestedBy: this.data.requestedBy,
        visitationId: +this.data.visitationId,
        createReplacement: createReplacement,
        userId: this.userId.value,
        reason: this.reason.value,
      }
      console.log('Sending to API', payload)
      this.schedulerService.cancelSupervisorVisitation(payload)
        .pipe(first())
        .subscribe( data => {
          console.log('Got from API', data);
          if (data.status != 'error') {
            this.alertService.success('Saved');
          } else {
            console.error('Error from API', data);
            this.alertService.error(data.errorMessage);
          }
          this.dialogRef.close(true);
        },
        error => {
          this.dialogRef.close(true);
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
        },
        //finally
        () => {this.handleFinally()});
    }
  }, 500)

  deleteVisit() {
    this.submitted = true;

    let visitationId = this.data.visitationId;
    let projectId = this.data.projectId;
    let outlet = this.data.outletId;

    this.schedulerService.deleteVisit(visitationId)
      .pipe(first())
      .subscribe(
        data => {
          console.log('Got from API', data);
          if (data.status == 'error') {
            console.error('Error from API', data);
            this.alertService.error(data.errorMessage);
          }
          this.dialogRef.close(true);
        },
        error => {
          this.dialogRef.close(true);
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
        },
        () => {this.handleFinally()}
      );
  }

  updateVisit() {
  if (this.type === 'scheduler-details') {
    this.submitted = true;
    if (this.userId.invalid && !this.isChangingDate && !this.isChangingSalary) return

    let data: Schedule = new Schedule();
    data.visitationId = this.data.visitationId;
    const isSalaryIncrement = this.salaryIncrement > 0

    if (!this.isChangingDate && !this.isChangingSalary) {
      // replacement
      data.userId = this.userId.value;
      if(isSalaryIncrement) {
        data.salaryIncrement = this.salaryIncrement;
        data.salaryIncrementUpdatedById = isSalaryIncrement ? this.currentUser.userId : null;
      }
    } else if(this.isChangingDate && !this.isNewVisitDateInvalid(this.visitDate.value)){
      // change date
      data.changeDate = moment(this.visitDate.value).format('YYYY-MM-DD');
      data.salaryIncrement = this.data.salaryIncrement;
      data.salaryIncrementUpdatedById = this.data.salaryIncrementUpdatedById;
    } else {
      // change salary
      data.userId = this.data.userId
      if(isSalaryIncrement) {
        data.salaryIncrement = this.salaryIncrement;
        data.salaryIncrementUpdatedById = isSalaryIncrement ? this.currentUser.userId : null;
      }
    }

    let projectId = this.data.projectId;
    let outlet = this.data.outletId;
    let visitationDate = this.data.visitationDate;
    let visitationTime = this.data.visitationTime;

    this.isLoading = true;

    this.schedulerService.updateVisit(data)
      .pipe(first())
      .subscribe(
        data => {
          console.log('Got from API', data);
          if (data.status != 'error') {
            if (data.response.updatedStatus == false) {
              console.error('Error from API', data);
              this.alertService.error('Error - INACTIVE Promoter');
            }
          } else {
            console.error('Error from API', data);
            this.alertService.error(data.errorMessage);
          }
          this.dialogRef.close(true);
        },
        error => {
          this.dialogRef.close(true);
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
        },
        () => {this.handleFinally()}
      );
    }
    if (this.type === 'overview-supervisor') { //change date only
      if (this.isNewVisitDateInvalid(this.visitDate.value)) return
      const payload = {
        requestedBy: this.data.requestedBy,
        visitationId: this.data.visitationId,
        visitationDate: moment(this.visitDate.value).format('YYYY-MM-DD'),
      }
      console.log('Sending to API', payload)
      this.isLoading = true;
      this.schedulerService.changeDateSupervisorVisitation(payload)
        .pipe(first())
        .subscribe(data => {
          console.log("Got from API", data)
          if (data.status != 'error') {
            this.alertService.success('Saved');
          } else {
            console.error('Error from API', data);
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.error('Error from API', error);
          this.alertService.error(error.statusText);
        },
        // finally
        () => {this.handleFinally()})
    }
  }

  assignLeader(){
    const result = confirm( "Are you sure you want to assign this promoter as a leader ?" );

    if (result) {
      let data: Schedule = new Schedule();
      data.visitationId = this.data.visitationId;
      data.userId = this.data.userId;

      this.schedulerService.assignLead(data)
        .pipe(first())
        .subscribe(
          data => {
            console.log('Got from API', data);
            if (data.status != 'error') {
              this.alertService.success('Saved');
            } else {
              console.error('Error from API', data);
              this.alertService.error(data.errorMessage);
            }
            this.dialogRef.close(true);
          },
          error => {
            this.dialogRef.close(true);
            console.error('Error from API', error);
            this.alertService.error(error.statusText);
          },
          () => {this.handleFinally()}
        );
    }
  }
  cancelRequest() {
    const payload = {
      visitationId: this.data.visitationId,
    }
    this.isLoading = true;
    console.log("Sending to API", payload)
    this.schedulerService.cancelApprovalSupervisorVisitation(payload)
      .pipe(first())
      .subscribe(data => {
        console.log("Got from API", data)
        if (data.status != 'error') {
          this.alertService.success('Saved');
        } else {
          console.error('Error from API', data);
        }
      },
      error => {
        console.error('Error from API', error);
        this.alertService.error(error.statusText);
      },
      //finally
      () => {this.handleFinally()});
  }

  approveAll() {
    if (this.data.date.allowanceIds.length > 0) this.moderateAllowance(this.data.isApproving)
    if (this.data.date.visitationIds.length > 0) this.moderateVisitation(this.data.isApproving)
  }

  approveRequest(isApproving: boolean) {
    if (this.type === 'modifyAllowance') this.moderateAllowance(isApproving)
    else this.moderateVisitation(isApproving)
  }

  moderateAllowance(isApproving: boolean) {
    let payload
    if (this.data.date.allowanceIds) { //Approval ALL
      payload = {
        allowanceIds : this.data.date.allowanceIds,
        approvals : []
      }
      payload.allowanceIds.forEach(_ => payload.approvals.push(isApproving))
    } else {
      payload = {
        allowanceIds : [this.data.date.id],
        approvals : [isApproving],
      }
    }
    this.isLoading = true;

    console.log("Sending to API", payload)
    this.userService.moderateAllowance(payload)
      .pipe(first())
      .subscribe( data => {
        console.log('Got from API', data);
        if (data.status != 'error') {
          let successMsg = isApproving ? 'Approved' : 'Rejected'
          this.alertService.success(successMsg)
        } else {
          console.error('Error from API', data)
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
        console.error('Error from API', error)
        this.alertService.error(error.statusText);
      },
      //finally
      () => {this.handleFinally()})
  }

  moderateVisitation(isApproving) {
    let payload
    if (this.data.date? this.data.date.visitationIds : false) { //Approval ALL
      payload = {
        visitationIds : this.data.date.visitationIds,
        approvals : []
      }
      payload.visitationIds.forEach(_ => payload.approvals.push(isApproving))
    } else {
      payload = {
        "visitationIds" : [this.data.visitationId],
        "approvals" : [isApproving],
      }
    }
    this.isLoading = true;

    console.log("Sending to API", payload)
    this.schedulerService.moderateSupervisorVisitation(payload)
      .pipe(first())
      .subscribe( data => {
        console.log('Got from API', data);
        if (data.status != 'error') {
          let successMsg = isApproving ? 'Approved' : 'Rejected'
          this.alertService.success(successMsg)
        } else {
          console.error('Error from API', data)
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
        this.dialogRef.close(true);
        console.error('Error from API', error)
        this.alertService.error(error.statusText);
      },
      //finally
      () => {this.handleFinally()})
  }

  // MICS

  handleFinally() {
    if (this.type === 'scheduler-details') {
      this.schedulerService.refreshSchedule(this.data.projectId, this.data.project);
    } else {
      this.schedulerService.refreshOverviewSupervisor()
    }
    this.isLoading = false;
    this.dialogRef.close(true);
  }

  isNewVisitDateInvalid(visitDate) {
    const newVisitDate = new Date(visitDate)
    const now = new Date()
    const visitDateZeroHour = newVisitDate.setHours(0,0,0,0)
    const nowZeroHour = now.setHours(0,0,0,0)

    return (newVisitDate < now) && (visitDateZeroHour !== nowZeroHour)
  }


  // For html

  handleCancelWithReplacementButton = debounce(function() {
    if (this.type === 'scheduler-details' && this.data.userType === 'ROLE_PROMOTER') this.cancelVisit(true);
    else {
      if (this.isChoosingAssignee) {
        this.cancelVisit(true);
      } else {
        this.isChoosingAssignee = true
      }
    }
  }, 500)

  canDisplay(type: string) {
    const status = this.data.visitationStatus
    if (type === 'PENDING') return status == 'PENDING'
    if (type === 'NOT_ASSIGNED') {
      if (status == 'NOT_ASSIGNED'
        || status == 'APPROVAL_REPLACEMENT' && this.data.userType === 'ROLE_PROMOTER' ) return true
    }
    if (type === 'REPLACEMENT') return status == 'REPLACEMENT'
    if (type === 'COMPLETED') return status == 'COMPLETED'
    if (type === 'CANCELLED') return status == 'CANCELLED'

    const isApproveAll = this.data.date ? this.data.date.allowanceIds && this.data.date.visitationIds : false
    if (type === 'APPROVE') {
      return (
      (status == 'APPROVAL_CANCELLED'
      || status === 'APPROVAL_PENDING'
      || (status === 'APPROVAL_REPLACEMENT' && this.data.userType !== 'ROLE_PROMOTER')
      || this.data.date)
      && !isApproveAll)
    }
    if (type === 'APPROVE_ALL' && isApproveAll) return this.canEditWithoutApproval
  }

  canChangeDate() {
    if (this.viewOnly) return false

    let accessLevel = this.currentUser.accessLevel
    if (accessLevel === 'ROLE_PROJECT_MANAGER' && !this.data.isAssignedPM) return false
    if (accessLevel !== 'ROLE_SUPER_ADMIN' && this.data.userType === 'ROLE_SUPERVISOR') return false
    if (this.isChangingDate || this.isChoosingAssignee || this.isChangingSalary) return false
    return true
  }

  canUpdateSalary() {
    if (this.viewOnly) return false
    let accessLevel = this.currentUser.accessLevel
    if(this.data.userType === 'ROLE_PROMOTER' && !this.isChangingDate && !this.isChoosingAssignee && !this.isChangingSalary ) {
      // ONLY AVAILABLE IN PROMOTER
      if (accessLevel === 'ROLE_PROJECT_MANAGER' && this.data.isAssignedPM) return true
      if (accessLevel === 'ROLE_SUPER_ADMIN') return true
    }
    return false
  }

  canModifySchedule(type: 'cancel' | 'choosingAssignee' | 'update' | 'cancelApproveRequest' | 'increment') {
    if (this.viewOnly) return false

    let accessLevel = this.currentUser.accessLevel
    let isNotConflicting = false

    if (this.data.visitationStatus !== 'APPROVAL_CANCELLED') {
      if (this.type === 'scheduler-details' && this.data.userType === 'ROLE_PROMOTER') {
        if (type === 'cancel' //default cancel view
        && !this.isChangingDate && !this.isChangingSalary) isNotConflicting = true

        if (type === 'update' //when user choose to update date
        && this.isChangingDate && !this.isChangingSalary) isNotConflicting = true

        if (type === 'increment' //when user choose to update salary
        && !this.isChangingDate && this.isChangingSalary) isNotConflicting = true

      } else {
        if (type === 'cancel' //default cancel view
        && !this.isChangingDate
        && !this.isChoosingAssignee) isNotConflicting = true

        if (type === 'update' //when user choose to update date
        && this.isChangingDate
        && !this.isChoosingAssignee) isNotConflicting = true

        if (type === 'choosingAssignee' //when user choose to cancel with replacement
        && !this.isChangingDate
        && this.isChoosingAssignee) isNotConflicting = true

        if (type === 'cancelApproveRequest') isNotConflicting = true
      }
    }

    // ASSIGNED PM can only edit the promoter schedule
    if (accessLevel === 'ROLE_PROJECT_MANAGER') {
      if (!this.data.isAssignedPM || (this.data.userType? this.data.userType === 'ROLE_SUPERVISOR' : true)) return false
    }

    // if (this.canEditWithApproval || this.canEditWithoutApproval) {
      return isNotConflicting
    // }
  }

  canModifyVisit() {
    if (this.viewOnly) return false

    let accessLevel = this.currentUser.accessLevel
    if (accessLevel === 'ROLE_PROJECT_MANAGER' && !this.data.isAssignedPM) return false
    if (this.data.userType === 'ROLE_SUPERVISOR' && accessLevel !== 'ROLE_SUPER_ADMIN') return false
    return true
  }

  canAssignLeader(data) {
    if (this.viewOnly) return false

    let accessLevel = this.currentUser.accessLevel
    if (accessLevel === 'ROLE_PROJECT_MANAGER' && !this.data.isAssignedPM) return false
    if (data.leadPromoter || data.userType != 'ROLE_PROMOTER') return false
    return true
  }

  selectDate() {
    let date = this.utilityService.parseDateToPOJODate(this.data.visitationDate, 'DD-MMM-YYYY');
    this.visitDate.setValue(date);
    this.isChangingDate = true;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (item.fullName.toLocaleLowerCase().indexOf(term) > -1) return true
    if (item.phoneNumber.toLocaleLowerCase().indexOf(term) > -1) return true
    return false
  }

  confirm(message, callbackFnAfterConfirm) {
    const dialogData = {
      title: 'Confirmation',
      message: message,
      footer: true,
      showOKBtn: true,
      showCancelBtn: true
    };
    // If the confirm dialog have additional inputs, please dont double dialog, might have problem
    // try to change the dialog or any other workaround
    const confirmDialogRef = this.dialogService.openDialog(dialogData);

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        callbackFnAfterConfirm()
        this.dialogRef.close(true)
      }
    });
  }

  cancelPastVisit() {
    let msg = `Are you sure you want to cancel this visitation with date = ${this.data.visitationDate}?`
    this.confirm(msg, () => {
      const postData = {
        adminId: this.currentUser.userId,
        visitationId: this.data.visitationId,
      }
      console.log(postData)
      this.schedulerService.cancelPastVisit(postData)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
        if (data.status != 'error') {
          this.alertService.success('Cancel Past Visitation successfully.');
          data = {};
          data["projectId"] = this.data.projectId;
          data["outletId"] = this.data.outletId;
          data["visitationDate"] = this.data.visitationDate;
          data["visitationTime"] = this.data.visitationTime;
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      },
      //finally
      () => {
        this.schedulerService.refreshScheduleByVisitationId(this.data)
      });
    })
  }

  forceCheckIn() {
    let msg = `Are you sure you want to force check IN this visitation with date = ${this.data.visitationDate}?`
    this.confirm(msg, () => {
      const postData = {
        adminId: this.currentUser.userId,
        visitationId: this.data.visitationId,
      }
      console.log(postData)
      this.schedulerService.forceCheckIn(postData)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
        if (data.status != 'error') {
          this.alertService.success('Force check in successfully.');
          data = {};
          data["projectId"] = this.data.projectId;
          data["outletId"] = this.data.outletId;
          data["visitationDate"] = this.data.visitationDate;
          data["visitationTime"] = this.data.visitationTime;
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      },
      //finally
      () => {
        this.schedulerService.refreshScheduleByVisitationId(this.data)
      });
    })
  }

  forceCheckOut() {
    let msg = `Are you sure you want to force check OUT this visitation with date = ${this.forceCheckOutDate}?`
    this.confirm(msg, () => {
      const postData = {
        adminId: this.currentUser.userId,
        userId: this.data.userId,
      }
      console.log(postData)
      this.schedulerService.forceCheckOut(postData)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
        if (data.status != 'error') {
          this.alertService.success('Force check out successfully.');
          let postData = {};
          postData["projectId"] = this.data.projectId;
          postData["outletId"] = this.data.outletId;
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      },
      //finally
      () => {
        this.schedulerService.refreshScheduleByVisitationId(this.data)
      });
    })
  }


}
