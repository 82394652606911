import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/globals'
import { Schedule } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class SchedulerService {

  @Output() change = new EventEmitter();
  @Output() changeTab = new EventEmitter();
  @Output() changeByOutlet = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() changeByVisitationId = new EventEmitter();
  //Overview Supervisor
  @Output() hideExpiredScheduler = new EventEmitter();
  @Output() dataPassing = new EventEmitter();
  @Output() refresh = new EventEmitter();

  private projectId : number;

  constructor(private http: HttpClient,public _globals: Globals) { }

  getSummary(id,type) {
    return this.http.post<any>('/fd/api/getvisitationsummary',{ "locale": this._globals.locale, "projectId": id, "userType": type});
  }

  getVisit(id){
    return this.http.post<any>('/fd/api/getvisitations',{ "locale": this._globals.locale, "projectId": id });
  }

  getVisitByOutlet(id, outletId){
    return this.http.post<any>('/fd/api/getvisitationsbyoutlet',{ "locale": this._globals.locale, "projectId": id, "outletId": outletId });
  }

  getVisitationById(id, outletId, visitationDate, visitationTime){
    return this.http.post<any>('/fd/api/getvisitationbyid',{ "locale": this._globals.locale, "projectId": id, "outletId": outletId, "visitationDate": visitationDate,  "visitationTime": visitationTime});
  }

  cancelVisit(sc: Schedule) {
    return this.http.post<any>('/fd/api/cancelvisitation', sc);
  }

  updateVisit(sc: Schedule) {
    return this.http.post<any>('/fd/api/updatevisitationschedule', sc);
  }

  deleteVisit(visitationId) {
    return this.http.post<any>('/fd/api/deletevisitationbyid',{ "locale": this._globals.locale, "visitationId": visitationId });
  }

  addVisit(sc: Schedule){
    return this.http.post<any>('/fd/api/addvisitationschedule', sc);
  }

  // === Special cases API - if promoter/supervisor unable to check-in/out/cancel with their mobile
  // for any reason, let the user with portal access to force check-in/out/cancel for their promoter/supervisor
  checkUserOngoingVisit(postData) { // can be past or not -> postData.past = true/false
    return this.http.post<any>('/fd/api/checkuserongoingvisitation', postData);
  }
  forceCheckIn(postData) {
    return this.http.post<any>('/fd/api/forcecheckin', postData);
  }
  forceCheckOut(postData) {
    return this.http.post<any>('/fd/api/forcecheckout', postData);
  }
  cancelPastVisit(postData) {
    return this.http.post<any>('/fd/api/cancelpastvisitation', postData);
  }
  // === Special cases API END

  assignLead(sc: Schedule) {
    return this.http.post<any>('/fd/api/assignleadpromoter', sc);
  }
  removeLead(sc: Schedule) {
    return this.http.post<any>('/fd/api/removeleadpromoter', sc);
  }

  getSupervisorList() {
    return this.http.get<any>('/fd/api/getsupervisorslist');
  }

  // Filtering Option
  getPromoter(date,userType?){
    return this.http.post<any>('/fd/api/getavailablepromoterslist',{ "locale": this._globals.locale, "date": date, "accessLevel": userType});
  }
  getUserListByType(date,userType?,outletId?,projectId?){
    return this.http.post<any>('/fd/api/getavailableuserslist',{ "locale": this._globals.locale, "date": date, "accessLevel": userType, "outletId": outletId, "projectId": projectId});
  }
  getOutlet(id){
    return this.http.post<any>('/fd/api/getoutletsinproject',{ "projectId": id });
  }
  getPromoter1(id, userType){
    return this.http.post<any>('/fd/api/getpromotersinproject',{ "projectId": id, "userType": userType });
  }

  fetchId(data) {
    this.projectId = data.id;
    this.change.emit(data);
  }

  // fetchFilter(filterType,value){
  //   this.filter.emit({ 'value' : value, 'type' : filterType});
  // }

  fetchFilter(filter:Object){
    this.filter.emit(filter);
  }

  refreshSchedule(id = this.projectId, project) {
    this.change.emit({id, project});
  }

  changeTabScheduler(tab) {
    this.changeTab.emit(tab);
  }

  refreshScheduleByOutlet(data) {
    this.changeByOutlet.emit(data);
  }

  refreshScheduleByVisitationId(data) {
    this.changeByVisitationId.emit(data);
  }

  // Edit Outlet
  addOutlet(projectId, outletCode, activationType, startTime, endTime, dailySalesTarget, dailySamplingTarget, salary, supervisorId){
    return this.http.post<any>('/fd/api/addoutletinproject',{
      "projectId": projectId,
      "outletCode": outletCode,
      "activationType": activationType,
      "startTime": startTime,
      "endTime": endTime,
      "dailySalesTarget": dailySalesTarget,
      "dailySamplingTarget": dailySamplingTarget,
      "salary": salary,
      "supervisorId": supervisorId
    });
  }

  addVisitation(projectId, outletId, visitationDate, visitationTime, promoterId, userType,salaryIncrement,salaryIncrementUpdatedById){
    return this.http.post<any>('/fd/api/addvisitation',{
      "projectId": projectId,
      "outletId": outletId,
      "visitationDate": visitationDate,
      "visitationTime": visitationTime,
      "userId": promoterId,
      "accessLevel": userType,
      "salaryIncrement": salaryIncrement,
      "salaryIncrementUpdatedById": salaryIncrementUpdatedById
    });
  }

  removeOutlet(projectId, outletId){
    return this.http.post<any>('/fd/api/removeoutletinproject',{ "projectId": projectId, "outletId": outletId });
  }

  editTime(projectId, outletId, startTime, endTime){
    return this.http.post<any>('/fd/api/updateoutletinproject',{ "projectId": projectId, "outletId": outletId, "startTime": startTime, "endTime": endTime });
  }

  parseStatus(status){
    if( status == "COMPLETED") return 'Completed';
    else if( status == "IN_PROGRESS") return 'In Progress';
    else if( status == "CANCELLED") return 'Cancelled';
    else if( status == "NON_WORKING_DAY") return 'Holiday';
    else if( status == "NON_WORKING") return 'N/A';
    else if( status == "NOT_ASSIGNED") return 'Pending Assignment';
    else if( status == "PENDING") return 'Assigned';
    else if( status == "REPLACEMENT") return 'Replacement';
    else return status;
  }


  // Overview Supervisor
  changeHideExpiredScheduler(data: boolean) {
    this.hideExpiredScheduler.emit(data);
  }

  passData(data) {
    this.dataPassing.emit(data)
  }

  refreshOverviewSupervisor() {
    this.refresh.emit()
  }

  addSupervisorVisitation(data) {
    return this.http.post<any>('/fd/api/supervisor/visitation/add', data);
  }

  cancelSupervisorVisitation(data) {
    return this.http.post<any>('/fd/api/supervisor/visitation/cancel', data)
  }

  cancelApprovalSupervisorVisitation(data) { //Action by coordinator to cancel approval request
    return this.http.post<any>('/fd/api/supervisor/visitation/request/cancel', data)
  }

  moderateSupervisorVisitation(data) {
    return this.http.post<any>('/fd/api/supervisor/visitation/moderate', data)
  }

  changeDateSupervisorVisitation(data) {
    return this.http.post<any>('/fd/api/supervisor/visitation/date/change', data)
  }


}


