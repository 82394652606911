export class User {
  locale: string;
  userId: number;
  email: string;
  password: string;
  name: string;
  playerId: string;
  type: string;
  status: string;
  accessLevel: string;
  phoneNumber: string;
  fullName: string;
  salary: number;
  effectiveMonth: string;
  effectiveYear: string;
  supervisorIds: number[]; //Assigned supervisor with coordinator
  coordinatorId: number;
}
