import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AlertService } from '@app/_services';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .custom-toast .ui-toast-message {
            color: #ffffff;
            background: #FC466B;
            background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);
            background: linear-gradient(to right, #3F5EFB, #FC466B);
        }

        :host ::ng-deep .custom-toast .ui-toast-close-icon {
            color: #ffffff;
        }
    `],
    providers: [MessageService]
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService, private messageService: MessageService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe((message) => {
          if(message){
              this.message = message;
              // console.log(message);
              this.showToast();
          }else{
              return;
          }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showToast(){
      let msg:any = {};
      msg = this.message;

      let data = {
        severity: msg.type,
        summary: msg.text,
        // detail: msg.text
      }

      this.messageService.add(data);
    }
}
