import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthenticationService } from '@app/_services';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // decode the token to get its payload
    // const tokenPayload = decode(token);
    if (
      !localStorage.getItem('isLoggedin') ||
      user.response.accessLevel !== expectedRole
    ) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
