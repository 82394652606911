export class Utility {
  channelList : any = new Array();
  stateList : any = new Array();
  retailerList : any = new Array();
  regionList : any = new Array();


  // this.channelList = data.response.channels ? data.response.channels : null;
  // this.stateList = data.response.states ? data.response.states : null;
  // this.retailerList = data.response.retailers ? data.response.retailers : null;
  // this.regionList = data.response.regions ? data.response.regions : null;
}
