import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef
} from "@angular/core";
import {
  AlertService,
  UserService,
  UtilityService,
  DialogService
} from "@app/_services";
import { User } from "@app/_models";
import { Globals } from "@app/globals";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import * as XLSX from "xlsx";

import { first } from "rxjs/operators";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit {
  @ViewChild("USERTABLE") table: ElementRef;

  userList: User[] = [];
  columnsToDisplay: string[] = [
    "fullName",
    "phoneNumber",
    "email",
    "accessLevel",
    "SVCoordinator",
    "status"
  ];
  userRoles: any = new Array();
  userStatusList: any = new Array();
  dataSource: MatTableDataSource<User>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private globals: Globals,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.userService.subsVar === undefined) {
      this.userService.subsVar = this.userService.invokeRefreshUserList.subscribe(
        (name: string) => {
          this.getUserList();
        }
      );
    }
    this.getUserList();
  }

  exportAsExcel() {
    let data:any[] = JSON.parse(JSON.stringify(this.userList));
    let context = this;
    data = data.filter(function (props) {
      delete props.coordinatorId;
      delete props.supervisors;
      delete props.password;
      return true;
    });
    data.forEach(function(dd) {
      dd.accessLevel = context.userService.parseUserAccessLv(dd.accessLevel);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      data
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "users.xlsx");
  }

  getUserList() {
    this.userService
      .getAll(this.globals.locale)
      .pipe(first())
      .subscribe(
        data => {
          console.log("Got user list from API", data);
          if (data.status !== "error") {
            this.userList = data.response;
            this.dataSource = new MatTableDataSource(this.userList);
            this.dataSource.sort = this.sort;
            this.setupCustomSort();
            this.dataSource.paginator = this.paginator;
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editUser(user) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      data: user,
      minWidth: "500px",
      maxWidth: "600px"
    });
  }

  viewUser() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      minWidth: "500px",
      maxWidth: "600px",
      data: {
        fullName: "",
        email: "",
        phoneNumber: "",
        accessLevel: ""
      }
    });
  }

  parseUAL(accessLv){
    return this.userService.parseUserAccessLv(accessLv);
  }

  parseSVCoordinators(user, type?: 'tooltip') {
    let output = null

    if (type === 'tooltip') { // for tooltip
      // only supervisor need tooltip, only shows if more than one
      if (user.supervisors? user.supervisors.length > 1 : false) {
        output = ""
        // only show max 10 in tooltips
        if (user.supervisors.length > 10) {
          for (let i = 0; i < 10; i++) {
            output += user.supervisors[i].fullName + "\n"
          }
          output += "..."
        } else {
          user.supervisors.forEach( user => {
            output += user.fullName + "\n"
          })
        }
      }
    }
    else { //for cell display (innerHtml)
      output = `<p class="text-center"> - </p>`
      if (user.supervisors && user.supervisors.length > 0) { // for Coordinator
        if (user.supervisors.length > 1) {
          output = `${user.supervisors[0].fullName} and ${user.supervisors.length - 1} more`
        }
        else {
          output = user.supervisors[0].fullName
        }
      }
      else if (user.coordinatorFullName) { // for Supervisor
        // const coordinator = this.userList.filter(el => user.coordinatorId === el.userId)
        output = user.coordinatorFullName
      }
    }

    return output
  }

  setupCustomSort() {
    this.dataSource.sortingDataAccessor = (item: any, property) => {
      if (property === 'SVCoordinator') {
        let itemToCompare = item.supervisors ? item.supervisors : item.coordinatorFullName
        return itemToCompare
      }
      return item[property]
    }
  }
}

// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from "@angular/material";
import { AbstractControl, FormControl, ValidatorFn, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import * as moment from "moment";

@Component({
  selector: "user-dialog",
  templateUrl: "./user-dialog.html",
  styleUrls: ["./user.component.scss"]
})
export class UserDialogComponent implements OnInit {
  currentUser = null;
  isSuperAdmin: Boolean = false;

  submitted = false;
  statusChecked = false;
  userData: User = new User();
  userName = new FormControl("", [Validators.required]);
  userEmail = new FormControl("", [Validators.required, Validators.email]);
  userPhone = new FormControl("60", [Validators.required, Validators.pattern("^[0-9]*$"),Validators.minLength(8)]);
  userAccess = new FormControl("", [Validators.required]);

  // Supervisor
  salary = new FormControl();
  initialSalary = null;
  initialEffectiveMonth = null;
  effectiveMonth = new FormControl("", [this.effectiveMonthValidator()]);
  coordinatorListSearch = new FormControl()
  coordinatorId = new FormControl([]);

  // Coordinator
  supervisorListSearch = new FormControl()
  supervisorIds = new FormControl([]);
  initialSupervisorIds = [];

  // userPassword = new FormControl("", Validators.required);
  userStatus = new FormControl("", Validators.required);

  userRoles: any = new Array();
  userStatusList: any = new Array("ACTIVE", "INACTIVE");

  datesList;

  supervisorList: any = new Array();
  supervisorListFiltered: any = new Array();
  coordinatorList: any = new Array();
  coordinatorListFiltered: any = new Array();
  // { value: "1", viewValue: "Admin" },
  //   { value: "2", viewValue: "Client" },
  //   { value: "3", viewValue: "User" }

  hasError: boolean = false;
  errorMessage: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private userService: UserService,
    public utilityService: UtilityService,
    private dialogService: DialogService,
    private globals: Globals,
    public dialogRef: MatDialogRef<UsersComponent>,
  ) {
    dialogRef.disableClose = false;
  }

  ngOnInit() {
    this.userData.locale = this.globals.locale;
    this.getUtilities();
    this.retrieveUser();
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser.response.accessLevel == "ROLE_SUPER_ADMIN") this.isSuperAdmin = true

    if (this.data.userId) this.initiateData()
    this.setDatesArray() //for effective month
  }

  getUtilities() {
    this.utilityService
      .get()
      .pipe(first())
      .subscribe(
        data => {
          if (data.status !== "error") {
            this.userRoles = this.filterAccessLv(data.response.accessLevels);
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
        }
      );
  }

  retrieveUser() {
    this.userService.getAll(this.globals.locale)
      .pipe(first())
      .subscribe(data => {
        this.supervisorList = data.response.filter(user => user.accessLevel === "ROLE_SUPERVISOR" && user.status === 'ACTIVE')
        this.supervisorListFiltered = this.supervisorList
        this.moveSelectedSupervisorsToTop()

        this.coordinatorList = data.response.filter(user => user.accessLevel === "ROLE_COORDINATOR" && user.status === 'ACTIVE')
        this.coordinatorListFiltered = this.coordinatorList
      })
  }

  initiateData() { //for editing only
    this.submitted = true;
    this.userName = new FormControl(this.data.userName, [
      Validators.required
    ]);
    this.userEmail = new FormControl(this.data.userEmail, [
      Validators.required,
      Validators.email
    ]);
    this.userPhone = new FormControl(this.data.userPhone, [
      Validators.required
    ]);
    this.userAccess = new FormControl(this.data.userAccess, [
      Validators.required
    ]);

    // this.userPassword = new FormControl(this.data.userPassword, [
    //   Validators.required
    // ]);
    this.userStatus = new FormControl(this.data.userStatus, [
      Validators.required
    ]);
    this.statusChecked = this.data.status === "ACTIVE" ? true : false;
    this.userAccess.setValue(this.data.accessLevel, { onlySelf: true });
    this.userName.setValue(this.data.fullName, { onlySelf: true });
    this.userEmail.setValue(this.data.email, { onlySelf: true });
    this.userPhone.setValue(this.data.phoneNumber, { onlySelf: true });
    this.userStatus.setValue(this.data.status, { onlySelf: true });

    // Supervisor
    this.coordinatorId = new FormControl([]);
    this.salary = new FormControl(null);
    this.effectiveMonth = new FormControl(null, [this.effectiveMonthValidator()]);
    if (this.data.coordinatorId) this.coordinatorId.setValue(this.data.coordinatorId, { onlySelf: true });

    if (this.data.currentSalary || this.data.currentSalary === 0) this.initialSalary = this.data.currentSalary

    if (this.data.salary || this.data.salary === 0) {
      this.salary.setValue(this.data.salary, { onlySelf: true });

      let monthYearRetrieved = null
      if (this.data.effectiveMonth && this.data.effectiveYear) {
        monthYearRetrieved = this.data.effectiveMonth + " " + this.data.effectiveYear
        monthYearRetrieved = this.utilityService.formatDate(monthYearRetrieved, 'M YYYY', 'MMM YYYY')
      }
      this.effectiveMonth.setValue(monthYearRetrieved, { onlySelf: true });
      this.initialEffectiveMonth = monthYearRetrieved
    }

    // Coordinator
    this.supervisorIds = new FormControl([]);
    if (this.data.supervisors) {
      let ids = []
      this.data.supervisors.forEach(user => {
        ids.push(user.userId)
      })
      this.initialSupervisorIds = ids
      this.supervisorIds.setValue(ids, { onlySelf: true });
    }
  }

  setDatesArray() {
    var startDay = new Date();
    var startDate = moment(startDay);
    var endDate = moment(startDay).add(2, 'year');
    var datesBetween = [];

    var startingMoment = startDate;
    while (startingMoment <= endDate) {
      datesBetween.push(startingMoment.clone().format('MMM YYYY'));
      startingMoment.add(1, 'month');
    }
    this.datesList = datesBetween
  }

  filterAccessLv(al){
    if (al) {
      al.indexOf('ROLE_PROMOTER') !== -1 && al.splice(al.indexOf('ROLE_PROMOTER'), 1);
      al.indexOf('ROLE_CLIENT') !== -1 && al.splice(al.indexOf('ROLE_CLIENT'), 1);
    }
    return al ? al : null;
  }

  moveSelectedSupervisorsToTop() {
    let result = this.supervisorList
    const selectedList = this.supervisorIds.value
    if (selectedList) {
      selectedList.forEach(selectedUserId => {
        let selectedUser = result.filter(user => user.userId === selectedUserId)
        result = result.filter(user => user.userId !== selectedUserId)
        result.unshift(selectedUser[0])
      })
    }
    this.supervisorList = result
  }

  // === Form submit (add/edit)

  addNewUser() {
    this.submitted = true;
    this.hasError = false;
    if (this.isAnyFieldInvalid()) {
      this.hasError = true;
      this.errorMessage = "Complete the form before submit.";
      return;
    }
    this.userData.fullName = this.userName.value;
    this.userData.email = this.userEmail.value;
    this.userData.phoneNumber = this.userPhone.value;
    this.userData.accessLevel = this.userAccess.value;
    this.userData.salary = this.salary.value;
    this.userData.effectiveMonth = null
    this.userData.effectiveYear = null
    this.userData.supervisorIds = this.supervisorIds.value;
    this.userData.coordinatorId = this.coordinatorId.value;
    // this.userData.password = this.userPassword.value;
    this.userData.status = this.userStatus.value;
    console.log('addNewUser(): Sending to API', this.userData);
    this.userService
      .add(this.userData)
      .pipe(first())
      .subscribe(
        data => {
          console.log('addNewUser(): Response back from API', data);
          if (data.status !== "error") {
            if (this.userAccess.value === 'ROLE_SUPERVISOR' || this.userAccess.value === 'ROLE_COORDINATOR') {
              this.saveUserSVAndCoordinator('add', {userId: data.response.userId})
              return // let saveUserSVAndCoordinator() to handle success
            }
            this.handleSuccess()
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
        }
      );
  }

  editUser() {
    this.hasError = false;
    if (this.isAnyFieldInvalid()) {
      this.hasError = true;
      this.errorMessage = "Complete the form before submit.";
      this.alertService.error(this.errorMessage);
      return;
    }
    this.userData.userId = this.data.userId;
    this.userData.fullName = this.userName.value;
    this.userData.email = this.userEmail.value;
    this.userData.accessLevel = this.userAccess.value;
    this.userData.phoneNumber = this.userPhone.value;

    // this.userData.password = this.userPassword.value;
    this.userData.status = this.statusChecked ? "ACTIVE" : "INACTIVE";
    // this.userData.status = this.userStatus.value;

    console.log('editUser(): Sending below data to API', this.userData)
    this.userService
      .update(this.userData)
      .pipe(first())
      .subscribe(
        data => {
          console.log('editUser(): Response from API', data);
          if (data.status !== "error") {
            if (this.userAccess.value === 'ROLE_SUPERVISOR' || this.userAccess.value === 'ROLE_COORDINATOR') {
              this.saveUserSVAndCoordinator('update')
              if(!this.salary.value || !this.effectiveMonth.value) return this.handleSuccess(false)
              if (this.initialSalary !== this.salary.value || this.initialEffectiveMonth !== this.effectiveMonth.value) this.updateSalary()
              return // let saveUserSVAndCoordinator() to handle success
            }            
            this.handleSuccess()
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  isAnyFieldInvalid() {
    return this.userName.invalid
    || this.userEmail.invalid
    || this.userPhone.invalid
    || this.userAccess.invalid
    || this.salary.invalid
    || this.effectiveMonth.invalid
  }

  saveUserSVAndCoordinator(type: 'update'|'add', payload?) { //payload.userId only required by add
    let userId = type === 'update' ? this.data.userId : payload.userId
    let unassignSupervisorIds = []
    unassignSupervisorIds = this.initialSupervisorIds.filter(id => !this.supervisorIds.value.includes(id))

    let data = null
    if (this.userAccess.value === 'ROLE_SUPERVISOR'
    && this.coordinatorId.value? this.coordinatorId.value.length !== 0: false) data = {
      "coordinatorId": this.coordinatorId.value,
      "supervisorIds": [userId]
    }
    if (this.userAccess.value === 'ROLE_COORDINATOR') data = {
      "coordinatorId": userId,
      "supervisorIds": this.supervisorIds.value,
      "unassignSupervisorIds": unassignSupervisorIds
    }

    if (data === null)  {
      console.log('saveUserSVAndCoordinator(): data is null, skipping this stage')
      if (type === 'update' && (this.initialSalary !== this.salary.value || this.initialEffectiveMonth !== this.effectiveMonth.value)) return //let updateSalary() to handle success
      this.handleSuccess()
      return
    }

    console.log('saveUserSVAndCoordinator(): Sending for update SV and Coordinator', data)
    this.userService.updateSVAndCoordinator(data)
      .pipe(first())
      .subscribe( data => {
        if (data.status !== "error") {
          console.log('saveUserSVAndCoordinator(): Response from API', data)
          if (type === 'update' && (this.initialSalary !== this.salary.value || this.initialEffectiveMonth !== this.effectiveMonth.value)) return //let updateSalary() to handle success
          this.handleSuccess()
        } else {
          this.alertService.error(data.errorMessage);
          return
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      })

  }

  updateSalary() {
    const data = {
      "userId": this.data.userId,
      "newSalary": this.salary.value,
      "effectiveMonth": moment(this.effectiveMonth.value, 'MMM YYYY').format('M'),
      "effectiveYear": moment(this.effectiveMonth.value, 'MMM YYYY').format('YYYY'),
    }
    console.log('updateSalary(): Sending for update salary', data)
    this.userService.updateSalary(data)
    .pipe(first())
    .subscribe(
      data => {
        if (data.status !== "error") {
          console.log('updateSalary(): Response from API', data)
          this.handleSuccess()
        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
        console.log(error);
        this.alertService.error(error.statusText);
      }
    )
  }

  resetPW() {
    const dialogData = {
      title: 'Confirmation',
      message: `Are you sure you want to reset password for email = ${this.data.email}?`,
      footer: true,
      showOKBtn: true,
      showCancelBtn: true
    };
    // If the confirm dialog have additional inputs, please dont double dialog, might have problem
    // try to change the dialog or any other workaround
    const confirmDialogRef = this.dialogService.openDialog(dialogData);

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.doResetPW()
        this.dialogRef.close(true)
      }
    });
  }

  doResetPW() {
    const postData = {
      locale: this.globals.locale,
      email: this.data.email,
      admin: this.isSuperAdmin,
      adminId: this.currentUser.response.userId
    }
    // console.log(postData)
    this.userService.resetPW(postData)
    .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          if (data.status !== "error") {
            this.alertService.success("Password reset successfully.");
          } else {
            this.alertService.error(data.errorMessage);
          }
        },
        error => {
          console.error(error);
          this.alertService.error(error.statusText);
        }
      );
  }

  handleSuccess(isAPICalling = true) {
    this.dialogRef.close(true);

    if(!!isAPICalling){
      this.alertService.success("Saved");
      this.refreshUserList();
    } // use isAPICalling flag to prevent refresh list twice
  }

  refreshUserList() {
    this.userService.refreshUserList();
  }

  // === Mics methods for HTML

  getErrorMessage(field) {
    if (field === "email") {
      return this.userEmail.hasError("required")
        ? "You must enter a value"
        : this.userEmail.hasError("email")
        ? "Not a valid email"
        : "";
    } else if (field === "name") {
      return this.userName.hasError("required") ? "You must enter a value" : "";
    } else if (field === "phone") {
      return this.userPhone.hasError("required")
        ? "You must enter a value"
        : this.userPhone.hasError("pattern")
        ? "Not a valid phone number"
        : "";
    } else if (field === "access") {
      return this.userAccess.hasError("required")
        ? "You must enter a value"
        : "";
    } else if (field === "salary") {
      return this.salary.hasError("required")
        ? "You must enter a value"
        : "";
    } else if (field === "effectiveMonth") {
      return this.effectiveMonth.hasError("required")
        ? "You must enter a value"
        : "";
    } else if (field === "status") {
      return this.userStatus.hasError("required")
        ? "You must enter a value"
        : "";
    }
  }

  parseUAL(accessLv){
    return this.userService.parseUserAccessLv(accessLv);
  }

  filterList(type: 'supervisorList' | 'coordinatorList', option?: 'unfilter') {
    let search = this[type + 'Search'].value
    if (!search || search === "" || option === 'unfilter') {
      this[type + 'Filtered'] = this[type] //unfilter
      return
    }
      search = search.toLowerCase()
      let result = this[type].filter(user => user.fullName.toLowerCase().indexOf(search) > -1)
      this[type + 'Filtered'] = result
  }

  // === Custom validators

  effectiveMonthValidator(): ValidatorFn { //Only required if new salary is inserted
    return (control: AbstractControl) => {
      console.log(this.salary.value)
      if (this.userAccess.value === 'ROLE_SUPERVISOR' && this.salary.value) {
        if (control ? control.value === null : false) {
          return { 'required':  true };
        }
      }
      return null
    };
  }
}
