import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { AlertService, SettingsService } from '@app/_services';
import { Retailer } from '@app/_models';
import { Globals } from '@app/globals';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-retailers',
  templateUrl: './retailers.component.html',
  styleUrls: ['./retailers.component.scss']
})
export class RetailersComponent implements OnInit {

  retailerList: Retailer[] = [];
  columnsToDisplay: string[] = ['name','code'];
  dataSource: MatTableDataSource<Retailer>;
  broadcastMessage = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private alertService: AlertService,
    private settingsService: SettingsService,
    private globals: Globals,
    public dialog: MatDialog) {
      this.settingsService.invokeRefreshRetailersList.subscribe((name:string) => {
        this.getRetailerList();
      });

      this.getRetailerList();
    }

    getRetailerList() {
      this.settingsService.getAllRetailers(this.globals.locale)
          .pipe(first())
          .subscribe(
              data => {
                // console.log(data);
                if (data.status !== 'error'){
                  this.retailerList = data.response.retailers;
                  this.dataSource = new MatTableDataSource(this.retailerList);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  // console.log(this.clientList);
                } else {
                  this.alertService.error(data.errorMessage);
                }
              },
              error => {
                  console.log(error);
                  this.alertService.error(error.statusText);
              });
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }


  ngOnInit() {
  }


  addRetailer() {
    const dialogRef = this.dialog.open(RetailerDialogContent, {
      disableClose: true,
      width: '500px'
    });
  }

  editRetailer(retailer) {
    const dialogRef = this.dialog.open(RetailerDialogContent, {
      disableClose: true,
      width: '500px',
      data: retailer
    });
  }


}



// ******************** START : DIALOG COMPONENT ********************
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'retailer-dialog',
  templateUrl: './retailer-form.html',
  styleUrls: ['./retailers.component.scss']
})
export class RetailerDialogContent implements OnInit {

  submitted = false;
  retData: Retailer = new Retailer();
  code = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private alertService: AlertService,
      private settingsService: SettingsService,
      private globals: Globals,
      public dialogRef:MatDialogRef <RetailersComponent>,
    ){ }

  ngOnInit() {
      this.retData.locale = this.globals.locale;

      if (this.data) {
        console.log(this.data);
        this.submitted = true;
        this.name = new FormControl(this.data.name, [Validators.required]);
        this.code = new FormControl(this.data.code, [Validators.required]);
      }
  }

  getErrorMessage() {
  }

  addNewRetailer() {
    this.submitted = true;
    if (this.name.invalid || this.code.invalid) {
        return;
    }
    this.retData.name = this.name.value;
    this.retData.code = this.code.value;
    // console.log(this.clientData);

    this.settingsService.addRetailer(this.retData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshRetailerList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }

  updateRetailer() {
    this.submitted = true;
    if (this.name.invalid || this.code.invalid || !this.data.id) {
        return;
    }
    this.retData.name = this.name.value;
    this.retData.code = this.code.value;
    this.retData.id = this.data.id;
    // console.log(this.clientData);

    this.settingsService.updateRetailer(this.retData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Saved');
          this.refreshRetailerList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }

  deleteRetailer() {
    this.submitted = true;
    if (!this.data.id) {
        return;
    }
    this.retData.id = this.data.id;
    // console.log(this.clientData);

    this.settingsService.deleteRetailer(this.retData).pipe(first()).subscribe(
      data => {
        console.log(data);
        if (data.status !== 'error') {

          this.dialogRef.close(true);
          this.alertService.success('Deleted');
          this.refreshRetailerList();

        } else {
          this.alertService.error(data.errorMessage);
        }
      },
      error => {
          console.log(error);
          this.dialogRef.close(true);
          this.alertService.error(error.statusText);
      }
    );
  }


  refreshRetailerList(){
    this.settingsService.refreshRetailersList();
  }

}
