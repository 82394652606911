export class Announcement {
  locale: string;
  name: string;
  details: string;
  title: string;
  userId: string;
  fromId: number;
  broadcast: boolean;
  type: string;
  to: number;
  from: number;
  status: string;
  project: number;
  projectType: number;
  content: string;
  header: string;
  description: string;
  date: string;
  userType: string;
  notificationId: number;
}
