import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Client } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ClientService {

    invokeRefreshClientList = new EventEmitter();
    subsVar: Subscription;

    constructor(private http: HttpClient) { }

    getAll(locale:string) {
        return this.http.post<any>('/fd/api/getclientslist',{
          "locale": locale,
          "status": null
        });
    }

    getById(client: Client) {
        return this.http.post<any>('/fd/api/getclientdetails', client);
    }

    add(client: Client) {
        return this.http.post<any>('/fd/api/addclient', client);
    }

    update(client: Client) {
        return this.http.post<any>('/fd/api/updateclient', client);
    }

    delete(client: Client) {
        return this.http.post<any>('/fd/api/deleteclient', client);
    }

    refreshClientList() {
      this.invokeRefreshClientList.emit();
    }
}
